import List from '@mui/material/List';
import Drawer from '@mui/material/Drawer';
import { useTranslation } from 'react-i18next';
import { ListItemButton } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { TRANSLATIONS } from 'types/enums';
import infoGrey from 'assets/icons/espectro/info-grey.svg';
import infoGreen from 'assets/icons/espectro/info-green.svg';
import { RoutesForNavigationDrawerWithVerticalMenu } from '../../../types/interfaces';
import { drawerPaper, highlightBackground, listIcon, root } from '../NavigationDrawerStyles';

type TCollapsibleRoute = {
  isOpen: boolean;
  routeName: string;
  parentRouteName: string;
};

export type Props = {
  openDrawer: boolean;
  routes: RoutesForNavigationDrawerWithVerticalMenu[];
};

const userManualRoute = '/espectro/user-manual';

function NavigationDrawerWithVerticalMenu(
  { routes, openDrawer }: Props,
) {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();

  const [
    collapsibleRoutes, setCollapsibleRoutes,
  ] = React.useState<TCollapsibleRoute[]>([]);
  const [currentActiveRoute, setCurrentActiveRoute] = useState<string>(
    location.pathname || '',
    // 'aa' || '',
  );

  function handleNavigation(routeName: string) {
    navigate(routeName);
    setCurrentActiveRoute(routeName);
  }

  function handleCollapse(toOpenRouteConfig: TCollapsibleRoute, parentRouteName: string) {
    const [{ collapseList }] = routes.filter(route => route.routeName === parentRouteName);
    const collapsibleRoutesValue: TCollapsibleRoute[] = [];
    collapseList?.forEach(
      route => collapsibleRoutesValue.push(
        { ...route, parentRouteName, isOpen: !toOpenRouteConfig.isOpen },
      ),
    );
    setCollapsibleRoutes(collapsibleRoutesValue);
  }

  function handleNavItemClick(
    routeName: string, isCollapse: boolean, toOpenRouteConfig: TCollapsibleRoute,
  ) {
    if (isCollapse) {
      handleCollapse(toOpenRouteConfig, routeName);
    } else {
      handleNavigation(routeName);
    }
  }
  const selectedRoute = useMemo(
    () => location.pathname, [location.pathname],
  );

  useEffect(() => {
    const routesWithCollapseList = routes
      .filter(route => !!route.collapseList?.length)[0];

    setCollapsibleRoutes((routesWithCollapseList?.collapseList || [])
      .map(({ routeName }) => ({
        routeName,
        isOpen: selectedRoute === routeName,
        parentRouteName: routesWithCollapseList.routeName,
      })));
  }, [selectedRoute, routes]);

  // @ts-ignore
  return (
    <div className="navigation_drawer-vertical-menu">
      <Drawer
        variant="permanent"
        anchor="left"
        open={openDrawer}
        style={{
          width: 180,
          fontSize: '0.95rem',
        }}
        PaperProps={{ sx: drawerPaper }}
      >
        <List component="nav" disablePadding>
          {routes.map((route, key) => {
            const [toOpenCheck] = collapsibleRoutes.filter(collapse => collapse.parentRouteName === route.routeName);
            return (
              <ListItemButton
                key={key}
                sx={root}
                selected={currentActiveRoute === route.routeName}
                onClick={() => handleNavItemClick(route.routeName, !!route.collapseList?.length, toOpenCheck)}
                style={(toOpenCheck?.isOpen && route.collapseList?.map(collapseList => collapseList.routeName).includes(currentActiveRoute)) ? highlightBackground : {}}
              >
                <img
                  src={(currentActiveRoute === route.routeName
                    || route.collapseList?.map(collapseList => collapseList.routeName).includes(currentActiveRoute))
                    ? route.listItemIconActive : route.listItemIcon}
                  alt={route.routeName}
                  style={listIcon}
                />
                <p
                  className={`navigation_text-title ${currentActiveRoute === route.routeName && 'navigation_text-active'}`}
                >
                  {route.listItemText}
                </p>
              </ListItemButton>
            );
          })}
        </List>
      </Drawer>

      {/* Manual Link */}
      <div
        style={{
          zIndex: 2,
          width: 191,
          bottom: '80px',
          position: 'absolute',
        }}
      >
        <ListItemButton
          sx={root}
          onClick={() => handleNavItemClick(
            userManualRoute, false, {} as any,
          )}
          selected={currentActiveRoute === userManualRoute}
        >
          <img
            src={currentActiveRoute === userManualRoute
              ? infoGreen : infoGrey}
            alt={userManualRoute}
            style={{
              height: '20px',
              width: '20px',
              marginRight: '10px',
              marginBottom: '1px',
            }}
          />
          <p>
            {t(TRANSLATIONS.USER_MANUAL)}
          </p>
        </ListItemButton>
      </div>
    </div>
  );
}

export default NavigationDrawerWithVerticalMenu;
