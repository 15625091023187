/* eslint global-require: "off" */

import { isEspectro } from '../constants';

const ImageLinks = {
  logo: require('../assets/icons/logo.svg').default,
  iconShield: require('../assets/icons/icon-shield.svg').default,
  nutIcon: require('../assets/icons/nut-icon.svg').default,
  lockIcon: isEspectro ? require('../assets/icons/lock.svg').default : require('../assets/icons/lock-icon.svg').default,
  lockWarningIcon: require('../assets/icons/lock-icon-orange.svg').default,
  userIcon: isEspectro ? require('../assets/icons/user-espectro-blue.svg').default : require('../assets/icons/user-icon.svg').default,
  userWarningIcon: require('../assets/icons/user-warning.svg').default,
  goalIcon: require('../assets/icons/goal.svg').default,
  chevronDownIcon: require('../assets/icons/chevron-down.svg').default,
  chevronRightBlueIcon: require('../assets/icons/chevron-right-blue@3x.png'),
  checkIcon: require('../assets/icons/check.svg').default,
  crossIcon: require('../assets/icons/cross.png'),
  userRegularIcon: require('../assets/icons/user-regular.svg').default,
  wetFloorIcon: require('../assets/icons/wet-floor.svg').default,
  timeIcon: require('../assets/icons/time.svg').default,
  progressIcon: require('../assets/icons/progress-icon.svg').default,
  fileIcon: require('../assets/icons/file-icon.svg').default,
  uploadIcon: require('assets/icons/upload-icon.png'),
  warningIcon: require('../assets/icons/warningIcon@3x.png'),
  closeMaterial: require('../assets/icons/close-24px.svg').default,
  leftMaterial: require('../assets/icons/keyboard_arrow_left-24px.svg').default,
  rightMaterial: require('../assets/icons/keyboard_arrow_right-24px.svg').default,
  afterPasswordBackground: require('../assets/icons/after-password-background.svg').default,
  chevronLeft: require('../assets/icons/chevron-left.svg').default,
  guyWithCheckmark: require('../assets/icons/guy-with-checkmark.svg').default,
  home: require('../assets/icons/home.png'),
  hospital: require('../assets/icons/hospital.png'),
  greenSuccess: require('../assets/icons/green-success.png'),
  medicalKit: require('../assets/icons/medical-kit.png'),
  house: require('../assets/icons/house.png'),
  attachFile: require('../assets/icons/attach-files.png'),
  message: require('../assets/icons/message.png'),
  calendar: require('../assets/icons/calendar.png'),
  calendarCircle: require('../assets/icons/espectro/calendarCircle.svg').default,
  finished: require('../assets/icons/finished.png'),
  watching: require('../assets/icons/watching.png'),
  settingsColored: require('../assets/icons/settings-color.png'),
  docsOpened: require('../assets/icons/docs-opened.png'),
  searchIcon: require('../assets/icons/search-icon.png'),
  summary: require('../assets/icons/summary.png'),
  grayCross: require('../assets/icons/gray-cross.png'),
  greenCalender: require('../assets/icons/green-calander.png'),
  chevronDown: require('../assets/icons/chevron-down.png'),
  greenChecked: require('../assets/icons/green-check.png'),
  crown: require('../assets/icons/crown.png'),
  dpo: {
    positiveShield: require('../assets/icons/positiveShield.png'),
    management: require('../assets/icons/management.png'),
    planning: require('../assets/icons/planning.png'),
    distribution: require('../assets/icons/distribution.png'),
    fleet: require('../assets/icons/fleet.png'),
    stock: require('../assets/icons/stock.png'),
    people: require('../assets/icons/people.png'),
  },
  tick: require('../assets/icons/check.png'),
  edit: require('../assets/icons/edit.png'),
  addBlue: require('../assets/icons/blue-plus.png'),
  greenPlus: require('../assets/icons/green-plus.png'),
  comments: require('../assets/icons/empty-comments.svg').default,
  remove: require('../assets/icons/remove.png'),
  add: require('../assets/icons/add.png'),
  checked: require('../assets/icons/checked.png'),
  search_black: require('../assets/icons/search-black.png'),
  chevron_right: require('../assets/icons/chevron-right.png'),
  emptyCheck: require('../assets/icons/empty-check.svg').default,
  checkRedSelected: require('../assets/icons/check-red-selected.svg').default,
  emptyRadioUnchecked: require('../assets/icons/empty-radio-unchecked.svg').default,
  yellowRadioChecked: require('../assets/icons/yellow-radio-checked.svg').default,
  emailIcon: require('../assets/icons/email-icon.svg').default,
  positionIcon: require('../assets/icons/file-red.svg').default,
  deleteIcon: require('../assets/icons/delete-icon-empty.svg').default,
  editIcon: require('../assets/icons/edit-icon.svg').default,
  leftArrowIcon: require('../assets/icons/arrow-left.svg').default,
  blueFilledCheck: require('../assets/icons/blue-filled-check.svg').default,
  grayEmptyCheck: require('../assets/icons/gray-empty-check.svg').default,
  categoryUser: require('../assets/icons/category-user.png'),
  filter: require('../assets/icons/filters.svg').default,
  errorImage: require('../assets/icons/error-message.png'),
  warehouse: require('assets/icons/werehouse/warehouse.png'),
  factory: require('assets/icons/factory/factory.png'),
  download: require('assets/icons/img/download.png'),
  overwrite: require('assets/icons/img/overwrite.png'),
  upload: require('assets/icons/img/upload.png'),
  file: require('assets/icons/img/file.png'),
  info: require('assets/icons/img/info.png'),
  loadingIcon: require('assets/gif/loading.gif'),
  EmptyImg: require('assets/icons/img/empty-state.png'),
  espectro1: require('assets/gif/espectro1.gif'),
  espectro2: require('assets/gif/espectro2.gif'),
  espectro3: require('assets/gif/espectro3.gif'),
  copyIcon: require('assets/icons/copy-icon.svg').default,
  espectro: {
    abc: require('assets/icons/espectro/abc.svg').default,
    imagePickerIconRaw: require('assets/icons/espectro/imagePickerRaw.svg').default,
    calendar: require('assets/icons/espectro/calendar.svg').default,
    foto: require('assets/icons/espectro/foto.svg').default,
    horizontal: require('assets/icons/espectro/horizontal.svg').default,
    vertical: require('assets/icons/espectro/vertical.svg').default,
    num: require('assets/icons/espectro/num.svg').default,
    scribble: require('assets/icons/espectro/foto.svg').default,
    signature: require('assets/icons/espectro/signature.svg').default,
    backArrow: require('assets/icons/espectro/backArrow.svg').default,
    delete: require('assets/icons/espectro/deleteBlack.svg').default,
    deleteRed: require('assets/icons/espectro/deleteRed.png'),
    deleteBlack: require('assets/icons/espectro/deleteBlack.png'),
    threeDots: require('assets/icons/espectro/threeDots.svg').default,
    blueTick: require('assets/icons/espectro/espectro-tick.png'),
    upload: require('assets/icons/espectro/upload.png'),
    whiteDelete: require('assets/icons/espectro/whiteDelete.svg').default,
    checkSmall: require('assets/icons/check-small.svg').default,
    fileUser: require('assets/icons/file-user.svg').default,
    mail: require('assets/icons/espectro/mail.svg').default,
    users: require('assets/icons/espectro/users.svg').default,
    usersActive: require('assets/icons/espectro/users-active.svg').default,
    checklistDownload: require('assets/icons/espectro/checklist-download.svg').default,
    checklistIcon: require('assets/icons/espectro/checklist.png'),
    roundCheck: require('assets/icons/round-check.svg').default,
    pendingCheck: require('assets/icons/espectro/pendingChecklistIcon.png'),
    noteIcon: require('assets/icons/espectro/notesIcon.svg').default,
    rocketMan: require('assets/icons/espectro/user-and-rocket.png'),
    shareIcon: require('assets/icons/espectro/shareIcon.svg').default,
    verticalDots: require('assets/icons/espectro/verticalDots.svg').default,
    dropdownIcon: require('assets/icons/espectro/dropdown.png'),
    thunderIcon: require('assets/icons/espectro/thunderIcon.svg').default,
    successCheck: require('assets/icons/espectro/successCheck.svg').default,
    emptyCheck: require('assets/icons/espectro/emptyCheck.svg').default,
    blackDot: require('assets/icons/espectro/blackDot.svg').default,
    flash: require('assets/icons/espectro/flash.svg').default,
    refresh: require('assets/icons/espectro/refresh.svg').default,
    notesIcon: require('assets/icons/espectro/note.svg').default,
    numberInputIcon: require('assets/icons/espectro/numberInputIcon.svg').default,
    imagePickerIcon: require('assets/icons/espectro/imagePicker.svg').default,
    assetsIcon: require('assets/icons/espectro/assetsIcon.svg').default,
    assetsIconGreen: require('assets/icons/espectro/assets.svg').default,
    checkboxIcon: require('assets/icons/espectro/checkbox.svg').default,
    checklistIconSvg: require('assets/icons/espectro/checklist.svg').default,
    instructionSvg: require('assets/icons/espectro/info.svg').default,
    dangerSvg: require('assets/icons/espectro/danger.svg').default,
    successSvg: require('assets/icons/espectro/success.svg').default,
    diagramIcon: require('assets/icons/espectro/diagram.svg').default,
    dropdownIconSvg: require('assets/icons/espectro/dropdown.svg').default,
    radioIcon: require('assets/icons/espectro/radioButton.svg').default,
    combinedShape: require('assets/icons/espectro/combinedShape.png'),
    combinedShapeGrey: require('assets/icons/espectro/combinedShapeGrey.png'),
    archiveIcon: require('assets/icons/espectro/archive.png'),
    assetsList: require('assets/icons/espectro/List.png'),
    sittingGuyIcon: require('assets/icons/espectro/sittingGuyIcon.png'),
    infoBlue: require('assets/icons/espectro/infoBlue.png'),
    danger: require('assets/icons/espectro/danger.png'),
    plusWhite: require('assets/icons/espectro/plusWhite.svg').default,
    deleteRedEmpty: require('assets/icons/espectro/deleteRedEmpty.png'),
    qrCode: require('assets/icons/espectro/qrCode.png'),
    file: require('assets/icons/espectro/file-bad.svg').default,
    info: require('assets/icons/espectro/infoBlue.svg').default,
    dangerRed: require('assets/icons/espectro/dangerRed.svg').default,
    success: require('assets/icons/espectro/successGreen.svg').default,
    cancelWorkOrder: require('assets/icons/espectro/cancelWorkOrder.png'),
    sendEmail: require('assets/icons/espectro/sendEmail.png'),
    account: require('assets/icons/espectro/account.svg').default,
    home: require('assets/icons/espectro/Home.svg').default,
    duplicate: require('assets/icons/espectro/duplicate.svg').default,
    duplicateV1: require('assets/icons/espectro/duplicate-v1.svg').default,
    media: require('assets/icons/espectro/media.svg').default,
    deleteFill: require('assets/icons/espectro/delete.svg').default,
    star: require('assets/icons/espectro/star.png'),
    listIcon: require('assets/icons/espectro/listIconV2.png'),
    listIconLight: require('assets/icons/espectro/listIconLight.png'),
    calendarV2: require('assets/icons/espectro/calendarV2.png'),
    calendarIconLight: require('assets/icons/espectro/calendarIconLight.png'),
    xlsxIcon: require('assets/icons/espectro/xlsx.png'),
  },
};

export default ImageLinks;
