import React, { useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { COLOR_CODE } from 'types/enums';
import { dataURLtoBlob } from 'utils/ImageHelper';
import ActionButton from '../Buttons/Action/Action';
import './signature.scss';

type Props = {
  signatureContainer?: any,
  buttonStyle?: any,
  onSignatureSave: (data: any)=> void,
};
function SignatureComponent(props: Props) {
  const { onSignatureSave, signatureContainer } = props;
  // Specify the type of the signatureRef using SignatureCanvas type
  const signatureRef = React.useRef<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const onHandleClear = () => {
    signatureRef?.current?.clear();
  };

  const onHandleSaveSignature = async () => {
    // Get the signature data as a base64 encoded string
    const data = signatureRef?.current?.toDataURL();
    const blobObject = dataURLtoBlob(data);
    setIsLoading(true);
    onSignatureSave(blobObject);
    setIsLoading(false);
  };

  return (
    <div className="signatureContainer" style={signatureContainer}>
      <SignatureCanvas
        ref={signatureRef}
        canvasProps={{ width: 400, height: 100 }}
        onEnd={onHandleSaveSignature}
      />
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
        <ActionButton
          text="Clear"
          onClick={onHandleClear}
          background={COLOR_CODE.ESPECTRO_PRIMARY}
          style={{ marginLeft: 20, marginRight: 20, color: 'white', width: '80%' }}
          disabled={isLoading}
        />

      </div>

    </div>
  );
}
export default SignatureComponent;
