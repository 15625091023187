/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useRef, useState } from 'react';
import QRCode from 'qrcode';
import Overlay from 'components/Espectro/Overlay';
import copyIcon from 'assets/copy-new.svg';
import downloadIcon from 'assets/download-new.svg';
import { useTranslation } from 'react-i18next';
import { TRANSLATIONS } from 'types/enums';
import { useNavigate } from 'react-router-dom';
import './AssetsDashboard.scss';
import Header from 'components/Header';
import i18next from 'i18next';
import cross from 'assets/cross-square.svg';
import NavigationDrawerWithSideMenu
  from 'components/NavigationDrawer/NavigationDrawerWithVerticalMenu';
import ImageLinks from 'utils/ImageLinks';
import InputWithLabel from 'components/Input/InputWithLabel/InputWithLabel';
import LoadingSpinner from 'components/LoadingSpinner';
import CustomTable from 'components/Espectro/Table';
import defaultRoutes from 'utils/defaultRoutes';
import { useGetListItemForCompanyQuery, useGetListItemsFromSearchWithoutSlugLazyQuery } from 'generated/graphql';
import { useSelector } from 'react-redux';
import { Button, Menu, MenuItem } from '@mui/material';
import { selectUserLocation, selectUserType } from 'store/selectors/user.selectors';
import BasicButton from 'components/Button/BasicButton';
import checklistSearch from '../../../assets/icons/input-search.svg';

const AssetsDashboard = () => {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);
  const [search, setSearch] = useState('');
  const userType = useSelector(selectUserType);
  const location = useSelector(selectUserLocation);
  const [qr, setQr] = useState<string>('');
  const [assetNameForQr, setAssetNameForQr] = useState<string | null>(null);
  const [isQRmodalOpen, setIsQRmodalOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedAssetId, setSelectedAssetId] = useState<number>(-1);

  const open = Boolean(anchorEl);

  const navigate = useNavigate();

  const {
    data: fetchedListItemsForCompany,
    loading: isFetchListItemsQueryLoading,
    refetch,
  } = useGetListItemForCompanyQuery();

  const [callGetListItemForCompanySearch, data] = useGetListItemsFromSearchWithoutSlugLazyQuery();

  useEffect(() => {
    refetch();
  }, [refetch]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSearch = async (event: any) => {
    callGetListItemForCompanySearch({
      variables: {
        searchString: event.target.value as string,
      },
    });
    setSearch(event.target.value);
  };

  const onPressAddAsset = () => {
    navigate('/espectro/assets/add-asset/');
  };

  const assets = search.length > 0
    ? data.data?.getListItemsFromSearchWithoutSlug
    : fetchedListItemsForCompany?.getListItemsOfCompany;

  const onPressEditAsset = () => {
    const asset = assets?.find(a => Number(a?.id) === selectedAssetId);
    navigate(`/espectro/assets/add-asset/${asset?.id ?? null}`);
  };

  const generateQrCode = (name: string) => {
    setAssetNameForQr(name);
    QRCode.toDataURL(name, {
      width: 220,
      color: {
        dark: '#000000',
        light: '#ffffff',
      },
      margin: 2,
    }, (err, url) => {
      if (err) {
        return;
      }
      setQr(url);
    });
  };

  const handleViewQRCode = () => {
    const asset = assets?.find(a => Number(a?.id) === selectedAssetId);
    generateQrCode(asset?.description ?? '');
    setIsQRmodalOpen(true);
  };

  const threeDot = (assetId: number) => (
    <div style={{ margin: 0 }}>
      <Button
        id="demo-positioned-button"
        aria-controls={open ? 'demo-positioned-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        style={{ borderRadius: 20, boxShadow: 'none', margin: 0 }}
        onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
          handleClick(e);
          setSelectedAssetId(assetId);
        }}
      >
        <img
          alt="dots"
          style={{ cursor: 'pointer' }}
          width={15}
          height={15}
          src={ImageLinks.espectro.verticalDots}
        />
      </Button>
    </div>
  );

  const userLang = i18next.language;

  const viewAssetInfo = (id: string) => {
    navigate(`/espectro/assets/assets-info/${id}`);
  };

  return (
    <div className="page-container">
      <Header />
      <NavigationDrawerWithSideMenu
        {...{
          routes: defaultRoutes(t, userType, location),
          openDrawer: true,
        }}
      />
      <div className="assets-dashboard__container">
        <div className="assets-dashboard__flex-row">
          <div style={{ justifyContent: 'center' }}>
            <h1>{t(TRANSLATIONS.MY_ASSETS)}</h1>
          </div>
          <BasicButton
            onClick={onPressAddAsset}
            className="add_asset-button"
            text={t(TRANSLATIONS.ADD_ASSET)}
            background="#6C63FF"
          />
        </div>

        <div className="assets-search-dropdown">
          <InputWithLabel
            inputRef={inputRef}
            inputImg={checklistSearch}
            onChange={handleSearch}
            placeholder={t(TRANSLATIONS.FIND_ACTIVE)}
            value={search}
            labelClassName="search-header"
            type="search"
            inputClassName="search-input"
          />
        </div>

        {isFetchListItemsQueryLoading ? (
          <div style={{ height: 400 }}>
            <LoadingSpinner positionTop={200} />
          </div>
        ) : (
          <>
            {!!assets
              && assets!.length > 0 && (
              <div
                className="assets-dashboard__cards-row assets-dashboard_scroll-view"
                style={{ backgroundColor: '#FFFFFF' }}
              >
                <CustomTable
                  onClickRow={viewAssetInfo}
                  header={[
                    t(TRANSLATIONS.NAME_INSPECTION),
                    userLang.includes('en') ? `${t(TRANSLATIONS.ASSETS)} ${t(TRANSLATIONS.NAME)}`
                      : `${t(TRANSLATIONS.NAME)} ${t(TRANSLATIONS.ASSETS)}`,
                    t(TRANSLATIONS.TYPE),
                    '',
                  ]}
                  sortableColumns={[2]}
                  rows={
                    assets!.map(row => {
                      return {
                        rowData: [
                          row!.name,
                          row!.description,
                          row!.list!.name,
                          threeDot(Number(row!.id)),
                        ],
                        index: row!.id,
                      };
                    })
                  }
                />
              </div>
            )}
            {
              open && selectedAssetId !== -1 && (
                <Menu
                  id="demo-positioned-menu"
                  aria-labelledby="demo-positioned-button"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  className="assets_dashboard-option-menu shadow-none"
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <MenuItem
                    className="assets_dashboard-menu-item shadow-none"
                    onClick={(e: React.MouseEvent<HTMLElement>) => {
                      e.stopPropagation();
                      handleViewQRCode();
                    }}
                  >
                    <div className="assets_dashboard-menu-item-div shadow-none">
                      <img src={ImageLinks.espectro.qrCode} style={{ height: 22, width: 22 }} alt="delete" />
                      <span>View QR code</span>
                    </div>
                  </MenuItem>
                  <MenuItem
                    className="assets_dashboard-menu-item shadow-none"
                    onClick={e => {
                      e.stopPropagation();
                      onPressEditAsset();
                    }}
                  >
                    <div className="assets_dashboard-menu-item-div shadow-none">
                      <img src={ImageLinks.edit} style={{ height: 22, width: 22 }} alt="delete" />
                      <span>Edit Asset</span>
                    </div>
                  </MenuItem>
                </Menu>
              )
            }
            {(fetchedListItemsForCompany?.getListItemsOfCompany ?? []).length <= 0 && (
              <div className="assets-dashboard__no-checklist-container">
                <img src={ImageLinks.espectro.sittingGuyIcon} style={{ width: '15%', marginTop: '40px' }} alt="no data" />
                <p className="assets-dashboard__no-checklist-h">{t(TRANSLATIONS.THERE_ARE_NO_ASSETS_YET)}</p>
                <p className="assets-dashboard__no-checklist-p">{t(TRANSLATIONS.PRESS_ADD_ASSET_TO_GET_STARTED)}</p>
              </div>
            )}
          </>
        )}
      </div>
      <Overlay open={isQRmodalOpen} onClose={() => setIsQRmodalOpen(false)}>
        <div style={{ maxWidth: 400, display: 'flex', flexDirection: 'column', marginLeft: 'auto', marginRight: 'auto', backgroundColor: 'white', justifyContent: 'center', alignItems: 'center', marginTop: 200, padding: 10, paddingBottom: 15, paddingTop: 15, borderRadius: '12px' }}>
          <div className="assetsInfo__overlay_head">
            <p className="assetsInfo__overlay_p">
              Descarga este código QR para compartirlo con tu equipo.
              Escaneándolo los usuarios podrán ver el perfil del activo y muchas cosas más.
            </p>
            <img onClick={() => setIsQRmodalOpen(false)} style={{ width: 28, height: 28, cursor: 'pointer' }} alt="grayCross" src={cross} />
          </div>
          <div className="assetsInfo_qr_container">
            <div>
              {qr && <img src={qr} alt="qr" />}
            </div>
            <p style={{ margin: 0 }} className="assetsInfo__name">{assetNameForQr}</p>
          </div>
          <div className="assetsInfo__download">
            <Button variant="outlined" onClick={() => { navigator.clipboard.writeText(assetNameForQr as string); }}>
              <img style={{ height: 18, width: 18 }} alt="copyIcon" src={copyIcon} />
              <p style={{ textTransform: 'none' }}>Copiar link</p>
            </Button>
            <Button style={{ background: '#6C63FF' }} variant="outlined" href={qr} download={`${assetNameForQr}.png`}>
              <img style={{ height: 16, width: 16, marginRight: 4 }} alt="download" src={downloadIcon} />
              <p style={{ textTransform: 'none', color: '#fff' }}>Descargar QR</p>
            </Button>
          </div>
        </div>
      </Overlay>
    </div>
  );
};

export default AssetsDashboard;
