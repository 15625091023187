import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Header from 'components/Header';
import NavigationDrawerWithSideMenu
  from 'components/NavigationDrawer/NavigationDrawerWithVerticalMenu';
import defaultRoutes from 'utils/defaultRoutes';
import { selectUser, selectUserLocation, selectUserType } from 'store/selectors/user.selectors';
import './DownloadReport.scss';
import { TRANSLATIONS } from 'types/enums';
import BasicButton from 'components/Button/BasicButton';
import { useNavigate } from 'react-router-dom';
import { Maybe, RoutineAvailable, useGetRoutinesAvailableWithReportInfoQuery, useListWorkOrderReportsQuery } from 'generated/graphql';
import CustomTable from 'components/Espectro/Table';
import i18next from 'i18next';
import { addMinutes, differenceInDays, differenceInHours, differenceInMinutes, differenceInMonths } from 'date-fns';
import LoadingSpinner from 'components/LoadingSpinner';
import { TabItem } from 'components/Espectro/Tabs/Tabs';
import Tabs from 'components/Espectro/Tabs';
import ChecklistSvg from 'assets/icons/espectro/checklist-new.svg';
import axios from 'axios';
import moment from 'moment';
import noChecklists from '../../../assets/icons/noChecklists.svg';

const DownloadReport = () => {
  const userType = useSelector(selectUserType);
  const location = useSelector(selectUserLocation);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const TABS: TabItem[] = [
    { title: t(TRANSLATIONS.WORK_ORDERS), value: '0' },
    { title: t(TRANSLATIONS.CHECKLIST), value: '1' },
  ];
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [selectedTab, setSelectedTab] = useState<TabItem>(TABS[0]);
  const [isLoadingWorkOrder, setIsLoadingWorkOrder] = useState<boolean>(true);

  const onClickNewChecklist = () => { navigate('/espectro/new-checklist-builder'); };

  const { data, loading } = useGetRoutinesAvailableWithReportInfoQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted: () => {
      setIsLoading(false);
    },
  });

  const { data: workOrderData, loading: workOrderLoading } = useListWorkOrderReportsQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted: () => {
      setIsLoadingWorkOrder(false);
    },
  });

  const routinesAvailable = data?.getRoutinesAvailableWithReportInfo;
  const workOrderReports = workOrderData?.listWorkOrderReports;

  const onClickCard = (id: string) => {
    navigate('/espectro/checklist-report', { state: { id } });
  };

  const downloadWorkOrder = async (id: string, completedAt: string) => {
    const newTab = window.open(`/api/v1/work-order/download-report/${id}`);
    const response = await axios.request({
      method: 'get',
      url: `/api/v1/work-order/download-report/${id}`,
      headers: {
        'Access-Control-Allow-Origin': '*',
      },
      responseType: 'arraybuffer',
    });
    const downloadurl = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = downloadurl;

    link.setAttribute('download', `${moment(new Date(completedAt)).format('yy-MM-DD')} ${moment(new Date(completedAt))
      .format('HH:mm:ss')}.pdf`);
    document.body.appendChild(link);
    link.click();
    newTab?.close();
  };

  const userDetails = useSelector(selectUser);

  const convertCreatedDate = (dateString: string) => {
    const { language }: { language: 'es' | 'en-GB' } = i18next as any;
    if (dateString === null) return '-';

    const completedAt = new Date(dateString);
    const currentDate = Date.now();
    const timezoneOffset = completedAt.getTimezoneOffset();
    const completedAtDate = addMinutes(completedAt, timezoneOffset);

    const locales = {
      'en-GB': {
        minute: 'minute',
        minutes: 'minutes',
        hour: 'hour',
        hours: 'hours',
        day: 'day',
        days: 'days',
        month: 'month',
        months: 'months',
        and: 'and',
      },
      es: {
        minute: 'minuto',
        minutes: 'minutos',
        hour: 'hora',
        hours: 'horas',
        day: 'día',
        days: 'días',
        month: 'mes',
        months: 'meses',
        and: 'y',
      },
    };

    const locale = locales[language] || locales.es;

    const minutes = differenceInMinutes(currentDate, completedAtDate);
    if (minutes < 60) {
      return `${minutes} ${locale[minutes !== 1 ? 'minutes' : 'minute']} ago`;
    }

    const hours = differenceInHours(currentDate, completedAtDate);
    if (hours < 24) {
      return `${hours} ${locale[hours !== 1 ? 'hours' : 'hour']} ago`;
    }

    const days = differenceInDays(currentDate, completedAtDate);
    if (days < 30) {
      return `${days} ${locale[days !== 1 ? 'days' : 'day']} ago`;
    }

    const months = differenceInMonths(currentDate, completedAtDate);
    const remainingDays = days - months * 30;
    return `${months} ${locale[months !== 1 ? 'months' : 'month']} ${
      locale.and} ${remainingDays} ${locale[remainingDays !== 1 ? 'days' : 'day']} ago`;
  };

  const convertLastUpdatedDate = (updatedAtDate: string) => {
    const date = moment(updatedAtDate).format('DD.MM.YY hh:mmA');
    return date;
  };

  const renderWorkOrderTitle = (workOrderTitle?: string | null) => (
    <p
      title={workOrderTitle || ''}
      className="download_report-work-order-title"
    >
      {workOrderTitle}
    </p>
  );

  const renderNameAndTagForRoutine = (row: Maybe<{
    __typename?: 'RoutineAvailable' | undefined;
  } & Pick<RoutineAvailable, 'id' | 'name' | 'lastReportCompletedAt' | 'totalReports' | 'isScheduledForDeletion'>>) => {
    const isDeleted = row?.isScheduledForDeletion;
    return (
      <>
        {row!.name}
        {
          isDeleted && (
            <div className="download_report-deleted-tag">
              • Deleted
            </div>
          )
        }
      </>
    );
  };

  return (
    <div className="download_report-main-container">
      <Header />
      <NavigationDrawerWithSideMenu
        {...{
          routes: defaultRoutes(t, userType, location, userDetails),
          openDrawer: true,
        }}
      />
      {
        !loading || !isLoadingWorkOrder ? (
          <div className="download-report__container">
            <div className="report_checklist-builder__flex-row">
              <h1>{t(TRANSLATIONS.REPORT)}</h1>
            </div>
            <div className="reports__tab">
              <Tabs
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
                tabsItems={TABS}
                tabStyle="reports__tab"
                tabNotSelectedStyle="reports__not-selected-tab"
              />
            </div>
            {selectedTab.value === '1' && !!routinesAvailable && routinesAvailable!.length > 0 && !isLoading && (
              <div className="checklist-builder__cards-row">
                <CustomTable
                  onClickRow={onClickCard}
                  header={[
                    t(TRANSLATIONS.CHECKLIST_NAME),
                    t(TRANSLATIONS.LAST_ACTIVITY),
                    t(TRANSLATIONS.TOTAL_CHECKLIST_REPORTS),
                  ]}
                  rows={
                  routinesAvailable!.map(row => {
                    return {
                      rowData: [
                        renderNameAndTagForRoutine(row!),
                        convertCreatedDate(row?.lastReportCompletedAt),
                        row?.totalReports as string,
                      ],
                      index: row!.id,
                    };
                  })
                }
                />
              </div>
            )}
            {selectedTab.value === '0' && !isLoadingWorkOrder && !workOrderLoading && !!workOrderReports && workOrderReports!.length && (
              <div className="checklist-builder__cards-row">
                <CustomTable
                  onClickRow={() => {
                    //
                  }}
                  header={[
                    t(TRANSLATIONS.NAME),
                    t(TRANSLATIONS.LAST_ACTIVITY),
                    'Fecha finalización',
                    t(TRANSLATIONS.ACTIONS),
                  ]}
                  rows={
                    workOrderReports!.map(row => {
                      return {
                        rowData: [
                          renderWorkOrderTitle(row!.workOrder?.name),
                          convertCreatedDate(row?.updatedAt),
                          convertLastUpdatedDate(row?.updatedAt),
                          <button
                            type="button"
                            className="download_wo__button"
                            onClick={() => {
                              downloadWorkOrder(String(row?.id), row?.createdAt);
                            }}
                          >
                            <img style={{ height: 24, width: 24 }} alt="checklist" src={ChecklistSvg} />
                            {t(TRANSLATIONS.DOWNLOAD_REPORT)}
                          </button>,
                        ],
                        index: row!.id,
                      };
                    })
                }
                />
              </div>
            )}
            {
              !isLoading && !routinesAvailable?.length && (
                <div className="checklist-builder__no-checklist-container">
                  <img src={noChecklists} style={{ margin: '2rem 0' }} alt="noChecklists" />
                  <p className="checklist-builder__no-checklist-h">{t(TRANSLATIONS.NEW_CHECKLIST)}</p>
                  <p className="checklist-builder__no-checklist-p">{t(TRANSLATIONS.FIRST_CHECKLIST)}</p>
                  <BasicButton
                    onClick={onClickNewChecklist}
                    className="checklist-builder__upload_button checklist-builder__no-checklist-margin-hor-16"
                    text={t(TRANSLATIONS.NEW_CHECKLIST)}
                    background="#6C63FF"
                  />
                </div>
              )
            }
          </div>
        ) : <LoadingSpinner positionTop={300} text="loading..." />
      }
    </div>
  );
};

export default DownloadReport;
