import { SxProps, Theme } from '@mui/system';

const itemCommon = {
  borderTopRightRadius: '30px',
  borderBottomRightRadius: '30px',
  padding: '5px 20px',
  marginRight: '15px',
};

export const drawerPaper: SxProps<Theme> = {
  top: '20px',
  backgroundColor: 'transparent',
  height: '80vh',
  overflowX: 'hidden',
  borderRight: 'none',
  width: 180,
  paddingTop: '2.6rem',
  gap: '18px',
};

export const DrawerPaperAnchorLeft: SxProps<Theme> = {
  borderRight: 'none',
};

export const root: SxProps<Theme> = {
  ...itemCommon,
  '&.Mui-selected': {
    backgroundColor: '#E4F2DA',
    color: '#70AD47',
  },
  color: '#878787',
  marginTop: '0.5rem',
};

export const selected: SxProps<Theme> = {};

export const primary: SxProps<Theme> = {
  color: '#56CD9D',
};

export const fontStyle: SxProps<Theme> = {
  fontSize: '8px',
  fontWeight: '500',
};

export const primaryV1: SxProps<Theme> = {
  color: '#56CD9D',
};

export const version: SxProps<Theme> = {
  position: 'absolute',
  bottom: '15px',
  width: 200,
};

export const highlightBackground = {
  backgroundColor: '#E4F2DA',
};

export const rootInsideCollapse: SxProps<Theme> = {
  '&.Mui-selected': {
    backgroundColor: '#E4F2DA',
  },
  ...itemCommon,
  borderRadius: '30px',
};

export const selectedInsideCollapse: SxProps<Theme> = {
  backgroundColor: '#F0F0F0',
  borderRadius: '30px',
};

export const selectedInsideCollapseV1: SxProps<Theme> = {
  backgroundColor: 'unset',
};

export const nested: SxProps<Theme> = {
  paddingLeft: '16px',
  marginRight: '4px',
  marginLeft: '2px',
  marginTop: '4px',
};

export const listIcon = {
  height: '20px',
  width: '20px',
  marginRight: '10px',
};

export const collapseListIcon = {
  position: 'absolute',
  left: '-2px',
};

export const rootText: SxProps<Theme> = {
  fontSize: '14px',
};

export const userManual: SxProps<Theme> = {
  position: 'absolute',
  zIndex: 2,
  bottom: '80px',
  width: 161,
};

export const userManualListIcon: SxProps<Theme> = {
  height: '20px',
  width: '20px',
  marginRight: '10px',
  marginBottom: '1px',
};
