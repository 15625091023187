// eslint-disable-next-line
export const isProd = process.env.NODE_ENV === 'production';
export const isEspectro = true;
export const REACT_APP_BASE_URL = 'http://localhost:3000';
interface ReportsMap {
  [org:string]: string;
}
export const DATA_STUDIO_REPORT_ULRS: ReportsMap = {
  Yadran: 'https://app.powerbi.com/view?r=eyJrIjoiOGNjNzhhZmUtN2M2NC00ZTk4LTg3NDItNTI2NjQ4N2FjNzcxIiwidCI6ImJlMTliNmNlLTdkMTktNGVkOS05NDgzLWIzM2I5NDI3ZTc4YyJ9',
  'ABI Supply': 'https://app.powerbi.com/view?r=eyJrIjoiODdiZWQ2M2ItM2Y2MS00NDMxLWI0NDEtOWIxMWE4NDUyMDA2IiwidCI6ImJlMTliNmNlLTdkMTktNGVkOS05NDgzLWIzM2I5NDI3ZTc4YyJ9',
};

export const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
export const ABI_COMPANY_ID = 21;
export const COMPANIES_FOR_ABI_ONBOARDING = [{
  label: 'ABI Supply',
  value: 'ABI Supply',
}, {
  label: 'Contractor',
  value: 'Contractor',
}];

export const CALENDAR_DAYS = {
  es: [{
    label: 'Domingo',
    value: 0,
  }, {
    label: 'Lunes',
    value: 1,
  }, {
    label: 'Martes',
    value: 2,
  }, {
    label: 'Miércoles',
    value: 3,
  }, {
    label: 'Jueves',
    value: 4,
  }, {
    label: 'Viernes',
    value: 5,
  }, {
    label: 'Sábado',
    value: 6,
  }],
  en: [{
    label: 'Sunday',
    value: 0,
  }, {
    label: 'Monday',
    value: 1,
  }, {
    label: 'Tuesday',
    value: 2,
  }, {
    label: 'Wednesday',
    value: 3,
  }, {
    label: 'Thursday',
    value: 4,
  }, {
    label: 'Friday',
    value: 5,
  }, {
    label: 'Saturday',
    value: 6,
  }],
};

export const UserTypesWithLabels: { [key: string]: string } = {
  SubzoneGeneralManagerDPO: 'Business',
  ReporterOnlyDPO: 'Lite',
  EspectroViewOnly: 'Read only',
};
