/* eslint-disable prefer-destructuring */
import './NewChecklist.scss';
import uuidv4 from 'uuid/v4';
import { cloneDeep } from 'lodash';
import { TabItem } from 'components/Espectro/Tabs/Tabs';
import LoadingSpinner from 'components/LoadingSpinner';
import { useNavigate, useLocation } from 'react-router-dom';
import { ToastOptions, toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import React, {
  Reducer,
  useState,
  useEffect,
  useReducer,
} from 'react';
import {
  useGenerateShareableLinkForRoutineAvailableMutation,
  useGetRoutineAvailableLazyQuery,
  useSaveRoutinesAvailableMutation,
} from 'generated/graphql';
import {
  TRANSLATIONS,
  LocalLogicActionType,
  LocalLogicTriggerActionType,
} from 'types/enums';
import {
  Actions,
  Frequency,
  LocalLogic,
  ChecklistState,
  LocalLogicAction,
  ChecklistSection,
  ChecklistBitTypes,
  LocalLogicTrigger,
  LocalLogicTriggerAction,
  showIfOperators,
  showIf,
  ChecklistItem,
} from '../types';

import AddLogic from './AddLogic';
import AddAnswers from './AddAnswers';
import ChecklistHeader from '../components/ChecklistHeader';

import { LOCAL_LOGIC_INIT, TRIGGER_INT } from '../constants';
import { getLocalLogicFromSections, mergeLocalLogicAndSections } from '../utils';
import ShareableChecklistModal from '../../../components/Espectro/ShareableChecklistModal';

export interface StateType { id: string }

const localLogicsReducer = (
  prevState: { [key: number]: LocalLogic[] },
  action: LocalLogicAction,
): { [key: number]: LocalLogic[] } => {
  let logicArray;
  const type = action.type;
  const payload = action.payload as any;

  switch (type) {
    case LocalLogicActionType.BASIC_INIT:
      logicArray = { 0: [{ ...LOCAL_LOGIC_INIT, id: uuidv4() }] };
      return logicArray;
    case LocalLogicActionType.ADD_NEW_LOGIC:
      logicArray = { ...prevState };
      logicArray[payload.localLogicMapKey] = [{ ...LOCAL_LOGIC_INIT, id: uuidv4() }];
      return logicArray;
    case LocalLogicActionType.REMOVE_LOGIC:
      logicArray = { ...prevState };
      delete logicArray[payload.localLogicMapKey];
      let indexCount = 0;
      const newObj: { [key: number]: LocalLogic[] } = {};
      Object.keys(logicArray).forEach(l => {
        newObj[indexCount] = prevState[Number(l)];
        indexCount += 1;
      });
      return newObj;
    case LocalLogicActionType.INITIALIZE_WITH_PAYLOAD:
      logicArray = payload;
      return logicArray;
    case LocalLogicActionType.UPDATE_LOGIC_QUESTION:
      logicArray = { ...prevState };
      logicArray[payload.localLogicMapKey][payload.logicIndex].sectionIndex = payload.sectionIndex;
      logicArray[payload.localLogicMapKey][payload.logicIndex].itemIndex = payload.itemIndex;
      return logicArray;
    case LocalLogicActionType.UPDATE_LOGIC_OPERATOR:
      logicArray = { ...prevState };
      logicArray[payload.localLogicMapKey][payload.logicIndex].operation = payload.operator;
      return logicArray;
    case LocalLogicActionType.ADD_LOGIC_SELECT:
      logicArray = { ...prevState };
      logicArray[payload.localLogicMapKey][payload.logicIndex].value = payload.value;
      return logicArray;
    case LocalLogicActionType.ADD_MULTIPLE_SOURCE_ITEM_TRIGGER:
      logicArray = { ...prevState };
      logicArray[payload.localLogicMapKey].push({ ...LOCAL_LOGIC_INIT, id: uuidv4() });
      return logicArray;
    case LocalLogicActionType.DELETE_SOURCE_ITEM_TRIGGER:
      logicArray = { ...prevState };
      logicArray[payload.localLogicMapKey].splice(payload.localLogicIndex, 1);
      return logicArray;
    case LocalLogicActionType.CLEAR:
      prevState = [[]];
      return prevState;
    default:
      return { ...prevState };
  }
};

const localLogicTriggersReducer = (
  prevState: LocalLogicTrigger[],
  action: LocalLogicTriggerAction,
): LocalLogicTrigger[] => {
  let array;
  const type = action.type;
  const payload = action.payload as any;

  switch (type) {
    case LocalLogicTriggerActionType.BASIC_INIT:
      array = [{ ...TRIGGER_INT }];
      console.log('Initializing');
      return array;
    case LocalLogicTriggerActionType.INITIALIZE_WITH_PAYLOAD:
      array = payload;
      return array;
    case LocalLogicTriggerActionType.UPDATE_TRIGGER:
      array = cloneDeep(prevState);
      console.log({ array });
      console.log({ array: payload.logicIndex });
      array[payload.logicIndex].trigger = payload.trigger;
      if (payload.trigger === Actions.SEND_EMAIL) {
        array[payload.logicIndex].sectionIndex = payload.sectionIndex;
        array[payload.logicIndex].itemIndex = payload.itemIndex;
      }
      return array;
    case LocalLogicTriggerActionType.REMOVE_TRIGGER:
      if (payload.logicIndexToDelete >= 0) {
        array = [...prevState];
        const triggersAfterDeletion = array
          .filter((l, i) => i !== payload?.logicIndexToDelete);
        return triggersAfterDeletion;
      }
      return [...prevState];
    case LocalLogicTriggerActionType.ADD_NEW_LOGIC_TRIGGER:
      array = [...prevState, { ...TRIGGER_INT }];
      return array;
    case LocalLogicTriggerActionType.ADD_RECIPIENTS:
      array = [...prevState];
      array[payload.logicIndex].recipient = payload.recipient;
      return array;
    case LocalLogicTriggerActionType.ADD_TRIGGER_QUESTION:
      array = [...prevState];
      array[payload.logicIndex].sectionIndex = payload.sectionIndex;
      array[payload.logicIndex].itemIndex = payload.itemIndex;
      return array;
    default:
      return [...prevState];
  }
};

const NewChecklist = () => {
  const { t } = useTranslation();
  const TABS: TabItem[] = [
    { title: t(TRANSLATIONS.QUESTIONS), value: '0' },
    { title: t(TRANSLATIONS.LOGICAL_QUESTION), value: '1' },
  ];

  const toastConfig = {
    position: 'top-right',
    autoClose: 4000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: false,
    progress: undefined,
  } as ToastOptions;

  const { state: routerState } = useLocation();
  const passedRoutineAvailableId = routerState?.id;

  const [localLogics, setLocalLogics] = useReducer<
  Reducer<{ [key: number]: LocalLogic[] }, LocalLogicAction>
  >(
    localLogicsReducer,
    [[{ ...LOCAL_LOGIC_INIT, id: uuidv4() }]],
  );

  const [localLogicTriggers, setLocalLogicTriggers] = useReducer<
  Reducer<LocalLogicTrigger[], LocalLogicTriggerAction>
  >(
    localLogicTriggersReducer,
    [{ ...TRIGGER_INT }],
  );

  const [sections, setSections] = useState<ChecklistSection[]>([{
    title: '',
    description: '',
    isExpandedDefault: false,
    items: [],
    key: 'key__0',
  }]);

  const [globalEmailRecipients, setGlobalEmailRecipients] = useState<string[]>([]);
  const [selectedTab, setSelectedTab] = useState<TabItem>(TABS[0]);
  const [isLoading, setIsLoading] = useState<boolean>(!!passedRoutineAvailableId);
  const [multiTriggerOptions, setMultiTriggerOptions] = useState<showIfOperators[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [headerHeight, setHeaderHeight] = useState<number>(0);
  const [captureSignatureBeforeSubmit, setCaptureSignatureBeforeSubmit] = useState<boolean>(false);

  const [state, setState] = useState<ChecklistState>({
    title: 'Title',
    frequency: Frequency.DAILY,
    description: '',
    shareableToken: null,
    versionNumber: undefined,
  });

  const [generateShareableLink, {
    loading: isGenerateShareableLinkMutationLoading,
  }] = useGenerateShareableLinkForRoutineAvailableMutation({
    onCompleted: d => {
      const fetchedRoutines = d.generateShareableLinkForRoutineAvailable.routines;
      setState({
        ...state,
        shareableToken: String(
          fetchedRoutines[0]?.shareableToken,
        ),
      });
    },
  });

  const generateShareableToken = () => {
    const tokenRouteSplit = state.shareableToken?.split('/') ?? [];
    const tokenValue = tokenRouteSplit[tokenRouteSplit.length - 1];
    const isTokenNull = tokenValue === 'null';

    if (isTokenNull) {
      generateShareableLink({
        variables: {
          routineAvailableId: String(passedRoutineAvailableId),
          responseModeIndividualChecklist: true,
        },
      });
    }
    setIsModalOpen(!isModalOpen);
  };

  const onHandleConfirm = () => {
    navigator.clipboard.writeText(String(state.shareableToken));
    setIsModalOpen(false);
  };

  const shareableToken = String(state.shareableToken);

  const navigate = useNavigate();

  const purgeUnwantedPropsFromChecklistBeforeSaving = () => {
    const checklistSections = cloneDeep(sections);
    checklistSections.forEach((section, sectionIndex) => {
      section.key = `${section.key}__v${state.versionNumber ?? 1}`;
      section.items.forEach((item, itemIndex) => {
        item.key = `${item.key}__v${state.versionNumber ?? 1}`;
        const bitProps = item.bits[0].props;
        switch (item.bits[0].type) {
          case ChecklistBitTypes.TIME:
          case ChecklistBitTypes.DATE:
            const description = item.bits[0].props?.description || '';
            item.bits[0].props = { description };
            break;
          case ChecklistBitTypes.DRIBBLE:
            const blueprint = item.bits[0].props?.blueprint;
            item.bits[0].props = { blueprint };
            break;
          case ChecklistBitTypes.NESTED_CHECKLIST:
            item.bits[0].props = {
              checklistId: bitProps.checklistId,
              title: bitProps.title,
              content: {
                checklist: bitProps?.content?.checklist,
                checklistTitle: bitProps?.content?.checklistTitle,
                checklistVersionId: bitProps?.content?.checklistVersionId,
                checklistVersionNumber: bitProps?.content?.checklistVersionNumber,
              },
            };
            break;
          case ChecklistBitTypes.DROPDOWN:
            item.bits[0].props = {
              options: bitProps.options,
              title: bitProps.title,
            };
            break;
          case ChecklistBitTypes.VERTICAL_MULTIPLE_OPTION:
          case ChecklistBitTypes.VERTICAL_SINGLE_OPTION:
          case ChecklistBitTypes.HORIZONTAL_SINGLE_OPTION:
            item.bits[0].props = {
              options: bitProps.options,
              showOthersOption: bitProps.showOthersOption,
            };
            break;
          case ChecklistBitTypes.SIGNATURE:
            delete item.showIf;
            const updatedItem: ChecklistItem = cloneDeep(item);

            updatedItem.bits[0].props = {
              showInputForSigneeName: bitProps.showInputForSigneeName,
              autoRemoveWhenSectionTampered: bitProps.autoRemoveWhenSectionTampered,
              hideQuestionUntilSectionAnswered: bitProps.hideQuestionUntilSectionAnswered,
            };

            if (bitProps.hideQuestionUntilSectionAnswered) {
              const values: showIf['values'] = [];
              section.items.forEach((question, questionIndex) => {
                if (questionIndex !== itemIndex
                  && question.bits[0].type !== ChecklistBitTypes.SIGNATURE
                  && question.required
                ) {
                  values.push({
                    operator: showIfOperators.notEmpty,
                    value: '',
                    key: `$checklist[${sectionIndex}].items[${questionIndex}].bits[0]`,
                  });
                }
              });
              delete updatedItem.showIf?.values;
              updatedItem.showIf = {
                values,
                value: Actions.SHOW_ANSWER,
                operator: showIfOperators.and,
                key: `$checklist[${sectionIndex}].items[${itemIndex}].bits[0]`,
              };
            }

            section.items[itemIndex] = updatedItem;
            break;
          case ChecklistBitTypes.IMAGE_PICKER:
            item.bits[0].props = {
              enableCameraCapture: bitProps.enableCameraCapture,
            };
            break;
          case ChecklistBitTypes.INPUT:
            item.bits[0].props = {
              placeholder: bitProps.placeholder,
              numericType: bitProps.numericType,
              keyboardType: bitProps.keyboardType,
              boundaryValues: bitProps?.boundaryValues,
            };
            break;
          case ChecklistBitTypes.TEXT:
            item.bits[0].props = {
              placeholder: bitProps.placeholder,
              keyboardType: bitProps.keyboardType,
              enableParagraphInput: bitProps.enableParagraphInput,
            };
            break;
          case ChecklistBitTypes.LIST_ITEM:
            item.bits[0].props = {
              selectedAssetType: bitProps.selectedAssetType,
            };
            break;
          default: break;
        }
      });
    });

    return checklistSections;
  };

  const [callGetRoutineAvailableQuery, {
    loading: isGetRoutineAvailableQueryLoading,
  }] = useGetRoutineAvailableLazyQuery({
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      const routineInfo = data.getRoutineAvailable;
      setState({
        title: routineInfo?.name,
        frequency: routineInfo?.frequency as Frequency,
        description: routineInfo?.description as string,
        shareableToken: String(routineInfo?.shareableToken),
        versionNumber: routineInfo?.routineVersions?.versionNumber!,
      });
      const checklist: ChecklistSection[] = routineInfo?.content?.checklist;
      checklist.forEach((s, sIndex) => {
        s.isExpanded = false;
        s.key = `key__${sIndex + 1}`;
        s.items.forEach((item, iIndex) => {
          item.key = `item_key__${iIndex + 1}`;
        });
      });
      setSections(checklist);

      const {
        localLogics: fetchedLocalLogics,
        localLogicTriggers: fetchedLocalLogicTriggers,
        globalEmailRecipients: fetchedGlobalEmailRecipients,
        multiTriggerOptions: fetchedMultiTriggerOptions,
        captureSignatureBeforeSubmit: fetchedCaptureSignatureBeforeSubmit,
      } = getLocalLogicFromSections(checklist);

      const ll = cloneDeep(fetchedLocalLogics);
      let indexCount = 0;
      const newObj: { [key: number]: LocalLogic[] } = {};
      Object.keys(ll).forEach(l => {
        if (fetchedLocalLogics[Number(l)].length > 0) {
          newObj[indexCount] = fetchedLocalLogics[Number(l)];
          indexCount += 1;
        }
      });

      setLocalLogics({
        payload: newObj,
        type: LocalLogicActionType.INITIALIZE_WITH_PAYLOAD,
      });

      setLocalLogicTriggers({
        payload: fetchedLocalLogicTriggers,
        type: LocalLogicTriggerActionType.INITIALIZE_WITH_PAYLOAD,
      });

      setMultiTriggerOptions(fetchedMultiTriggerOptions);

      setGlobalEmailRecipients(fetchedGlobalEmailRecipients || []);
      setCaptureSignatureBeforeSubmit(fetchedCaptureSignatureBeforeSubmit);
      setIsLoading(false);
    },
  });

  const [
    saveRoutinesAvailableMutation, { loading },
  ] = useSaveRoutinesAvailableMutation({
    onCompleted(data) {
      if (data.saveRoutinesAvailable.isSuccess) {
        toast.success(t(TRANSLATIONS.ROUTINE_SAVED), toastConfig);
        setState({ title: '',
          frequency: Frequency.DAILY });
        setSections([]);
        navigate('/espectro/checklist-builder');
      }
    },
    onError(e) {
      toast.error(e.message || 'Something went wrong!', toastConfig);
    },
  });

  useEffect(() => {
    if (passedRoutineAvailableId) {
      callGetRoutineAvailableQuery({
        variables: {
          routineAvailableId: passedRoutineAvailableId,
        },
      });
    }
  }, [callGetRoutineAvailableQuery, passedRoutineAvailableId]);

  const onChangeTitle = (e: any) => {
    setState({ ...state, title: e.target.value });
  };

  const onToggleEditTitle = (height: number) => {
    setHeaderHeight(height);
  };

  const onChangeDescription = (e:any) => {
    setState({ ...state, description: e.target.value });
  };

  const checkListOptionsLength = (sectionIndex: number, itemIndex: number, iBitsIndex: number) => {
    let saveFlag = true;
    const optionsLength = sections[sectionIndex].items[itemIndex]
      .bits[iBitsIndex].props.options?.length!;

    if (!optionsLength || optionsLength < 2) {
      saveFlag = false;
      return saveFlag;
    }
    return saveFlag;
  };

  const goBack = () => navigate(-1);

  const handleSaveRoutine = () => {
    let resultFlag = true;
    let isNestedChecklistAnswered = true;

    const purgedSections = purgeUnwantedPropsFromChecklistBeforeSaving();

    purgedSections.forEach((section, sectionIndex) => {
      section.items.forEach((item, itemIndex) => {
        item.bits.forEach((iBits, iBitsIndex) => {
          if (iBits.type === ChecklistBitTypes.HORIZONTAL_SINGLE_OPTION
             || iBits.type === ChecklistBitTypes.VERTICAL_SINGLE_OPTION
              || iBits.type === ChecklistBitTypes.VERTICAL_MULTIPLE_OPTION) {
            if (resultFlag) {
              resultFlag = checkListOptionsLength(sectionIndex, itemIndex, iBitsIndex);
            }
          }
          if (
            iBits.type === ChecklistBitTypes.NESTED_CHECKLIST
             && iBits.props.checklistId === undefined) {
            isNestedChecklistAnswered = false;
          }
        });
      });
    });

    const checklist = mergeLocalLogicAndSections(
      localLogics,
      localLogicTriggers,
      purgedSections,
      globalEmailRecipients,
      multiTriggerOptions,
      captureSignatureBeforeSubmit,
    );

    if (resultFlag && isNestedChecklistAnswered) {
      setSelectedTab(TABS[0]);
      saveRoutinesAvailableMutation({
        variables: {
          args: {
            ...(passedRoutineAvailableId && { routineAvailableId: passedRoutineAvailableId }),
            name: state.title as string,
            frequency: state.frequency,
            description: state.description,
            content: { checklist },
            isActive: 1,
          },
        },
      });
    } else if (!isNestedChecklistAnswered) {
      toast.error(t(TRANSLATIONS.NESTED_CHECKLIST_ERROR));
    } else {
      toast.error('Add atleast 2 options!');
    }
  };

  if (isLoading || loading) {
    return (
      <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
        <LoadingSpinner />;
      </div>
    );
  }

  return (
    <div
      className="checklist_builder_new_checklist"
      style={{ backgroundColor: '#FFF' }}
    >
      <ShareableChecklistModal
        isOpen={isModalOpen}
        onConfirm={onHandleConfirm}
        onClose={() => setIsModalOpen(false)}
        link={shareableToken}
        routineName={state.title || ''}
        isLoading={isGenerateShareableLinkMutationLoading}
      />
      <ChecklistHeader
        handleSaveRoutine={handleSaveRoutine}
        sectionsLength={sections.length}
        loading={loading}
        goBack={goBack}
        checklistVersion={state.versionNumber}
        checklistTile={isGetRoutineAvailableQueryLoading ? '' : state.title}
        onChangeTitle={onChangeTitle}
        onToggleEditTitle={onToggleEditTitle}
        showShareButton={!!passedRoutineAvailableId}
        generateShareableToken={generateShareableToken}
        tabs={TABS}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
      />
      {selectedTab?.value === '0'
        ? (
          <AddAnswers
            state={state}
            setState={setState}
            sections={sections}
            setSections={setSections}
            localLogics={localLogics}
            headerHeight={headerHeight}
            localLogicTriggers={localLogicTriggers}
            onChangeDescription={onChangeDescription}
            passedRoutineAvailableId={passedRoutineAvailableId}
            captureSignatureBeforeSubmit={captureSignatureBeforeSubmit}
            setCaptureSignatureBeforeSubmit={setCaptureSignatureBeforeSubmit}
          />
        ) : (
          <AddLogic
            sections={sections}
            localLogics={localLogics}
            setLocalLogics={setLocalLogics}
            localLogicTriggers={localLogicTriggers}
            globalEmailRecipients={globalEmailRecipients}
            setLocalLogicTriggers={setLocalLogicTriggers}
            setMultiTriggerOptions={setMultiTriggerOptions}
            multiTriggerOptions={multiTriggerOptions}
            setGlobalEmailRecipients={setGlobalEmailRecipients}
          />
        )}
    </div>
  );
};

export default NewChecklist;
