import React, { useState, useMemo } from 'react';
// @ts-ignore
import ReactTable from 'react-table-legacy';
import LoadingSpinner from 'components/LoadingSpinner';
import { useTranslation } from 'react-i18next';

import { Button } from '@mui/material';
import EmptyState from 'components/EmptyState/EmptyState';
import ImageLinks from 'utils/ImageLinks';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { AnsweredRoutinesSchema, useGetAnsweredRoutinesQuery } from 'generated/graphql';
import './ChecklistReport.scss';
import moment from 'moment';
import { addMinutes } from 'date-fns';
import { TRANSLATIONS } from 'types/enums';

// interface StateType { id: string }

export const getQueryParamString = (params: any) => {
  const queryKeys = Object.keys(params);
  const string = queryKeys
    .filter(key => params[key])
    .map(key => `${key}=${params[key]}`)
    .join('&');

  return string ? `?${string}` : '';
};

// type UserInfoType = {
//   position: string;
//   location: string;
//   name: string;
//   pin: string;
//   shortSlug: string;
// };

const ChecklistReport = () => {
  const { state: routerState } = useLocation();
  const passedRoutineAvailableId = routerState?.id;
  const [checklistReportData, setChecklistReportData] = useState<AnsweredRoutinesSchema[]>([]);
  const [isLoading, setLoading] = useState<boolean>(true);

  const {
    fetchMore: fetchMoreData,
    data: getAnsweredRoutineData,
  } = useGetAnsweredRoutinesQuery({
    variables: {
      routinesAvailableId: passedRoutineAvailableId,
      pageNumber: 1,
    },
    onCompleted: d => {
      setChecklistReportData(d?.getAnsweredRoutines.routines as any || []);
      setLoading(false);
    },
    onError: (error:any) => {
      console.log(error);
    },
  });

  const pageInfo = getAnsweredRoutineData?.getAnsweredRoutines?.pageInfo;

  let currentPage = Number(pageInfo?.currentPage);

  const totalPages = Number(pageInfo?.totalPages);

  const { t } = useTranslation();

  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  const utc = new Date();
  const offset = utc.getTimezoneOffset();

  const onClickDownload = async (v: any) => {
    const newTab = window.open(`/api/v1/download-routine-pdf/${encodeURIComponent(v.value)}`);
    const response = await axios.request({
      method: 'get',
      url: `/api/v1/download-routine-pdf/${encodeURIComponent(v.value)}`,
      headers: {
        'Access-Control-Allow-Origin': '*',
      },
      responseType: 'arraybuffer',
    });
    const downloadurl = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = downloadurl;

    const completedAt = v.original?.completedAt;
    link.setAttribute('download', `${v.original?.routineAvailable?.company?.name}-${v.original
      ?.routineAvailable?.name}-${moment(new Date(completedAt)).format('yy-MM-DD')} ${moment(new Date(completedAt))
      .format('HH:mm:ss')}.pdf`);
    document.body.appendChild(link);
    link.click();
    newTab?.close();
  };

  const columns = useMemo(() => {
    return [
      {
        Header: 'Routine Name',
        id: 'Routine Name',
        accessor: (row:any) => `${row.routineAvailable.name} ${row.routineVersion
          ? `v${Number(row.routineVersion.versionNumber || 1).toFixed(2)}`
          : ''}`,
      },
      {
        Header: 'Submitted By',
        id: 'Submitted By',
        accessor: (row:any) => (String(row.user.name).includes('Anonymous')
          ? t(TRANSLATIONS.ANONYMOUS) : row.user.name),
      },
      {
        Header: 'Completed At',
        id: 'Completed At',
        accessor: (row:any) => moment(addMinutes(new Date(row.completedAt), offset)).format('YYYY-MM-DD HH:mm:ss'),
      },
      {
        id: 'Download',
        Header: 'Download',
        accessor: (row:any) => row.id,
        width: 180,
        Cell: (v: any) => {
          return (
            <div className="download-checklist-container" role="button" onClick={() => onClickDownload(v)}>
              <img alt="checklistDownload" src={ImageLinks.espectro.checklistDownload} />
              <p
                className="download-checklist"
              >{t(TRANSLATIONS.DOWNLOAD_REPORT)}
              </p>
            </div>
          );
        },
      },
    ] as any;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t]);

  return (
    <>
      <div role="button" className="user-checklistHeader__back" onClick={goBack}>
        <img alt="backArrow" src={ImageLinks.espectro.backArrow} />
        <p>Regresar</p>

      </div>

      <div className=" user-list-container">
        <>
          <div
            style={{
              padding: '0px 0',
              margin: '15px 0',
              position: 'relative',
              display: isLoading || checklistReportData?.length === 0 ? 'flex' : '',
            }}
            className="user-table"
          >
            {isLoading ? (
              <LoadingSpinner />
            ) : checklistReportData?.length > 0 ? (
              <>
                <ReactTable
                  minRows={0}
                  data={checklistReportData}
                  columns={columns}
                  showPagination={false}
                  className="-striped max-height"
                  NoDataComponent={EmptyState}
                  sortable={false}
                />
                <div className="table-footer">
                  <Button
                    variant="contained"
                    onClick={() => {
                      setLoading(true);
                      currentPage -= 1;
                      fetchMoreData({
                        variables: {
                          routinesAvailableId: passedRoutineAvailableId,
                          pageNumber: currentPage,
                        },
                        updateQuery: (previousResult, { fetchMoreResult }:any) => {
                          setChecklistReportData(fetchMoreResult?.getAnsweredRoutines?.routines);
                          setLoading(false);
                          return fetchMoreResult;
                        },
                      });
                    }}
                    disabled={currentPage < 2}
                  >
                    {t(TRANSLATIONS.PREVIOUS)}
                  </Button>
                  <div className="page">
                    <input
                      type="number"
                      value={currentPage}
                      disabled
                      defaultValue={currentPage}
                    />
                    <div>{t(TRANSLATIONS.TOTAL_PAGES)}: <span>{totalPages}</span></div>
                  </div>
                  <Button
                    variant="contained"
                    onClick={() => {
                      setLoading(true);
                      currentPage += 1;
                      fetchMoreData({
                        variables: {
                          routinesAvailableId: passedRoutineAvailableId,
                          pageNumber: currentPage,
                        },
                        updateQuery: (previousResult, { fetchMoreResult }:any) => {
                          setChecklistReportData(fetchMoreResult?.getAnsweredRoutines?.routines);
                          setLoading(false);
                          return fetchMoreResult;
                        },
                      });
                    }}
                    disabled={currentPage === totalPages}
                  >
                    {t(TRANSLATIONS.NEXT)}
                  </Button>
                </div>
              </>
            ) : (
              <EmptyState />
            )}
          </div>
        </>
      </div>
    </>
  );
};

export default ChecklistReport;
