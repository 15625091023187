import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TRANSLATIONS } from 'types/enums';
import LinkDropdown from 'components/Dropdown/LinkDropdown';
import { ReactComponent as LogoutIcon } from 'assets/icons/log-out.svg';
import './Settings.scss';
import { useSelector } from 'react-redux';
import ImageLinks from 'utils/ImageLinks';

const Settings = () => {
  const [isClicked, setClicked] = useState(false);
  const { t } = useTranslation();
  const user = useSelector((state: any) => state.get('user'))?.toJS();
  function onVisibleChange(isVisible: boolean) {
    setClicked(isVisible);
  }

  const settingsItems = [];

  if (user) {
    const uiAvatarImage = user?.picture?.length
      ? user?.picture
      : encodeURI(
        `https://ui-avatars.com/api/?name=${user?.name}&color=000000&background=f2f2f2`,
      );
    settingsItems.push({
      url: '#',
      target: '',
      style: {
        backgroundColor: 'unset',
        cursor: 'default',
      },
      label: (
        <div>
          <div className="userHeader">
            <div className="userEmailHeader">
              <div>
                <img
                  src={uiAvatarImage}
                  alt="uiAvatarImage"
                  className="userImage"
                />
              </div>
              <div
                // style={{
                //   display: 'flex',
                //   flexDirection: 'column',
                //   fontSize: 12,
                //   marginLeft: 16,
                //   overflowWrap: 'anywhere',
                // }}
                className="userInfo"
              >
                <p className="userName">{user?.name}</p>
                <p className="userEmail">{user?.email}
                </p>
              </div>
            </div>
            {user?.company?.logo ? (
              <div className="userCompanyLogoWrap">
                <img
                  src={user?.company?.logo}
                  className="userCompanyLogo"
                  alt="companyIcon"
                />
              </div>
            ) : null }
          </div>
          <div>
            <div
              style={{
                paddingTop: user?.company?.logo ? 20 : 28,
              }}
              className="userCompanyDetailsWrap"
            >
              <img
                src={ImageLinks.espectro.home}
                className="userCompanyNameLogo"
                alt="companyName"
              />
              <p className="userCompanyName">{user?.company?.name || ''}
              </p>
            </div>
            <div className="userPositionWrap">
              <img
                src={ImageLinks.espectro.account}
                className="userCompanyNameLogo"
                alt="companyName"
              />
              <p className="userCompanyName">{user?.position || ''}
              </p>
            </div>
          </div>
        </div>
      ),
      insertSectionDivider: true,
    });
  }

  settingsItems.push({
    url: '/login',
    label: (
      <span className="logout">
        {t(TRANSLATIONS.SIGN_OFF)}
        <LogoutIcon />
      </span>
    ),
  });

  return (
    <div className="settings">
      <LinkDropdown
        dropdownContent={settingsItems}
        className={`link-dropdown ${isClicked ? 'clicked' : ''}`}
        dropdownContentPositioning={{ right: -10 }}
        onVisibleChange={onVisibleChange}
      >
        <img
          src={user?.picture?.length ? user?.picture : encodeURI(`https://ui-avatars.com/api/?name=${user?.name}&color=000000&background=f2f2f2`)}
          className="userImageMain"
          alt="uiAvatarImage"
        />
      </LinkDropdown>
    </div>
  );
};

export default Settings;
