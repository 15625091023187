import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Header from 'components/Header';
import NavigationDrawerWithSideMenu
  from 'components/NavigationDrawer/NavigationDrawerWithVerticalMenu';
import defaultRoutes from 'utils/defaultRoutes';
import { selectUser, selectUserLocation, selectUserType } from 'store/selectors/user.selectors';
import './WorkOrders.scss';
import { TRANSLATIONS, UserTypes, VIEW_MODE } from 'types/enums';
import BasicButton from 'components/Button/BasicButton';
import { useNavigate } from 'react-router-dom';
import {
  useDeleteWorkOrderMutation,
  useCancelWorkOrderMutation,
  WorkOrder,
  useQueueEmailPdfToRecipientsLazyQuery,
  WorkOrderStatus,
  useGetWorkOrdersLazyQuery,
  GetWorkOrdersQuery,
  Maybe,

} from 'generated/graphql';
import InputWithLabel from 'components/Input/InputWithLabel/InputWithLabel';
import { ToastOptions, toast } from 'react-toastify';
import moment from 'moment';
import 'moment/locale/es';
import { Tooltip } from '@mui/material';
import ImageLinks from 'utils/ImageLinks';
import MultiValueInput from 'components/MultiValueInput';
import Modal from 'components/Modal';
import WorkOrdersTable from 'components/Espectro/WorkOrdersTable';
import LoadingSpinner from 'components/LoadingSpinner';
import { capitalizeFirst } from 'utils/stringHelpers';
import { SlotInfo } from 'react-big-calendar';
import Tabs from 'components/Espectro/Tabs';
import { getMonth } from 'date-fns';
// import useDebounce from 'utils/hooks/useDebounce';
import { TabItem } from 'components/Espectro/Tabs/Tabs';
import checklistSearch from '../../../assets/icons/input-search.svg';
import noChecklists from '../../../assets/icons/Docs.svg';
import deleteIcon from '../../../assets/icons/delete-empty.svg';
import { EMAIL_REGEX } from '../../../constants';
import WorkOrdersCalendarView from '../components/WorkOrdersCalendarView/WorkOrdersCalendarView';
import { DEFAULT_WORK_ORDERS_RECORDS_LIMIT_PER_PAGE, DEFAULT_WORK_ORDERS_TAB_DATA } from '../constants';
import { WorkOrdersTabDataType } from '../types';
import OverlayLoader from '../components/OverlayLoader/OverlayLoader';

const useDebounce = (value: any, timeout: any) => {
  const [state, setState] = useState(value);

  useEffect(() => {
    const collection = document.getElementsByClassName('rt-tr-group');
    for (let i = 0; i < collection.length; i++) {
      collection[i].setAttribute('tabIndex', '1');
    }
  });

  useEffect(() => {
    const handler = setTimeout(() => setState(value), timeout);

    return () => clearTimeout(handler);
  }, [value, timeout]);

  return state;
};

const WorkOrders = () => {
  const userType = useSelector(selectUserType);
  const location = useSelector(selectUserLocation);
  const { t } = useTranslation();
  const TABS: TabItem[] = [
    { title: t(TRANSLATIONS.ACTIVE), value: '0' },
    { title: t(TRANSLATIONS.FINALIZED), value: '1' },
    { title: t(TRANSLATIONS.CANCELLED), value: '2' },
  ];
  const navigate = useNavigate();
  moment.locale('es');
  const inputRef = useRef<HTMLInputElement>(null);
  const [search, setSearch] = useState('');
  const [selectedWoId, setSelectedWoId] = useState<number>(-1);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
  const [showSendEmailModal, setShowSendEmailModal] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState<TabItem>(TABS[0]);
  const [selectetWo, setSelectetWo] = useState<any>(null);
  const [cancellationReason, setCancellationReason] = useState<string>('');
  const [recipients, setRecipients] = useState<string[]>([]);
  const [selectedViewMode, setSelectedViewMode] = useState<VIEW_MODE>(VIEW_MODE.LIST);
  const [onFocusLoader, setOnFocusLoaderVisibility] = useState<boolean>(false);

  const [
    activeWorkOrders,
    setActiveWorkOrders,
  ] = useState<WorkOrdersTabDataType>(DEFAULT_WORK_ORDERS_TAB_DATA);

  const [
    finalizedWorkOrders,
    setFinalizedWorkOrders,
  ] = useState<WorkOrdersTabDataType>(DEFAULT_WORK_ORDERS_TAB_DATA);

  const [
    cancelledWorkOrders,
    setCancelledWorkOrders,
  ] = useState<WorkOrdersTabDataType>(DEFAULT_WORK_ORDERS_TAB_DATA);

  const [
    recordsRequestedForStatus,
    setRecordsRequestedForStatus,
  ] = useState<Maybe<WorkOrderStatus | undefined>>(WorkOrderStatus.Active);

  const onClickNewWorkOrder = () => {
    if (userType !== UserTypes.EspectroViewOnly) {
      navigate('/espectro/new-work-order', {
        state: {
          isDuplicateAction: false,
        },
      });
    }
  };

  const [
    getWorkOrdersQuery,
    {
      refetch,
    },
  ] = useGetWorkOrdersLazyQuery({
    onCompleted: (d: GetWorkOrdersQuery) => {
      const workOrderRecords = d?.getWorkOrders || [];
      const currentPageNumber = workOrderRecords?.[0]?.page;
      const noRecordsFetched = workOrderRecords?.length === 0;
      const message = noRecordsFetched ? 'No more records!' : '';
      const recordsFetchedForStatus = workOrderRecords?.[0]?.status;
      // const areRecordsSearched = workOrderRecords?.[0]?.areRecordsSearched;

      if (!noRecordsFetched) {
        setRecordsRequestedForStatus(recordsFetchedForStatus);
      }
      const statusToCompareRecords = !noRecordsFetched
        ? recordsFetchedForStatus : recordsRequestedForStatus;

      if (statusToCompareRecords === WorkOrderStatus.Active) {
        setActiveWorkOrders({
          message,
          loading: false,
          firstLoadingEvent: false,
          data: search && currentPageNumber! > 1
            ? [...activeWorkOrders.data, ...workOrderRecords as any]
            : search || currentPageNumber === 1
              ? [...workOrderRecords] : [...activeWorkOrders.data, ...workOrderRecords as any],
          page: currentPageNumber!,
        });
      }
      if (statusToCompareRecords === WorkOrderStatus.Finalized) {
        setFinalizedWorkOrders({
          message,
          loading: false,
          firstLoadingEvent: false,
          data: search && currentPageNumber! > 1
            ? [...finalizedWorkOrders.data, ...workOrderRecords as any]
            : search || currentPageNumber === 1
              ? [...workOrderRecords] : [...finalizedWorkOrders.data, ...workOrderRecords as any],
          page: currentPageNumber!,
        });
      }
      if (statusToCompareRecords === WorkOrderStatus.Cancelled) {
        setCancelledWorkOrders({
          message,
          loading: false,
          firstLoadingEvent: false,
          data: search && currentPageNumber! > 1
            ? [...cancelledWorkOrders.data, ...workOrderRecords as any]
            : search || currentPageNumber === 1
              ? [...workOrderRecords] : [...cancelledWorkOrders.data, ...workOrderRecords as any],
          page: currentPageNumber!,
        });
      }
      setRecordsRequestedForStatus(undefined);
      setOnFocusLoaderVisibility(false);
    },
    fetchPolicy: 'network-only',
  });

  const targetList: WorkOrdersTabDataType = (selectedTab.value === '0')
    ? activeWorkOrders : (selectedTab.value === '1') ? finalizedWorkOrders
      : cancelledWorkOrders;

  const toastConfig = {
    position: 'top-right',
    autoClose: 4000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: false,
    progress: undefined,
  } as ToastOptions;

  const [
    deleteWorkOrderMutation, { loading: deleteWorkOrderLoading },
  ] = useDeleteWorkOrderMutation({
    onCompleted() {
      if (refetch) {
        refetch?.();
      }
      setShowDeleteModal(false);
    },
    onError(e) {
      console.log('error', e.message);
      toast.error(e?.message, toastConfig);
    },
  });

  const [
    queueEmailPdfToRecipientsQuery, {
      loading: isQueueEmailPdfQueryLoading,
    },
  ] = useQueueEmailPdfToRecipientsLazyQuery({
    onCompleted() {
      toast.success('Email dispatched!', toastConfig);
      setShowSendEmailModal(false);
      setRecipients([]);
    },
    onError(e) {
      console.log('error', e.message);
      toast.error(e?.message, toastConfig);
    },
  });

  const status = selectedTab.value === '0' ? WorkOrderStatus.Active
    : selectedTab.value === '1' ? WorkOrderStatus.Finalized
      : WorkOrderStatus.Cancelled;

  const fetchWorkOrderRecords = useCallback((monthFilterEnabled = false) => {
    console.log('Executing fetchWorkOrderRecords');
    setOnFocusLoaderVisibility(true);
    getWorkOrdersQuery({
      variables: {
        status,
        activeMonth: monthFilterEnabled ? String(getMonth(new Date()) + 1) : undefined,
        limit: DEFAULT_WORK_ORDERS_RECORDS_LIMIT_PER_PAGE,
      },
    });
  }, [status, getWorkOrdersQuery]);

  const activeWorkOrdersListIsEmpty = !activeWorkOrders.data.length;
  const finalizedWorkOrdersListIsEmpty = !finalizedWorkOrders.data.length;
  const cancelledWorkOrdersListIsEmpty = !cancelledWorkOrders.data.length;

  useEffect(() => {
    if (status === WorkOrderStatus.Active && !activeWorkOrdersListIsEmpty) return;
    if (status === WorkOrderStatus.Finalized && !finalizedWorkOrdersListIsEmpty) return;
    if (status === WorkOrderStatus.Cancelled && !cancelledWorkOrdersListIsEmpty) return;
    fetchWorkOrderRecords();
  }, [
    status,
    fetchWorkOrderRecords,
    activeWorkOrdersListIsEmpty,
    finalizedWorkOrdersListIsEmpty,
    cancelledWorkOrdersListIsEmpty,
  ]);

  const formatDateToString = (dateString: string) => {
    const date = moment(new Date(dateString)).format('DD.MM.YY hh:mm A');
    return date;
  };

  const [
    cancelWorkOrderMutation, {
      loading: isCancelWorkOrderMutationLoading,
    },
  ] = useCancelWorkOrderMutation({
    onError: e => {
      toast.error(e.message);
      setShowCancelModal(false);
    },
    onCompleted: () => {
      refetch?.();
      setShowCancelModal(false);
    },
  });

  const debouncedUsersReq = useDebounce(search, 1500);

  const searchWorkOrders = (searchText: string) => {
    setOnFocusLoaderVisibility(true);
    getWorkOrdersQuery({
      variables: {
        status,
        page: 1,
        limit: DEFAULT_WORK_ORDERS_RECORDS_LIMIT_PER_PAGE,
        searchText,
      },
    });
  };

  useEffect(() => {
    console.log('Triggered');
    setOnFocusLoaderVisibility(true);
    if (search.length > 2) searchWorkOrders(search);
    if (!search.length) fetchWorkOrderRecords();
    // eslint-disable-next-line
  }, [debouncedUsersReq]);

  useEffect(() => {
    if (selectedWoId !== -1) {
      const asset = targetList?.data?.find(a => Number(a?.id) === selectedWoId);
      setSelectetWo(asset);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedWoId]);

  const handleSearch = async (event: any) => {
    const searchedText: string = event.target.value;
    setSearch(searchedText);
    // debounceSearchRequest(searchedText);
  };

  const onPressEditWo = (woId: any) => {
    if (userType === UserTypes.EspectroViewOnly) return;
    const asset = targetList?.data?.find(a => Number(a?.id) === Number(woId));
    console.log({ f: asset?.isFinalized, c: asset?.isCancelled });
    // if (asset?.isFinalized) alert('F');
    // if (asset?.isCancelled) alert('C');
    // if (!asset?.isFinalized && !asset?.isCancelled) alert('A');

    if (selectedTab.value === '1') {
      window.open(`/api/v1/work-order/download-report-by-work-order/${woId}`);
    }

    if (['0', '2'].includes(selectedTab?.value!)) {
      if (asset?.totalQuestionsAnswered! > 0 || selectedTab?.value === '2') {
        navigate('/espectro/work-order-info', {
          state: { checklistData: asset, isEdit: true },
        });
      } else {
        navigate('/espectro/new-work-order', {
          state: {
            checklistData: asset,
            isEdit: true,
            isDuplicateAction: false,
          },
        });
      }
    }
  };

  const showDeleteWorkOrder = () => {
    setShowDeleteModal(true);
  };

  const dispatchEmailToRecipients = () => {
    if (recipients.length > 0) {
      queueEmailPdfToRecipientsQuery({
        variables: {
          recipients,
          workOrderId: String(selectedWoId),
        },
      });
    } else {
      toast.error('Please add at least 1 email recipient');
    }
  };

  const duplicateSelectedWorkOrder = (workOrderId: string) => {
    const workOrder = targetList?.data?.find(a => Number(a?.id) === Number(workOrderId));
    navigate('/espectro/new-work-order', {
      state: {
        isEdit: true,
        checklistData: workOrder,
        isDuplicateAction: true,
      },
    });
  };

  const threeDot = (woId: number) => {
    return (
      <div className="wo__menuContainer">
        {selectedTab?.value === '0' ? (
          <div
            role="button"
            className={`wo__editIcon ${userType === UserTypes.EspectroViewOnly ? 'opacity' : ''}`}
            onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
              e.stopPropagation();
              if (userType === UserTypes.EspectroViewOnly) return;
              setSelectedWoId(woId);
              setShowCancelModal(true);
            }}
          >
            <img src={ImageLinks.espectro.file} alt="edit" className="wo_action_icon" />
          </div>
        ) : null}
        {
          selectedTab?.value !== '0' && (
            <div
              role="button"
              className={`wo__mailIcon ${userType === UserTypes.EspectroViewOnly ? 'opacity' : ''}`}
              onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                e.stopPropagation();
                if (userType === UserTypes.EspectroViewOnly) return;
                if (selectedTab?.value === '1') {
                  setSelectedWoId(woId);
                  setShowSendEmailModal(true);
                }
              }}
            >
              <img src={ImageLinks.espectro.mail} alt="mail" className="wo_action_icon" />
            </div>
          )
        }
        <div
          role="button"
          className={`wo__deleteIcon ${userType === UserTypes.EspectroViewOnly ? 'opacity' : ''}`}
          onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            e.stopPropagation();
            if (userType === UserTypes.EspectroViewOnly) return;
            setSelectedWoId(woId);
            showDeleteWorkOrder();
          }}
        >
          <img src={deleteIcon} alt="delete" className="wo_action_icon" />
        </div>

        <div
          role="button"
          className={`wo__deleteIcon ${userType === UserTypes.EspectroViewOnly ? 'opacity' : ''}`}
          onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            e.stopPropagation();
            if (userType === UserTypes.EspectroViewOnly) return;
            setOnFocusLoaderVisibility(true);
            setSelectedWoId(woId);
            duplicateSelectedWorkOrder(String(woId));
          }}
        >
          <img src={ImageLinks.espectro.duplicate} alt="duplicate" className="wo_action_icon" />
        </div>

      </div>
    );
  };

  const woTitle = (title: string) => (
    <p
      title={title}
      className="wo__title"
    >
      {capitalizeFirst(title)}
    </p>
  );

  const woChecklistTitle = (title: string) => (
    <p className="wo__checklist-title">{title}</p>
  );

  const woIdText = (id: string) => (
    <p className="wo__id">{id}</p>
  );

  const woChecklistQuestions = (questions: string, totalQuestions: string, percentage: any) => (
    <div className="wo__question-wrap">
      <p className="wo__answered-question-title">{questions} / {totalQuestions}&nbsp;</p>
      <p className="wo__total-question-title">({percentage ?? 0}%)</p>
    </div>
  );

  const closeModal = () => {
    setShowDeleteModal(false);
  };

  const onClickDeleteWorkOrder = () => {
    deleteWorkOrderMutation({
      variables: {
        deleteWorkOrderId: selectedWoId?.toString(),
      },
    });
  };

  const cancelWorkOrder = () => {
    cancelWorkOrderMutation({
      variables: {
        cancellationReason,
        workOrderId: String(selectedWoId),
      },
    });
  };

  const fetchWorkOrders = (newPage: number) => {
    if (status !== recordsRequestedForStatus) {
      setRecordsRequestedForStatus(status);
      console.log('Setting status', status);
    }
    switch (status) {
      case WorkOrderStatus.Active: {
        setActiveWorkOrders({
          ...activeWorkOrders,
          loading: true,
        });
        break;
      }
      case WorkOrderStatus.Finalized: {
        setFinalizedWorkOrders({
          ...finalizedWorkOrders,
          loading: true,
        });
        break;
      }
      case WorkOrderStatus.Cancelled: {
        setCancelledWorkOrders({
          ...cancelledWorkOrders,
          loading: true,
        });
        break;
      }
      default: break;
    }
    getWorkOrdersQuery({
      variables: {
        status,
        page: newPage,
        searchText: search.length > 2 ? search : undefined,
        limit: DEFAULT_WORK_ORDERS_RECORDS_LIMIT_PER_PAGE,
      },
    });
  };

  const onUpdateRecipients = useCallback((
    receivers: string[],
  ) => {
    setRecipients(receivers);
  }, []);

  const onSelectSlotForNewWorkOrder = (slotInfo: SlotInfo) => {
    if (userType === UserTypes.EspectroViewOnly) return;

    navigate('/espectro/new-work-order', {
      state: {
        selectStartDate: slotInfo.start,
        selectEndDate: slotInfo.end,
        isDuplicateAction: false,
      },
    });
  };

  const isListViewModeActive = selectedViewMode === VIEW_MODE.LIST;

  const toggleViewMode = () => {
    setSelectedViewMode(
      isListViewModeActive ? VIEW_MODE.CALENDAR : VIEW_MODE.LIST,
    );
  };

  const userDetails = useSelector(selectUser);

  return (
    <div className="wo_page-container">
      {search.length > 2 && onFocusLoader && <OverlayLoader />}
      {showDeleteModal && (
      <Modal
        modalContainerStyles={{
          width: '632px',
          height: '156px',
          display: 'flex',
          flexDirection: 'column',
          position: 'absolute',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
          overflow: 'hidden',
          padding: '24px',
        }}
      >
        {deleteWorkOrderLoading ? (
          <div className="wo__delete-loader">
            <LoadingSpinner />
          </div>
        ) : (
          <div>
            <p className="wo__deleteMoal-title">{`${t(TRANSLATIONS.DELETE_WORK)} ${selectedWoId}`}</p>
            <p className="wo__deleteMoal-description">{t(TRANSLATIONS.THIS_WORK_WILL_BE_DELETED_EVERYWHERE_THIS_ACTION_CANNOT_BE_UNDONE)}</p>
            <div className="wo__deleteMoal-button_container">
              <BasicButton text={t(TRANSLATIONS.CANCEL)} className="wo__deleteMoal-cancel_button" onClick={closeModal} />
              <BasicButton text={t(TRANSLATIONS.DELETE_PERMANENTLY)} className="wo__deleteMoal-delete_button" onClick={onClickDeleteWorkOrder} />
            </div>
          </div>
        )}
      </Modal>
      )}
      {showCancelModal && (
      <Modal
        modalContainerStyles={{
          width: '632px',
          height: 'auto',
          display: 'flex',
          flexDirection: 'column',
          position: 'absolute',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
          overflow: 'hidden',
          padding: '24px',
        }}
      >
        {isCancelWorkOrderMutationLoading ? (
          <div className="wo__delete-loader">
            <LoadingSpinner />
          </div>
        ) : (
          <div>
            <p className="wo__deleteMoal-title">{`${t(TRANSLATIONS.CANCEL_WORK_ORDER)}`}</p>
            <p className="wo__deleteMoal-description">{`${selectedWoId} - ${selectetWo?.name}`}</p>
            <textarea
              value={cancellationReason}
              inputMode="text"
              placeholder={`${t(TRANSLATIONS.REASON_FOR_CANCELLATION)}...`}
              onChange={
                (event: React.ChangeEvent<HTMLTextAreaElement>) => setCancellationReason(
                  event.target.value,
                )
              }
              className="wo__cancellation-reason-input"
              style={{
                width: '100%',
              }}
            />
            <div className="wo__deleteMoal-button_container">
              <BasicButton text={t(TRANSLATIONS.CANCEL)} className="wo__deleteMoal-cancel_button" onClick={() => setShowCancelModal(false)} />
              <BasicButton text={t(TRANSLATIONS.FINISH_AND_SEND)} className="wo__cancelModal-send_button" onClick={cancelWorkOrder} />
            </div>
          </div>
        )}
      </Modal>
      )}

      {/* Email PDF */}
      {showSendEmailModal && (
      <Modal
        modalContainerStyles={{
          width: '632px',
          height: 'auto',
          display: 'flex',
          flexDirection: 'column',
          position: 'absolute',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
          overflow: 'hidden',
          padding: '24px',
        }}
      >
        {isQueueEmailPdfQueryLoading ? (
          <div className="wo_tab-send-email-loader-div">
            <LoadingSpinner />
          </div>
        ) : (
          <div>
            <p className="wo__deleteMoal-title">{`${t(TRANSLATIONS.SEND_PDF_VIA_EMAIL)}`}</p>
            <p
              className="wo__deleteMoal-description"
            >
              {t(TRANSLATIONS.ADD_EMAILS_TO_SEND)}<i>{` ${selectetWo?.name}`}</i> PDF
            </p>
            <MultiValueInput
              onAddItem={onUpdateRecipients}
              validationRegEx={EMAIL_REGEX}
              placeholder={t(TRANSLATIONS.TYPE_EMAIL_AND_PRESS_ENTER)}
              errorMessage="Invalid email!"
              onDeleteItem={onUpdateRecipients}
              items={recipients}
            />
            <div className="wo__deleteMoal-button_container mt-4">
              <BasicButton
                text={t(TRANSLATIONS.CANCEL)}
                className="wo__deleteMoal-cancel_button"
                onClick={() => {
                  setShowSendEmailModal(false);
                  setRecipients([]);
                }}
              />
              <BasicButton
                text={t(TRANSLATIONS.FINISH_AND_SEND)}
                className="wo__cancelModal-send_button"
                onClick={dispatchEmailToRecipients}
              />
            </div>
          </div>
        )}
      </Modal>
      )}

      <Header />
      <NavigationDrawerWithSideMenu
        {...{
          routes: defaultRoutes(t, userType, location, userDetails),
          openDrawer: true,
        }}
      />
      <div className="wo__container">
        <div className="wo__flex-row">
          {
            userType === UserTypes.EspectroViewOnly ? (
              <Tooltip title="Read Only" arrow placement="right">
                <h1>{t(TRANSLATIONS.WORK_ORDERS)}</h1>
              </Tooltip>
            ) : (
              <h1>{t(TRANSLATIONS.WORK_ORDERS)}</h1>
            )
          }
          <div className="workOrder_action-buttons-container">
            <div className="workOrders-view-type-selector-container">
              <div
                role="button"
                onClick={toggleViewMode}
                className={`workOrders-view-mode-container ${isListViewModeActive && 'workOrders-active-view-mode'}`}
              >
                <img
                  src={isListViewModeActive
                    ? ImageLinks.espectro.listIconLight : ImageLinks.espectro.listIcon}
                  width={18}
                  height={18}
                  alt="list"
                />
              </div>
              <div
                role="button"
                onClick={toggleViewMode}
                className={`workOrders-view-mode-container ${!isListViewModeActive && 'workOrders-active-view-mode'}`}
              >
                <img
                  src={isListViewModeActive
                    ? ImageLinks.espectro.calendarV2 : ImageLinks.espectro.calendarIconLight}
                  width={18}
                  height={18}
                  alt="calendar"
                />
              </div>
            </div>
            <BasicButton
              onClick={onClickNewWorkOrder}
              className="wo__upload_button"
              text={`+ ${t(TRANSLATIONS.NEW_WORK_ORDER)}`}
              background="#6C63FF"
              disabled={selectedTab?.value === '1'
                || selectedTab?.value === '2'
                || userType === UserTypes.EspectroViewOnly}
            />
          </div>
        </div>
        <div className="workOrders_tabs-and-selector-container">
          <div className="wo__tabs">
            <Tabs
              selectedTab={selectedTab}
              setSelectedTab={(item: TabItem) => {
                setSearch('');
                setActiveWorkOrders({
                  ...activeWorkOrders,
                  loading: false,
                });
                setFinalizedWorkOrders({
                  ...finalizedWorkOrders,
                  loading: false,
                });
                setCancelledWorkOrders({
                  ...cancelledWorkOrders,
                  loading: false,
                });
                setSelectedTab(item);
              }}
              tabsItems={TABS}
              tabStyle=""
              tabNotSelectedStyle="wo__not-selected-tab"
            />
          </div>
        </div>

        {
          selectedViewMode === VIEW_MODE.LIST ? (
            <>
              <InputWithLabel
                inputRef={inputRef}
                disabled={onFocusLoader}
                inputImg={checklistSearch}
                onChange={handleSearch}
                placeholder={t(TRANSLATIONS.SEARCH)}
                value={search}
                labelClassName="search-header"
                type="search"
                inputClassName="search-input"
              />

              {(targetList.data) && (
                <div style={targetList?.data?.length === 0 ? { display: 'none', marginTop: 0 } : {}}>
                  <div className="wo__cards-row wo__scroll-view">
                    {/* {onFocusLoader && <OverlayLoader />} */}
                    <WorkOrdersTable
                      onClickRow={(id: any) => onPressEditWo(id)}
                      fetchQuery={fetchWorkOrders}
                      data={targetList}
                      header={
                        [
                          t(TRANSLATIONS.ID),
                          t(TRANSLATIONS.JOB_NAME),
                          t(TRANSLATIONS.INSPECTIONS),
                          t(TRANSLATIONS.STEPS),
                          t(TRANSLATIONS.START_DATE),
                          t(TRANSLATIONS.END_DATE),
                          '',
                        ]
                      }
                      rows={
                        targetList?.data?.map((row: WorkOrder) => {
                          return {
                            rowData: [
                              woIdText(row!.id),
                              woTitle(String(row!.name)),
                              woChecklistTitle(`${row?.routines?.length} checklist`),
                              woChecklistQuestions(
                                String(row?.totalQuestionsAnswered),
                                String(row?.totalQuestions),
                                // eslint-disable-next-line max-len
                                row?.totalQuestions === 0 ? 0 : Math.round((parseInt(String(row?.totalQuestionsAnswered), 10) / parseInt(String(row?.totalQuestions), 10)) * 100),
                              ),
                              formatDateToString(row.startDate),
                              formatDateToString(row.endDate),
                              (!row.isCancelled) && threeDot(Number(row!.id)),
                            ],
                            index: row!.id,
                          };
                        })
                      }
                    />
                  </div>
                </div>
              )}
              {
                onFocusLoader && (
                  <div style={{ height: 400 }}>
                    <LoadingSpinner positionTop={200} />
                  </div>
                )
              }
              { targetList?.data?.length === 0 && !onFocusLoader
                ? (
                  selectedTab?.value === '1' ? (
                    <div className="wo__no-checklist-container">
                      <img src={noChecklists} style={{ marginBottom: '24px' }} alt="noChecklists" />
                      <p className="wo__no-checklist-h">{t(TRANSLATIONS.THERE_ARE_NO_COMPLETED_JOBS_YET)}</p>
                      <p className="wo__no-checklist-p">{t(TRANSLATIONS.WAIT_FOR_THE_ASSIGNED_USERS_TO_FINISH_TO_VIEW_THEM_HERE)}</p>
                    </div>
                  ) : selectedTab?.value === '2' ? (
                    <div className="wo__no-checklist-container">
                      <img src={noChecklists} style={{ marginBottom: '24px' }} alt="noChecklists" />
                      <p className="wo__no-checklist-h">{t(TRANSLATIONS.NO_JOBS_CANCELED_YET)}</p>
                      <p className="wo__no-checklist-p">{t(TRANSLATIONS.WAIT_FOR_WORK_ORDERS_TO_BE_CANCELED_TO_VIEW_THEM_HERE)}</p>
                    </div>
                  ) : (
                    <div className="wo__no-checklist-container">
                      <img src={noChecklists} style={{ marginBottom: '24px' }} alt="noChecklists" />
                      <p className="wo__no-checklist-h">{t(TRANSLATIONS.THERE_ARE_NO_JOBS_YET)}</p>
                      <p className="wo__no-checklist-p">{t(TRANSLATIONS.PRESS_PLUS_NEW_JOB_TO_START)}</p>
                      <BasicButton
                        onClick={onClickNewWorkOrder}
                        className="wo__upload_button wo__no-checklist-margin-hor-16"
                        text={`+ ${t(TRANSLATIONS.NEW_WORK_ORDER)}`}
                        background="#6C63FF"
                      />
                    </div>
                  )
                ) : null}
            </>
          ) : (
            targetList
              ? onFocusLoader && selectedViewMode === VIEW_MODE.CALENDAR ? (
                <OverlayLoader />
              )
                : (
                  <WorkOrdersCalendarView
                    workOrderList={targetList.data}
                    fetchWorkOrderRecords={fetchWorkOrderRecords}
                    onPressWorkOrderEvent={onPressEditWo}
                    onSelectSlotForNewWorkOrder={onSelectSlotForNewWorkOrder}
                  />
                ) : (
                  <div style={{ height: 400 }}>
                    <LoadingSpinner positionTop={200} />
                  </div>
              )
          )
        }
      </div>
    </div>
  );
};

export default WorkOrders;
