/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { useCallback, useEffect, useReducer, useState, Dispatch, SetStateAction } from 'react';
import {
  Backdrop,
  Button,
  CircularProgress,
  Fade,
  FormControl,
  MenuItem,
  Modal,
  Select,
  TextareaAutosize,
} from '@mui/material';
import ConfirmationModal from 'components/Modal/ConfirmationModal';
import { AlertTypeProps } from 'components/Notification/SnackBarNotification/SnackBarNotification';
import { differenceInCalendarDays, isAfter } from 'date-fns';
import {
  Action_Log_Origin,
  Action_Log_Status, CreateEditActionLog,
  CreateEditActionLogPayload,
  ImageInputItem,
  useActionLogStatusMutation,
  useAddOrEditActionLogCommentMutation,
  useCreateEditActionLogMutation,
  useDeleteActionLogCommentMutation,
  useDetailedCommentsForActionLogQuery,
  useSummaryForActionLogLazyQuery,
  useDeleteActionLogMutation,
} from 'generated/graphql';
import { isEqual } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectUser } from 'store/selectors/user.selectors';
import { ACTION_LOG_SUMMARY_MODAL_STATUS, TRANSLATIONS, COMMENT_CREATE_TYPE } from 'types/enums';
import { getTranslatedDateTimeValue } from 'utils/dateHelpers';
import { requestImageUploadPermission, uploadObjectImage } from 'utils/ImageHelper';
import ImageLinks from 'utils/ImageLinks';
import { toast, ToastOptions } from 'react-toastify';
import { actionLogOriginText, trelloBoardColumns, actionLogCommentRefetchQueries } from '../actionLogshelper';
import {
  cleanActionLogDBPayload,
  CreateEditActionLogState,
  formatPreFilledState,
  initialCreateEditActionLogState,
  isActionLogFilledPartially,
  isStateFilledCompletely,
  KPIState,
  LocalImage,
  ManagementTool,
  reducerCreateEditActionLog,
  Responsible,
} from '../createEditActionLog/createEditActionLogModal.state';
import { ACTION_LOG_PILLAR_IMAGE } from '../createEditActionLog/pillarImagesHelper';
import CommentSection from '../../../components/CommentSection';
import { ActionLogProvider, useActionLogContext } from './context';
import './SummaryModal.scss';
import ButtonsForSummaryModal from './SummaryModalComponent/ButtonsForSummaryModal';
import ImageSectionForSummaryModal from './SummaryModalComponent/ImageSectionForSummaryModal/ImageSectionForSummaryModal';
import KPIForSummaryModal from './SummaryModalComponent/KPIForSummaryModal/KPIForSummaryModal';
import MeetingForSummaryModal from './SummaryModalComponent/MeetingForSummaryModal/MeetingForSummaryModal';
import ManagementToolForSummaryModal from './SummaryModalComponent/ManagementToolForSummaryModal/ManagementToolForSummaryModal';
import PillarForSummaryModal from './SummaryModalComponent/PillarForSummaryModal/PillarForSummaryModal';
import ResponsiblesAvatarWithTooltip from './SummaryModalComponent/ResponsiblesAvatarWithTooltip';
import SummaryModalDatePicker from './SummaryModalComponent/SummaryModalDatePicker/SummaryModalDatePicker';
import { Routine } from '../RoutinesListing/RoutinesListing';
import { isEspectro } from '../../../constants';
import { SelectInputProps } from '@mui/material/Select/SelectInput';

type Props = {
  locationSlug: string;
  locationName: string;
  actionLogId: string | undefined;
  summaryModalStatus?: ACTION_LOG_SUMMARY_MODAL_STATUS;
  createActionLogInitialStatus: Action_Log_Status | undefined;
  setNotificationDetails: Dispatch<SetStateAction<any>>;
  setSummaryModalStatus: Dispatch<SetStateAction<ACTION_LOG_SUMMARY_MODAL_STATUS | undefined>>;
  handleCloseSummaryModal: (isFromEdit?: boolean) => void | VoidFunction;
  selectedRoutine: Routine;
};
function SummaryModal({
  locationName,
  locationSlug,
  actionLogId,
  summaryModalStatus,
  setNotificationDetails,
  createActionLogInitialStatus,
  handleCloseSummaryModal,
  setSummaryModalStatus,
  selectedRoutine,
}: Props) {
  const descriptionMaxLength = 1000;
  const successCriteriaMaxLength = 255;
  const notesMaxLength = 1500;
  const textAreaAlertLength = 30;

  const { t } = useTranslation();
  const lng = useTranslation()[1].language;
  const loggedInUser = useSelector(selectUser);

  const [savingInProgress, setSavingInProgress] = useState<boolean>(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState<boolean>(false);

  const [createEditActionLog] = useCreateEditActionLogMutation();
  const [updateActionLogStatus] = useActionLogStatusMutation();
  const [deleteActionLogMutation] = useDeleteActionLogMutation();
  const [deleteActionLogConfirmationModal, setDeleteActionLogConfirmationModal] = useState({
    isLoading: false,
    description: `Si eliminas no podrás recuperar la información de la tarjeta #${actionLogId} más adelante. ¿Deseas continuar?`,
    okButtonText: 'Eliminar',
  });
  const [getSummary, getSummaryResponse] = useSummaryForActionLogLazyQuery({
    fetchPolicy: 'cache-and-network',
    pollInterval: 5000,
  });

  const actionLogSummary: CreateEditActionLog = getSummaryResponse?.data?.actionLog || {} as any;

  function initializer(initializerState = initialCreateEditActionLogState) {
    if (actionLogSummary?.id) {
      return formatPreFilledState(actionLogSummary);
    }
    const { name, slug, position, profile, id } = loggedInUser.toJS();

    return {
      ...initializerState,
      [CreateEditActionLogState.RESPONSIBLES]: [
        {
          userId: id,
          name,
          slug,
          position,
          profile,
        },
      ],
      [CreateEditActionLogState.OWNER]: slug,
      [CreateEditActionLogState.STATUS]: createActionLogInitialStatus,
      [CreateEditActionLogState.START_DATE]: new Date(),
      [CreateEditActionLogState.END_DATE]: new Date(),
      [CreateEditActionLogState.LOCATION]: { name: locationName, slug: locationSlug },
      [CreateEditActionLogState.IMAGES]: [],
      [CreateEditActionLogState.MEETING]: {
        label: selectedRoutine.label || '',
        value: selectedRoutine.value || '',
      },
      [CreateEditActionLogState.ROUTINES_AVAILABLE]: {
        name: selectedRoutine.label || '',
        slug: selectedRoutine.value || '',
      },
    };
  }

  const [state, dispatch] = useReducer(
    reducerCreateEditActionLog,
    initialCreateEditActionLogState,
    initializer,
  );

  const toastConfig = {
    position: 'bottom-left',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
  } as ToastOptions;

  const endDate = state?.endDate ? new Date(state.endDate) : new Date();

  function onRemoveResponsibleHandler(slug: string) {
    const updatedResponsibles = state.responsibles.filter(
      (responsible: { slug: string }) => responsible.slug !== slug,
    );
    dispatch({ type: CreateEditActionLogState.RESPONSIBLES, payload: updatedResponsibles });
  }
  function handleAddResponsible(user: Responsible) {
    dispatch({
      type: CreateEditActionLogState.RESPONSIBLES,
      payload: [...state.responsibles, user],
    });
  }
  function updateStatusChangeState(status: Action_Log_Status) {
    dispatch({ type: CreateEditActionLogState.STATUS, payload: status });
  }
  function changeOwner(slug: string) {
    if (summaryModalStatus !== ACTION_LOG_SUMMARY_MODAL_STATUS.SUMMARY) {
      dispatch({ type: CreateEditActionLogState.OWNER, payload: slug });
    }
  }
  async function handleStatusChange(event: React.ChangeEvent<{ value: unknown }>) {
    updateStatusChangeState(event.target.value as Action_Log_Status);
    try {
      if (actionLogId && !!event.target.value) {
        const response = await updateActionLogStatus({
          variables: {
            actionLogId: Number(actionLogId),
            status: event.target.value as Action_Log_Status,
            isOwnerCheckSurpassed: true,
          },
        });
        if (!response.data?.updateActionLogStatus?.isSuccess) {
          updateStatusChangeState(actionLogSummary?.status as Action_Log_Status);
        }
      }
    } catch (error) {
      console.log('handleStatusChange e', error);
    }
  }
  function toggleConfirmationModal() {
    setShowConfirmationModal(prevState => !prevState);
  }
  function toggleDeleteConfirmationModel() {
    setShowDeleteConfirmationModal(prevState => !prevState);
  }
  async function deleteActionLog(id: number) {
    try {
      setDeleteActionLogConfirmationModal(prevState => ({ ...prevState, isLoading: true }));
      await deleteActionLogMutation({
        variables: {
          actionLogId: id,
        },
      });
      toast.success('Se eliminó correctamente', toastConfig);
      toggleDeleteConfirmationModel();
      handleCloseSummaryModal();
    } catch (err) {
      setDeleteActionLogConfirmationModal(prevState => ({
        ...prevState,
        description: `Falló el intento de eliminar la tarjeta ${actionLogId}, ¿deseas reintentar?`,
        okButtonText: 'Reintentar',
        isLoading: false,
      }));
    }
  }
  function onCancelButtonClick() {
    if (summaryModalStatus === ACTION_LOG_SUMMARY_MODAL_STATUS.SUMMARY) {
      handleCloseSummaryModal();
    } else if (
      isActionLogFilledPartially(state, loggedInUser.toJS().slug)
      || isEqual(
        state,
        formatPreFilledState(actionLogSummary || initialCreateEditActionLogState),
      )) {
      toggleConfirmationModal();
    }
  }
  function onDeleteButtonClick() {
    toggleDeleteConfirmationModel();
  }
  function handleConfirmationModelCancel() {
    toggleConfirmationModal();
    if (summaryModalStatus !== ACTION_LOG_SUMMARY_MODAL_STATUS.SUMMARY) {
      handleCloseSummaryModal(summaryModalStatus === ACTION_LOG_SUMMARY_MODAL_STATUS.EDIT);
    }
    dispatch({ type: 'RESET', payload: initializer() });
  }
  function handleCreateDateChange(date: Date) {
    dispatch({ type: CreateEditActionLogState.START_DATE, payload: date });
    if (isAfter(new Date(date), new Date(state.endDate))) {
      dispatch({ type: CreateEditActionLogState.END_DATE, payload: date });
    }
  }

  function handleEndDateChange(date: Date) {
    dispatch({ type: CreateEditActionLogState.END_DATE, payload: date });
  }

  function handleDescriptionChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
    dispatch({ type: CreateEditActionLogState.DESCRIPTION, payload: event.target.value });
  }

  function handleDescriptionOnBlur(event: React.ChangeEvent<HTMLTextAreaElement>) {
    dispatch({ type: CreateEditActionLogState.DESCRIPTION, payload: event.target.value.trim() });
  }

  function handleSuccessCriteriaChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
    dispatch({ type: CreateEditActionLogState.SUCCESS_CRITERIA, payload: event.target.value });
  }
  function changePillar(pillar: ACTION_LOG_PILLAR_IMAGE) {
    dispatch({ type: CreateEditActionLogState.PILLAR, payload: pillar });
  }
  function handleMeetingChange(meeting: Routine) {
    dispatch({ type: CreateEditActionLogState.MEETING, payload: meeting });
  }
  function handleManagementToolChange(managementTool: ManagementTool) {
    dispatch({ type: CreateEditActionLogState.MANAGEMENT_TOOL, payload: managementTool });
  }
  function onKPISelectionHandler(kpi: KPIState) {
    dispatch({ type: CreateEditActionLogState.KPIS, payload: kpi });
  }
  function handleNoteChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
    dispatch({ type: CreateEditActionLogState.NOTE, payload: event.target.value });
  }
  function onImageAddHandler(url: string, extension: string) {
    dispatch({
      type: CreateEditActionLogState.IMAGES,
      payload: [
        ...state[CreateEditActionLogState.IMAGES],
        {
          url,
          id: url,
          extension,
        },
      ],
    });
  }

  function onImageRemoveHandler(uri: string) {
    dispatch({
      type: CreateEditActionLogState.IMAGES,
      payload: [
        ...state[CreateEditActionLogState.IMAGES].filter(
          (image: { url: string }) => image.url !== uri,
        ),
      ],
    });
  }
  const isStateFilledCompletelyCb: () => boolean = useCallback(() => {
    const { note: _note, images: _images, ...requiredStates } = state;
    return isStateFilledCompletely(requiredStates);
  }, [state]);

  async function handleImageUpload(images: LocalImage[]): Promise<ImageInputItem[]> {
    const payloadImages: ImageInputItem[] = [...images];
    const s3Target = 'action_log';

    const uploadPromises = images.map(
      image => new Promise<{ url: string | undefined; id: string }>(resolve => {
        if (!image.url.includes(`s3.${process.env.AWS_REGION}`)) {
          requestImageUploadPermission('jpeg', s3Target)
            .then(response => {
              if (response?.data?.preSignedUrl) {
                return uploadObjectImage(response.data.preSignedUrl, image.url);
              }
            })
            .then(url => {
              resolve({
                url: url || image.id,
                id: image.id,
              });
            })
            .catch(() => {
              resolve({
                url: image.id,
                id: image.id,
              });
            });
        } else {
          resolve({
            url: image.id,
            id: image.id,
          });
        }
      }),
    );
    try {
      const uploadedImages = await Promise.all(uploadPromises);
      uploadedImages.forEach((imageObj, index) => {
        if (imageObj && imageObj.url) {
          payloadImages[index] = { url: imageObj.url, id: imageObj.id };
        }
      });
    } catch (error) {
      console.log('handleImageUpload e()', error);
    }
    return payloadImages;
  }
  function createEditFailureNotification() {
    setNotificationDetails({
      isOpen: true,
      alertTitle: 'Action log',
      alertDescription: 'Something went wrong. Please try again',
      alertType: AlertTypeProps.ERROR,
    });
  }
  async function onSubmitActionLogData() {
    try {
      setSavingInProgress(true);
      const payload = ({ ...state } as unknown) as CreateEditActionLogPayload;
      if (actionLogId) {
        payload.id = actionLogId;
      }
      payload.images = await handleImageUpload(state[CreateEditActionLogState.IMAGES]);
      payload.routinesAvailableSlug = state[CreateEditActionLogState.MEETING].value;
      const response = await createEditActionLog({
        variables: {
          payload: cleanActionLogDBPayload(payload),
        },
      });
      if (response.data?.createEditActionLog.isSuccess) {
        setNotificationDetails({
          isOpen: true,
          alertTitle: 'Tarea',
          alertDescription: summaryModalStatus === ACTION_LOG_SUMMARY_MODAL_STATUS.CREATE
            ? 'Tarea ha sido creada con éxito'
            : 'tarea ha sido actualizada con éxito',
          alertType: AlertTypeProps.SUCCESS,
        });
      } else {
        createEditFailureNotification();
      }
    } catch (e) {
      createEditFailureNotification();
      dispatch({ type: 'RESET', payload: initializer() });
      console.log('onSubmitActionLogData e()', e);
    } finally {
      if (summaryModalStatus === ACTION_LOG_SUMMARY_MODAL_STATUS.CREATE) {
        handleCloseSummaryModal();
      } else if (getSummaryResponse?.refetch) {
        getSummaryResponse.refetch();
        setSummaryModalStatus(ACTION_LOG_SUMMARY_MODAL_STATUS.SUMMARY);
      }
      setSavingInProgress(false);
    }
  }

  function onSetActionLogModalStatusToEdit() {
    setSummaryModalStatus(ACTION_LOG_SUMMARY_MODAL_STATUS.EDIT);
  }

  const statusTimeLineText = (): string => {
    const dateDifference = differenceInCalendarDays(endDate, (new Date()));
    if (dateDifference === 0) {
      return t(TRANSLATIONS.SHOULD_BE_CLOSED_TODAY);
    } if (dateDifference > 0) {
      return `${lng === 'es' ? 'Quedan' : ''} ${dateDifference} ${dateDifference === 1 ? t(TRANSLATIONS.DAY) : t(TRANSLATIONS.DAYS)} ${lng !== 'es' ? 'left' : ''} ${t(TRANSLATIONS.TO_BE_CLOSED)}`;
    }
    return `${t(TRANSLATIONS.SHOULD_HAVE_BEEN_CLOSED)} ${dateDifference * -1} ${dateDifference * -1 === 1 ? t(TRANSLATIONS.DAY) : t(TRANSLATIONS.DAYS)} ${lng !== 'es' ? 'ago' : ''} `;
  };

  function handleModalClose() {
    handleCloseSummaryModal();
  }

  const isSaveButtonDisabled = !isStateFilledCompletelyCb()
    || isEqual(
      state,
      formatPreFilledState(
        summaryModalStatus === ACTION_LOG_SUMMARY_MODAL_STATUS.CREATE
          ? initializer(initialCreateEditActionLogState)
          : actionLogSummary,
      ),
    );

  useEffect(() => {
    if (actionLogId) {
      getSummary({ variables: { actionLogId } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionLogId]);

  useEffect(() => {
    if (actionLogSummary?.id) {
      dispatch({ type: 'RESET', payload: initializer() });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionLogSummary?.id]);

  const columnData = trelloBoardColumns(t);
  const createdInTxt = `${t(TRANSLATIONS.CREATED)} ${t(TRANSLATIONS.IN)}`;

  if (summaryModalStatus) {
    return (
      <Modal
        aria-labelledby="transition-modal"
        aria-describedby="transition-modal-description"
        className={`summary_modal ${summaryModalStatus === ACTION_LOG_SUMMARY_MODAL_STATUS.SUMMARY
          ? ''
          : 'summary_modal_without_comment'}`}
        open={!!summaryModalStatus}
        onClose={handleModalClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={!!summaryModalStatus}>
          <div id="transition-modal" className="modal_children_container">
            {(!state || savingInProgress || getSummaryResponse.loading) ? (
              <div className="loader">
                <CircularProgress size={30} />
                <p className="loading_text">{savingInProgress ? 'Saving' : 'Loading'}</p>
              </div>
            ) : (
              <ActionLogProvider actionLog={{ ...actionLogSummary, id: actionLogId as string }}>
                <ButtonsForSummaryModal
                  modalStatus={summaryModalStatus}
                  onCancelClick={onCancelButtonClick}
                  onDeleteClick={onDeleteButtonClick}
                  onSetActionLogModalStatusToEdit={onSetActionLogModalStatusToEdit}
                  canEdit={state.owner === loggedInUser.toJS().slug}
                />
                <div className="modal_children_sub_container">
                  <div
                    className={`summary_section ${summaryModalStatus === ACTION_LOG_SUMMARY_MODAL_STATUS.SUMMARY
                      ? ''
                      : 'summary_section_full_width'}`}
                  >
                    <div className="details_first_section">
                      <div className="first_row_details">
                        <div className="participant_image_section">
                          {state?.responsibles?.length && (
                            <ResponsiblesAvatarWithTooltip
                              responsibles={state.responsibles}
                              owner={state.owner}
                              modalStatus={summaryModalStatus}
                              changeOwner={changeOwner}
                              onRemoveResponsibleHandler={onRemoveResponsibleHandler}
                              location={state.location}
                              handleAddResponsible={handleAddResponsible}
                            />
                          )}
                        </div>
                        {!isEspectro ? (
                          <div className="start_end_status_container">
                            <div className="start_end_details">
                              <div className="calender_section_container">
                                <SummaryModalDatePicker
                                  primaryDate={state.startDate}
                                  secondaryDate={state.endDate}
                                  text={t(TRANSLATIONS.CREATED)}
                                  modalStatus={summaryModalStatus}
                                  onDateChange={handleCreateDateChange}
                                  showTimePicker
                                  status={state[CreateEditActionLogState.STATUS]}
                                />
                              </div>
                              <div className="calender_section_container">
                                <SummaryModalDatePicker
                                  secondaryDate={state.startDate}
                                  primaryDate={state.endDate}
                                  minDate={state.startDate}
                                  text={t(TRANSLATIONS.DUE_DATE)}
                                  modalStatus={summaryModalStatus}
                                  onDateChange={handleEndDateChange}
                                  isEndDate
                                  status={state[CreateEditActionLogState.STATUS]}
                                />
                              </div>
                            </div>
                            <div className="action_log_status_container">
                              <div>
                                <FormControl className="select_status_form">
                                  <Select
                                    className="select_status"
                                    autoWidth
                                    variant="outlined"
                                    labelId="simple-select-label"
                                    id="simple-select"
                                    value={state.status}
                                    onChange={handleStatusChange as SelectInputProps<any>['onChange']}
                                  >
                                    {columnData.map(status => (
                                      <MenuItem className="single_status" value={status.status} key={status.status}>
                                        <img
                                          className="pillar_image"
                                          src={status.icon}
                                          alt="status"
                                        />
                                        {' '}{status.name}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </div>
                              <p className="time_passed">
                                {(actionLogSummary?.statusUpdateDate) && (
                                <p>{lng}
                                  {t(TRANSLATIONS.SINCE)}{lng === 'es' ? 'el' : ''}{' '}
                                  {getTranslatedDateTimeValue(
                                    actionLogSummary?.statusUpdateDate,
                                    'eee dd',
                                  )}{' '}
                                  {lng === 'es' ? 'de' : ''}{' '}
                                  {getTranslatedDateTimeValue(
                                    actionLogSummary?.statusUpdateDate,
                                    'MMM',
                                  )}
                                </p>
                                )}
                              </p>
                            </div>
                          </div>
                        ) : (
                          <div className="action_log_status_container">
                            <div>
                              <FormControl className="select_status_form">
                                <Select
                                  className="select_status"
                                  autoWidth
                                  variant="outlined"
                                  labelId="simple-select-label"
                                  id="simple-select"
                                  value={state.status}
                                  onChange={handleStatusChange as SelectInputProps<any>['onChange']}
                                >
                                  {columnData.map(status => (
                                    <MenuItem className="single_status" value={status.status} key={status.status}>
                                      <img
                                        className="pillar_image"
                                        src={status.icon}
                                        alt="status"
                                      />
                                      {' '}{status.name}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </div>
                            <p className="time_passed">
                              {(actionLogSummary?.statusUpdateDate) && (
                              <p>{lng}
                                {t(TRANSLATIONS.SINCE)}{lng === 'es' ? 'el' : ''}{' '}
                                {getTranslatedDateTimeValue(
                                  actionLogSummary?.statusUpdateDate,
                                  'eee dd',
                                )}{' '}
                                {lng === 'es' ? 'de' : ''}{' '}
                                {getTranslatedDateTimeValue(
                                  actionLogSummary?.statusUpdateDate,
                                  'MMM',
                                )}
                              </p>
                              )}
                            </p>
                          </div>
                        )}
                      </div>
                      {summaryModalStatus === ACTION_LOG_SUMMARY_MODAL_STATUS.SUMMARY
                        ? (
                          <div>
                            {isEspectro ? (
                              <div className="espectro_label">
                                {t(TRANSLATIONS.TASK_DESCRIPTION)}
                              </div>
                            ) : <></>}
                            <div className="description margin_bottom">{state?.description} </div>
                          </div>
                        )
                        : (
                          <>
                            {isEspectro ? (
                              <div className="espectro_label">
                                {t(TRANSLATIONS.TASK_DESCRIPTION)}
                              </div>
                            ) : (
                              <div className="Description_title">
                                {t(TRANSLATIONS.DESCRIPTION)}
                              </div>
                            )}
                            <TextareaAutosize
                              maxLength={descriptionMaxLength}
                              onChange={handleDescriptionChange}
                              className={`description ${isEspectro ? 'edit_textarea_espectro' : 'edit_textarea'}`}
                              value={state?.description}
                              placeholder={t(TRANSLATIONS.WRITE_ACTION_HERE)}
                              onBlur={handleDescriptionOnBlur}
                              minRows={isEspectro ? 3 : 1}
                            />
                            <div className={`${isEspectro ? 'words_left_espectro' : 'words_left'} ${descriptionMaxLength - state?.description?.length < textAreaAlertLength ? 'red' : ''}`}>
                              {` ${state?.description?.length}/${descriptionMaxLength} `}
                            </div>
                          </>
                        )}
                      <div className="success_criteria">
                        <div className="success_criteria__header">
                          <img style={isEspectro ? { width: 12, height: 12 } : {}} src={isEspectro ? ImageLinks.espectro.blueTick : ImageLinks.greenChecked} alt="success" />
                          <p className={isEspectro ? 'espectro_label' : 'success_criteria__header__title'}>{t(isEspectro ? TRANSLATIONS.SUCCESS_CRITERIA : TRANSLATIONS.SUCCESS_CRITERIA.toUpperCase())}
                          </p>
                        </div>
                        {summaryModalStatus === ACTION_LOG_SUMMARY_MODAL_STATUS.SUMMARY ? (
                          <p className="success_criteria__description">{state.successCriteria}</p>
                        ) : (
                          <>
                            <TextareaAutosize
                              maxLength={successCriteriaMaxLength}
                              onChange={handleSuccessCriteriaChange}
                              className={`success_criteria__description ${isEspectro ? 'edit_textarea_espectro' : 'edit_textarea'}`}
                              value={state.successCriteria}
                              placeholder={t(TRANSLATIONS.WRITE_SUCCESS_CRITERIA_HERE)}
                              minRows={isEspectro ? 3 : 1}
                            />
                            <div className={`${isEspectro ? 'words_left_espectro' : 'words_left'} ${successCriteriaMaxLength - state?.successCriteria?.length < textAreaAlertLength ? 'red' : ''}`}>
                              {`${state?.successCriteria?.length}/${successCriteriaMaxLength}`}
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    {
                      state.status !== Action_Log_Status.Completed
                      && summaryModalStatus === ACTION_LOG_SUMMARY_MODAL_STATUS.SUMMARY
                      && (
                        <p className={`status_time_line_text ${Number(new Date()) < Number(endDate) ? 'status_time_line_text_black' : ''}`}>
                          {statusTimeLineText()}
                        </p>
                      )
                    }

                    <div className="details_second_section">
                      {!isEspectro && (
                      <PillarForSummaryModal
                        selectedPillar={state.pillar}
                        changePillar={changePillar}
                        modalStatus={summaryModalStatus}
                      />
                      )}
                      <div className={isEspectro ? 'extra_details_section_espectro' : 'extra_details_section'}>
                        <div className="extra_details_section__first_section">
                          <MeetingForSummaryModal
                            modalStatus={summaryModalStatus}
                            meeting={state[CreateEditActionLogState.MEETING]}
                            handleMeetingChange={handleMeetingChange}
                          />
                          {summaryModalStatus === ACTION_LOG_SUMMARY_MODAL_STATUS.SUMMARY && (
                            <div className="created">
                              <p className="extra_title">{createdInTxt.toUpperCase()} </p>
                              <p className="extra_sub_title">
                                {actionLogOriginText(
                                  actionLogSummary?.origin as Action_Log_Origin,
                                  actionLogSummary?.originText || '',
                                  t,
                                )}{' '}
                              </p>
                            </div>
                          )}
                          {!isEspectro && (
                            <ManagementToolForSummaryModal
                              modalStatus={summaryModalStatus}
                              managementTool={state[CreateEditActionLogState.MANAGEMENT_TOOL]}
                              handleManagementToolChange={handleManagementToolChange}
                            />
                          )}
                        </div>
                        <KPIForSummaryModal
                          modalStatus={summaryModalStatus}
                          currentKPI={state.KPIs}
                          onKPISelectionHandler={onKPISelectionHandler}
                        />
                      </div>
                      {isEspectro && (
                        <div className="start_end_status_container_espectro">
                          <p>Fechas</p>
                          <div className="start_end_details_espectro">
                            <div className="calender_section_container_espectro">
                              <SummaryModalDatePicker
                                primaryDate={state.startDate}
                                secondaryDate={state.endDate}
                                text={t(TRANSLATIONS.CREATED)}
                                modalStatus={summaryModalStatus}
                                onDateChange={handleCreateDateChange}
                                showTimePicker
                                status={state[CreateEditActionLogState.STATUS]}
                              />
                            </div>
                            <div className="calender_section_container_espectro">
                              <SummaryModalDatePicker
                                secondaryDate={state.startDate}
                                primaryDate={state.endDate}
                                minDate={state.startDate}
                                text={t(TRANSLATIONS.DUE_DATE)}
                                modalStatus={summaryModalStatus}
                                onDateChange={handleEndDateChange}
                                isEndDate
                                status={state[CreateEditActionLogState.STATUS]}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="note_section">
                        <p className="note_title">{t(TRANSLATIONS.NOTE)}
                        </p>
                        {summaryModalStatus === ACTION_LOG_SUMMARY_MODAL_STATUS.SUMMARY
                          ? <p className={state.note ? 'edit_notes notes' : 'greyedTxt notes'}> {state.note ? state.note : t(TRANSLATIONS.THERE_IS_NO_NOTE_ADDED_FOR_THIS_ACTION_LOG)}</p>
                          : (
                            <>
                              <TextareaAutosize
                                maxLength={notesMaxLength}
                                placeholder={`${t(TRANSLATIONS.WRITE_A_NOTE)} ...`}
                                onChange={handleNoteChange}
                                className={`edit_notes ${isEspectro ? 'edit_textarea_espectro' : 'edit_textarea'}`}
                                value={state.note}
                                minRows={isEspectro ? 3 : 1}
                              />
                              <div className={`words_left ${notesMaxLength - state?.note?.length < textAreaAlertLength ? 'red' : ''}`}>
                                {`${state?.note?.length || 0}/${notesMaxLength}`}
                              </div>
                            </>
                          )}
                      </div>
                      <ImageSectionForSummaryModal
                        images={state.images as ImageInputItem[]}
                        modalStatus={summaryModalStatus}
                        onImageAddHandler={onImageAddHandler}
                        onImageRemoveHandler={onImageRemoveHandler}
                      />
                    </div>
                    {summaryModalStatus !== ACTION_LOG_SUMMARY_MODAL_STATUS.SUMMARY && (
                      <div className="save_update_btn_container">
                        <Button className="btn cancel" onClick={toggleConfirmationModal}>
                          {' '}
                          {t(TRANSLATIONS.CANCEL)}
                        </Button>
                        <Button
                          disabled={isSaveButtonDisabled}
                          className="btn save_update"
                          onClick={onSubmitActionLogData}
                        >
                          {summaryModalStatus === ACTION_LOG_SUMMARY_MODAL_STATUS.EDIT
                            ? t(TRANSLATIONS.SAVE_ACTION_LOG)
                            : t(TRANSLATIONS.CREATE_ACTION_LOG)}
                        </Button>
                      </div>
                    )}
                  </div>
                  {summaryModalStatus === ACTION_LOG_SUMMARY_MODAL_STATUS.SUMMARY && (
                    <div className="comment_section">
                      <CommentSection
                        type={COMMENT_CREATE_TYPE.ACTION_LOG}
                        customContext={useActionLogContext}
                        createEditMuation={useAddOrEditActionLogCommentMutation}
                        refetchQueries={actionLogCommentRefetchQueries}
                        editCommentsMutation={useAddOrEditActionLogCommentMutation}
                        deleteCommentsMutation={useDeleteActionLogCommentMutation}
                        detailedCommentsQuery={useDetailedCommentsForActionLogQuery}
                        dynamicKeyName="actionLogId"
                        errorMessage="deleteActionLogComment e()"
                        requestIdKey="commentsActionLogId"
                        responseKey="comments"
                      />
                    </div>
                  )}
                </div>
              </ActionLogProvider>
            )}
            {showConfirmationModal && (
              <ConfirmationModal
                title={t(TRANSLATIONS.WAIT_A_MOMENT)}
                description={`${t(TRANSLATIONS.IF_YOU_LEAVE_IT_WITHOUT_SAVING_YOUR_CHANGES_WILL_LOST_WANT_TO_STAY_HERE)}`}
                isOpen={showConfirmationModal}
                onOk={toggleConfirmationModal}
                okButtonText={`${(t(TRANSLATIONS.CONTINUE).toLowerCase())} ${(t(TRANSLATIONS.HERE).toLowerCase())}`}
                cancelButtonText={t(TRANSLATIONS.LEAVE_WITHOUT_SAVE)}
                onCancel={handleConfirmationModelCancel}
                okButtonStyle={{ backgroundColor: '#56cd9d', color: '#fff' }}
              />
            )}
            {showDeleteConfirmationModal && (
              <ConfirmationModal
                title={`${t(TRANSLATIONS.DELETE_CARD)} #${actionLogId}`}
                description={deleteActionLogConfirmationModal.description}
                isOpen={showDeleteConfirmationModal}
                onOk={() => deleteActionLog(Number(actionLogId))}
                okButtonText={deleteActionLogConfirmationModal.okButtonText}
                okButtonIcon={ImageLinks.deleteIcon}
                cancelButtonText={t(TRANSLATIONS.CANCEL)}
                cancelButtonIcon={ImageLinks.leftArrowIcon}
                onCancel={toggleDeleteConfirmationModel}
                isOkButtonLoading={deleteActionLogConfirmationModal.isLoading}
                cancelButtonStyle={{ width: '110px' }}
                okButtonStyle={{ color: '#C00000', width: '106px' }}
              />
            )}
          </div>
        </Fade>
      </Modal>
    );
  }
  return <></>;
}

export default SummaryModal;
