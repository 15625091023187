import React, { Suspense } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from 'react-router-dom';
import LoadingSpinner from 'components/LoadingSpinner';
import ResetPassword from 'features/internal-users/user/ForgotPassword/ResetPassword';
import RecoverPassword from 'features/internal-users/user/RecoverPassword/RecoverPassword';

import DownloadReport from 'features/Espectro/user-download/DownloadReport';
import ChecklistReport from 'features/Espectro/user-download/ChecklistReport/ChecklistReport';
import ChecklistRenderer from 'features/Espectro/ChecklistRenderer';
import AddAsset from 'features/Espectro/AssetBuilder/AddAsset';

import AssetsDashboard from 'features/Espectro/Assets/AssetsDashboard';
import AssetType from 'features/Espectro/Assets/AssetType';

import AssetInfo from 'features/Espectro/Assets/AssetInfo';
import AuthWrapper from './features/internal-users/user/AuthWrapper';
import Login from './features/internal-users/user/Login';
import Register from './features/internal-users/user/Register';
import Boards from './features/action-logs/Boards';

// import Logout from './features/Logout';
// /**
//  * Espectro Screens
// */
import ChecklistBuilder from './features/Espectro/ChecklistBuilder';
import NewChecklist from './features/Espectro/NewChecklist';
import WorkOrders from './features/Espectro/WorkOrders';
import NewWorkOrder from './features/Espectro/NewWorkOrder';
import CancelWorkOrder from './features/Espectro/CancelWorkOrder';
import UserManual from './features/Espectro/UserManual';
import PdfViewer from './features/Espectro/components/PdfRenderer/PdfRenderer';
import UsersIndexN from 'features/internal-users/user/UsersIndexN';
import CompanyAnalytics from 'features/analytics/CompanyAnalytics/CompanyAnalytics';

export default () => {
  return (
    <Router>
      <Suspense
        fallback={(
          <LoadingSpinner
            style={{
              position: 'fixed',
              top: '50%',
              right: '50%',
              marginLeft: 30,
              marginBottom: 30,
            }}
          />
      )}
      >
        <Routes>
          <Route path="*" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register/:token" element={<Register />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/recover-password/:token" element={<RecoverPassword />} />
          <Route path="/answer-checklist/:token" element={<ChecklistRenderer />} />

          <Route path="/action-log/board" element={<AuthWrapper component={Boards} />} />
          <Route path="/analytics/routines" element={<AuthWrapper component={CompanyAnalytics} />} />
          <Route path="/all-users" element={<AuthWrapper component={UsersIndexN} />} />
          <Route path="/espectro/user-manual" element={<AuthWrapper component={UserManual} />} />
          <Route path="/espectro/checklist-builder" element={<AuthWrapper component={ChecklistBuilder} />} />
          <Route path="/espectro/new-checklist-builder" element={<AuthWrapper component={NewChecklist} />} />
          <Route path="/espectro/download-report" element={<AuthWrapper component={DownloadReport} />} />
          <Route path="/espectro/checklist-report" element={<AuthWrapper component={ChecklistReport} />} />
          <Route path="/espectro/work-orders" element={<AuthWrapper component={WorkOrders} />} />
          <Route path="/espectro/new-work-order" element={<AuthWrapper component={NewWorkOrder} />} />
          <Route path="/espectro/work-order-info" element={<AuthWrapper component={CancelWorkOrder} />} />
          <Route path="/espectro/work-order-report/:workOrderId" element={<AuthWrapper component={PdfViewer} />} />
          <Route path="/espectro/assets/add-asset/:assetId" element={<AuthWrapper component={AddAsset} />} />
          <Route path="/espectro/assets/add-asset" element={<AuthWrapper component={AddAsset} />} />
          <Route path="/espectro/assets-dashboard" element={<AuthWrapper component={AssetsDashboard} />} />
          <Route path="/espectro/assets/assets-info/:assetsId" element={<AuthWrapper component={AssetInfo} />} />
          <Route path="/espectro/assets/add-asset-type" element={<AuthWrapper component={AssetType} />} />
        </Routes>
      </Suspense>
    </Router>
  );
};
