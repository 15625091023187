/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TRANSLATIONS } from 'types/enums';
import ImageLinks from 'utils/ImageLinks';
import { ToastOptions, toast } from 'react-toastify';
import ActionButton from 'components/Espectro/Buttons/Action/Action';
import './AssetType.scss';
import Overlay from 'components/Espectro/Overlay';
import { useCreateNewListMutation } from 'generated/graphql';
import { ChecklistBitTypes, ChecklistItem } from 'features/Espectro/types';
import { useNavigate } from 'react-router-dom';
import { CircularProgress, Button, Menu, MenuItem } from '@mui/material';
import { CHECKLIST_TITLE_MAX_LENGTH } from 'features/Espectro/constants';

// interface ChecklistHeaderProps {
//   goBack: () => void;
// }

interface AssetListItem {
  title: string;
  bits: [{
    type: ChecklistBitTypes;
  }];
}

const AssetType = () => {
  const { t } = useTranslation();
  const [editTitle, setEditTitle] = useState<boolean>(false);
  const toggleEditTitle = () => setEditTitle(prev => !prev);
  const [listItems, setListItems] = useState<AssetListItem[]>([]);
  const [newFieldName, setNewFieldName] = useState('');
  const [assetTypeTitle, setAssetTypeTitle] = useState<string>('');
  const [showNewField, setShowNewField] = useState(false);
  const [showRenameField, setShowRenameField] = useState(false);
  const [showDeleteField, setShowDeleteField] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [openedFieldIndex, setOpenedFieldIndex] = useState<number | null>(null);
  const [decimalEnabled, setDecimalEnabled] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState({
    icon: ImageLinks.espectro.abc,
    text: t(TRANSLATIONS.TEXT),
  });

  const navigate = useNavigate();
  const goBack = () => navigate(-1);

  const toastConfig = {
    position: 'top-right',
    autoClose: 4000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: false,
    progress: undefined,
  } as ToastOptions;

  const [
    createNewListMutation, { loading: createNewListLoading },
  ] = useCreateNewListMutation({
    onCompleted() {
      goBack();
      console.log('CREATE NEW LIST SUCCESSFULL');
    },
    onError(e) {
      console.log('error', e.message);
      toast.error(e.message, toastConfig);
    },
  });

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleFieldDropdown = (index: number) => {
    setOpenedFieldIndex(index);
  };

  const handleOptionSelect = (icon: any, text: any) => {
    setSelectedOption({ icon, text });
    setIsDropdownOpen(false);
    setOpenedFieldIndex(null);
  };

  const getItemType = (): ChecklistBitTypes => {
    switch (selectedOption.text) {
      case t(TRANSLATIONS.TEXT):
        return ChecklistBitTypes.TEXT;
      case t(TRANSLATIONS.NUMBER):
        return ChecklistBitTypes.INPUT;
      case t(TRANSLATIONS.DATE):
        return ChecklistBitTypes.DATE;
      default: return ChecklistBitTypes.TEXT;
    }
  };

  const getItemTypeString = (type: ChecklistBitTypes | undefined) => {
    switch (type) {
      case ChecklistBitTypes.TEXT:
        return t(TRANSLATIONS.TEXT);
      case ChecklistBitTypes.INPUT:
        return t(TRANSLATIONS.NUMBER);
      case ChecklistBitTypes.DATE:
        return t(TRANSLATIONS.DATE);
      default: break;
    }
  };

  const getItemIcon = (type: ChecklistBitTypes | undefined) => {
    switch (type) {
      case ChecklistBitTypes.TEXT:
        return ImageLinks.espectro.abc;
      case ChecklistBitTypes.INPUT:
        return ImageLinks.espectro.numberInputIcon;
      case ChecklistBitTypes.DATE:
        return ImageLinks.espectro.calendar;
      default: break;
    }
  };

  const defaultFields = [
    {
      title: t(TRANSLATIONS.UNIQUE_NAME),
      bits: [{
        type: ChecklistBitTypes.TEXT,
      }],
    },
    {
      title: t(TRANSLATIONS.NAME_OF_INSPECTION),
      bits: [{
        type: ChecklistBitTypes.TEXT,
      }],
    },
  ];

  const handleCreateField = () => {
    if (!newFieldName) return;

    const newField: ChecklistItem | any = {
      title: newFieldName,
      bits: [{
        type: getItemType(),
      }],
    };
    if (selectedOption.text === t(TRANSLATIONS.NUMBER)) {
      newField.bits[0] = {
        ...newField.bits[0],
        props: {
          numericType: decimalEnabled ? 'decimal' : 'integer',
        },
      };
    }

    setListItems([...listItems, newField]);
    setShowNewField(false);
    setNewFieldName('');
    setIsDropdownOpen(false);
    setSelectedOption({ icon: ImageLinks.espectro.abc, text: 'Text' });
  };

  const renameField = () => {
    setShowRenameField(true);
  };

  const deleteField = () => {
    setShowDeleteField(true);
  };

  const handleRename = () => {
    if (!newFieldName) return;

    if ((openedFieldIndex || openedFieldIndex === 0) && newFieldName) {
      listItems[openedFieldIndex].title = newFieldName;
      setShowRenameField(false);
      setNewFieldName('');
      setOpenedFieldIndex(null);
    }
  };

  const handleDelete = () => {
    if (openedFieldIndex || openedFieldIndex === 0) {
      listItems.splice(openedFieldIndex, 1);
      setShowDeleteField(false);
      setOpenedFieldIndex(null);
    }
  };

  const handleDone = () => {
    const attributes = [{
      items: [...defaultFields, ...listItems],
    }];
    createNewListMutation({
      variables: {
        name: assetTypeTitle,
        attributes,
      },
    });
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return createNewListLoading ? (
    <div className="asset-loader">
      <CircularProgress size={30} />
    </div>
  ) : (
    <div className="page-container">
      <div className="assetTypeHeader-container">
        <div className="assetType_header-back-and-title-container">
          <div role="button" className="assetTypeHeader__back" onClick={goBack}>
            <img alt="backArrow" src={ImageLinks.espectro.backArrow} />
            <div>
              <h3 style={{ color: '#2B323B' }}>
                {t(TRANSLATIONS.ADD_ASSET_TYPE)}
              </h3>
              <p style={{ color: '#878787' }}>
                {t(TRANSLATIONS.CHANGES_ARE_SAVED_AUTOMATICALLY)}
              </p>
            </div>
          </div>
        </div>
        <div className="assetTypeHeader__button-container">
          <ActionButton
            className="assetTypeHeader-action-button-done"
            onClick={handleDone}
            text={t(TRANSLATIONS.DONE)}
            background="#6C63FF"
          />
        </div>
      </div>

      <div className="assetType__edit-container">
        <div className="assetType__edit-container1">
          {editTitle ? (
            <input
              className="checklistHeader_input"
              type="text"
              placeholder={t(TRANSLATIONS.ADD_ASSET_TYPE)}
              value={assetTypeTitle}
              onChange={e => setAssetTypeTitle(e.target.value)}
              onBlur={toggleEditTitle}
              autoFocus
              maxLength={CHECKLIST_TITLE_MAX_LENGTH}
            />
          ) : (
            <div className="checklistHeader_edit">
              <p onClick={toggleEditTitle}>{assetTypeTitle === ''
                ? t(TRANSLATIONS.ENTER_ASSET_TYPE) : assetTypeTitle}
              </p>
              <img onClick={toggleEditTitle} height={16} width={16} src={ImageLinks.edit} alt="edit" />
            </div>
          )}
          <p>{t(TRANSLATIONS.GENERAL)}</p>

          <div className="assetType_edit-cards">
            {/* Card */}
            <div className="assetType_edit-card">
              <span>{t(TRANSLATIONS.UNIQUE_NAME)}*</span>
              <div>
                <img src={ImageLinks.espectro.abc} alt="abc" />
                <span>{t(TRANSLATIONS.TEXT)}</span>
              </div>
            </div>
            <div className="assetType_edit-card">
              <span>{t(TRANSLATIONS.NAME_OF_INSPECTION)}*</span>
              <div>
                <img src={ImageLinks.espectro.abc} alt="abc" />
                <span>{t(TRANSLATIONS.TEXT)}</span>
              </div>
            </div>
          </div>

          <p>{t(TRANSLATIONS.DETAILS)}</p>
          {listItems.length > 0 ? (
            (listItems || [])?.map((listItem, index) => (
              <div key={String(index)} className="assetType_edit-card1">
                <span>{listItem.title}</span>
                <div>
                  <img src={getItemIcon(listItem.bits[0].type)} alt={listItem.bits[0].type} />
                  <span>{getItemTypeString(listItem.bits[0].type)}</span>
                  <Button
                    style={{ marginTop: 0 }}
                    id="demo-positioned-button"
                    aria-controls={open ? 'demo-positioned-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={event => {
                      handleClick(event);
                      handleFieldDropdown(index);
                    }}
                  >
                    <img
                      alt="dots"
                      id="dotsStyle"
                      style={{ cursor: 'pointer' }}
                      width={15}
                      height={15}
                      src={ImageLinks.espectro.verticalDots}
                    />
                  </Button>
                </div>
                {(openedFieldIndex !== null && openedFieldIndex === index) && (
                  <>
                    <Menu
                      id="demo-positioned-menu"
                      aria-labelledby="demo-positioned-button"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      className="assets_dashboard-option-menu"
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                    >
                      <MenuItem className="assets_dashboard-menu-item" onClick={handleClose}>
                        <div className="assets_type-menu-item-div" role="button" onClick={renameField}>
                          <img src={ImageLinks.edit} alt="rename" width={16} height={16} />
                          <span style={{ color: '#2B323B' }}>{t(TRANSLATIONS.RENAME_FIELD)}</span>
                        </div>
                      </MenuItem>
                      <MenuItem className="assets_dashboard-menu-item" onClick={handleClose}>
                        <div className="assets_type-menu-item-div" role="button" onClick={deleteField}>
                          <img src={ImageLinks.espectro.deleteRedEmpty} alt="delete" width={20} height={20} />
                          <span style={{ color: '#E92B36' }}>{t(TRANSLATIONS.DELETE_FIELD)}</span>
                        </div>
                      </MenuItem>
                    </Menu>
                  </>
                )}
              </div>
            ))
          ) : (
            <p style={{
              marginTop: 10,
              color: '#B5B5B5',
              fontSize: 16,
            }}
            >{t(TRANSLATIONS.ADD_RIGHT_PANEL_FIELDS)}
            </p>
          )}

          <ActionButton
            onClick={() => setShowNewField(true)}
            text={`+ ${t(TRANSLATIONS.CREATE_NEW_FIELD)}`}
              // disabled={!sectionsLength || loading}
            background="#6C63FF"
          />
        </div>
        <Overlay open={showNewField} onClose={() => setShowNewField(false)}>
          <div
            className="newfield-overlay"
          >
            <div>
              <p style={{ fontWeight: 700, fontSize: 24, color: '#2B323B' }}>{t(TRANSLATIONS.NEW_FIELD)}</p>
              <p>{t(TRANSLATIONS.NEW_FIELD_DESCRIPTION)}</p>
            </div>
            <div className="input-field_wrapper">
              <div role="button" className="input-container">
                <input
                  type="text"
                  className="input-style"
                  placeholder="Escribe aquí..."
                  value={newFieldName}
                  onChange={e => setNewFieldName(e.target.value)}
                />
              </div>
              <div className="dropdown-container_wrapper">
                <div
                  role="button"
                  className="checklist-question-popup"
                  onClick={handleDropdownToggle}
                >
                  <div>
                    <img src={selectedOption.icon} alt={selectedOption.text} style={{ marginRight: '10px' }} />
                    <span>{selectedOption.text}</span>
                  </div>
                  <img src={ImageLinks.chevronDown} width={24} height={24} alt="chevron-down" />
                </div>
                {isDropdownOpen && (
                <div className="dropdown-options">
                  {/* Option 1 */}
                  <div className="dropdown-option" role="button" onClick={() => handleOptionSelect(ImageLinks.espectro.abc, t(TRANSLATIONS.TEXT))}>
                    <img src={ImageLinks.espectro.abc} alt="abc" style={{ marginRight: '10px' }} />
                    <span>{t(TRANSLATIONS.TEXT)}</span>
                  </div>

                  {/* Option 2 */}
                  <div
                    className="dropdown-option"
                    role="button"
                    onClick={() => handleOptionSelect(ImageLinks.espectro.numberInputIcon, t(TRANSLATIONS.NUMBER))}
                  >
                    <img src={ImageLinks.espectro.numberInputIcon} alt="123" style={{ marginRight: '10px' }} />
                    <span>{t(TRANSLATIONS.NUMBER)}</span>
                  </div>

                    {/* Option 3 */}
                  <div className="dropdown-option" role="button" onClick={() => handleOptionSelect(ImageLinks.espectro.calendar, t(TRANSLATIONS.DATE))}>
                    <img src={ImageLinks.espectro.calendar} alt="date" style={{ marginRight: '10px' }} />
                    <span>{t(TRANSLATIONS.DATE)}</span>
                  </div>
                </div>
                )}
              </div>
            </div>
            <div>
              {selectedOption.text === 'Number' && (
              <span
                role="button"
                onClick={() => {
                  setDecimalEnabled(!decimalEnabled);
                }}
                className="add_answers-checklist-radio-buttons"
              >
                <img
                  src={!decimalEnabled ? ImageLinks.espectro.emptyCheck
                    : ImageLinks.espectro.successCheck}
                  alt="checklist allows note"
                  width={16}
                  height={16}
                  style={{ pointerEvents: 'all' }}
                />
                <p style={{ marginLeft: 6 }}>{t(TRANSLATIONS.DECIMAL).toLocaleLowerCase()}</p>
              </span>
              )}
            </div>
            <div style={{ display: 'flex', marginLeft: 'auto' }}>
              <ActionButton text="Create" onClick={handleCreateField} background="#6C63FF" />
            </div>
          </div>
        </Overlay>

        {/* RENAME */}
        <Overlay open={showRenameField} onClose={() => setShowRenameField(false)}>
          <div
            className="newfield-overlay"
          >
            <div>
              <p style={{ fontWeight: 700, fontSize: 24, color: '#2B323B' }}>{t(TRANSLATIONS.RENAME_FIELD)}</p>
              <p>{t(TRANSLATIONS.RENAME_DESCRIPTION)}</p>
            </div>
            <div className="input-field_wrapper">
              <div role="button" className="asset_type-input-container">
                <input
                  type="text"
                  className="input-style"
                  placeholder="Escribe aquí..."
                  // @ts-ignore
                  defaultValue={listItems[openedFieldIndex]?.title}
                  onChange={e => setNewFieldName(e.target.value)}
                />
              </div>
            </div>
            <div style={{ display: 'flex', marginLeft: 'auto', gap: '10px' }}>
              <ActionButton
                text="Cancel"
                background="#FFFFFF"
                style={{ color: '#000000', border: '1px solid #D6D6D6', fontWeight: 400 }}
                onClick={() => setShowRenameField(false)}
              />
              <ActionButton onClick={handleRename} text="Rename" background="#6C63FF" />
            </div>
          </div>
        </Overlay>

        {/* DELETE */}
        <Overlay open={showDeleteField} onClose={() => setShowDeleteField(false)}>
          <div
            className="newfield-overlay"
          >
            <div>
              <p style={{ fontWeight: 700, fontSize: 24, color: '#2B323B' }}>{t(TRANSLATIONS.DELETE_FIELD)}</p>
              <p>{t(TRANSLATIONS.DELETE_FIELD_DESCRIPTION)}</p>
            </div>
            <div style={{ display: 'flex', marginLeft: 'auto', gap: '10px', marginTop: '20px' }}>
              <ActionButton
                text="Cancel"
                background="#FFFFFF"
                style={{ color: '#000000', border: '1px solid #D6D6D6', fontWeight: 400 }}
                onClick={() => setShowDeleteField(false)}
              />
              <ActionButton onClick={handleDelete} text="Delete permanently" background="#E92B36" />
            </div>
          </div>
        </Overlay>
      </div>
    </div>
  );
};

export default AssetType;
