import React from 'react';
import {
  Action_Log_Origin,
  Action_Log_Status,
  BaseUser,
  Kpi,
} from 'generated/graphql';
import { Draggable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import getAvatarAlternateUI from 'utils/avatarAlternateUi';
import { getTranslatedDateTimeValue } from 'utils/dateHelpers';
import ImageLinks from 'utils/ImageLinks';
import { differenceInDays } from 'date-fns';
import {
  actionLogOriginText,
  getParticipantIncludingOwner,
  participantNumber,
} from '../actionLogshelper';
import './ActionLogCardStyle.scss';
import { Responsible } from '../createEditActionLog/createEditActionLogModal.state';
import { isEspectro } from '../../../constants';

type Props = {
  id: string;
  pillar: string;
  owner: BaseUser;
  description: string;
  origin: Action_Log_Origin;
  originText: string;
  endDate: Date;
  responsibles: Responsible[];
  evidence: number;
  onClick: (id: string, columnStatus: Action_Log_Status) => void;
  avatarColor?: string;
  index: number;
  numberOfComments?: number;
  columnStatus: Action_Log_Status;
  kpi: Kpi
};

function ActionLogCard(props: Props) {
  const {
    id,
    pillar,
    owner,
    description,
    origin,
    originText,
    endDate,
    responsibles,
    evidence,
    numberOfComments = 0,
    onClick,
    // avatarColor,
    index,
    columnStatus,
  } = props;
  const { t } = useTranslation();
  const isDueDateArriving = (
    columnStatus !== Action_Log_Status.Completed
    && differenceInDays(
      (new Date(endDate)),
      (new Date()),
    ) < (3));

  return (
    <Draggable key={id} draggableId={id} index={index}>
      {provided => (
        <div
          className={` action_log_card_container ${isDueDateArriving && !isEspectro ? 'action_log_card_container_alert_end_date' : ''}`}
          onClick={() => onClick(id, columnStatus)}
          role="presentation"
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <b className={`${isDueDateArriving ? 'alert_end_date' : ''} mb-2`}>#{id}</b>
          {!isEspectro && (
          <div className="pillar_section mb-3">
            <div className="pillar_name_container">
              <p className={`pillar_name mr-1 safety ${pillar?.toLocaleLowerCase()}`}>{pillar}</p>
            </div>
          </div>
          )}
          <p className="description mb-3">{description}</p>
          <div className="origin_end_date_section mb-3">
            <p className="origin">{actionLogOriginText(origin, originText, t)}</p>
            <div className="end_date">
              <img src={ImageLinks.calendar} alt="calendar" className={isDueDateArriving ? 'alert_end_date' : ''} />
              <p className={
                `end_date_text
                  ${isDueDateArriving ? 'alert_end_date' : ''}`
              }
              >
                {getTranslatedDateTimeValue(endDate, 'dd MMM')}
              </p>
            </div>
          </div>
          <div className="participant_images_comment_section mb-2">
            <div className="participant_image_section">
              {responsibles?.length > 0
                && getParticipantIncludingOwner(
                  responsibles, owner, 3,
                )?.map(responsible => (
                  <img
                    src={
                      responsible?.picture
                        ? responsible.picture
                        : getAvatarAlternateUI({
                          name: responsible?.name,
                          isRandomBackground: true,
                        })
                    }
                    alt="participant"
                  />
                ))}
              {responsibles?.length > participantNumber - 1 && (
                <p className="action_log_card_remaining_participant">{`+${responsibles?.length - participantNumber + 1}`} </p>
              )}
            </div>
            <div className="image_comment_section">
              {!!evidence && (
                <div className="image_count">
                  <img className="attach_image" src={ImageLinks.attachFile} alt="attach file" />
                  {evidence}
                </div>
              )}
              {
                !!numberOfComments && (
                  <img className="comment_image" src={ImageLinks.message} alt="comment" />
                )
              }
            </div>
          </div>
        </div>
      )}
    </Draggable>
  );
}
export default ActionLogCard;
