/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useEffect, useState } from 'react';
import { COLOR_CODE } from 'types/enums';
import { dataURLtoBlob } from 'utils/ImageHelper';
import ActionButton from '../Buttons/Action/Action';
import './dribble.scss';

type Props = {
  imageUrl?: any,
  saveButtonStyle?: any,
  undoButtonStyle?: any,
  onDribleSave: (data: any)=> void,
  blueprint?: string;
};
const Dribble = (props: Props) => {
  const { imageUrl, onDribleSave, blueprint } = props;
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const isDrawing = useRef(false);
  const lastX = useRef(0);
  const lastY = useRef(0);
  const [drawingHistory, setDrawingHistory] = useState<string[]>([]);

  const showImageOnCanvas = () => {
    const image = new Image();
    image.crossOrigin = 'anonymous'; // Set the crossorigin attribute
    if (imageUrl && typeof imageUrl === 'object') {
      const img = URL.createObjectURL(imageUrl);
      image.src = img;
    } else if (blueprint) {
      image.src = blueprint;
    }
    image.onload = () => {
      const canvas = canvasRef.current;
      const context = canvas?.getContext('2d');
      if (canvas && context) {
        context.drawImage(image, 0, 0, canvas.width, canvas.height);
      }
    };
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas?.getContext('2d');
    // @ts-ignore
    const isTouchDevice = 'ontouchstart' in window || navigator.msMaxTouchPoints > 0;
    const setCanvasDimensions = () => {
      if (canvas) {
        const parentDiv = canvas.parentElement;
        const maxWidth = 600;
        const maxHeight = 300;
        // @ts-ignore
        canvas.width = Math.min(maxWidth, parentDiv.clientWidth);
        // @ts-ignore
        canvas.height = Math.min(maxHeight, parentDiv.clientHeight);
        showImageOnCanvas();
      }
    };

    setCanvasDimensions();
    window.addEventListener('resize', setCanvasDimensions);
    // @ts-ignore
    const handleStart = event => {
      if (isTouchDevice) {
        event.preventDefault();
        const touch = event.touches[0];
        // @ts-ignore
        lastX.current = touch.clientX - canvas.getBoundingClientRect().left;
        // @ts-ignore
        lastY.current = touch.clientY - canvas.getBoundingClientRect().top;
      } else {
        lastX.current = event.offsetX;
        lastY.current = event.offsetY;
      }
      isDrawing.current = true;
    };
    // @ts-ignore
    const handleMove = event => {
      if (!isDrawing.current) return;
      if (!context) return;
      let currentX;
      let currentY;

      if (isTouchDevice) {
        event.preventDefault();
        const touch = event.touches[0];
        // @ts-ignore
        currentX = touch.clientX - canvas.getBoundingClientRect().left;
        // @ts-ignore
        currentY = touch.clientY - canvas.getBoundingClientRect().top;
      } else {
        currentX = event.offsetX;
        currentY = event.offsetY;
      }
      context.strokeStyle = 'red'; // Set the stroke color (you can change this)
      context.lineJoin = 'round';
      context.lineCap = 'round';
      context.lineWidth = 5; // Set the line width (you can change this)

      context.beginPath();
      context.moveTo(lastX.current, lastY.current);
      context.lineTo(currentX, currentY);
      context.stroke();
      lastX.current = currentX;
      lastY.current = currentY;
    };
    const handleEnd = () => {
      isDrawing.current = false;
      // Save the current drawing state to the history
      if (canvas && context) {
        const canvasData = canvas.toDataURL();
        setDrawingHistory(prevHistory => [...prevHistory, canvasData]);
      }
    };
    if (canvas && context) {
      if (isTouchDevice) {
        canvas.addEventListener('touchstart', handleStart);
        canvas.addEventListener('touchmove', handleMove);
        canvas.addEventListener('touchend', handleEnd);
      } else {
        canvas.addEventListener('mousedown', handleStart);
        canvas.addEventListener('mousemove', handleMove);
        canvas.addEventListener('mouseup', handleEnd);
      }
      // Cleanup event listeners on component unmount
      return () => {
        if (isTouchDevice) {
          canvas.removeEventListener('touchstart', handleStart);
          canvas.removeEventListener('touchmove', handleMove);
          canvas.removeEventListener('touchend', handleEnd);
        } else {
          canvas.removeEventListener('mousedown', handleStart);
          canvas.removeEventListener('mousemove', handleMove);
          canvas.removeEventListener('mouseup', handleEnd);
        }
        window.removeEventListener('resize', setCanvasDimensions);
      };
    }
  }, []);

  // Function to save the canvas content as a data URL in the state variable
  const handleSaveImage = async () => {
    const canvas = canvasRef.current;
    if (canvas) {
      const img = drawingHistory[drawingHistory.length - 1];
      // const image = canvas.toDataURL('image/png');
      const blobObject = dataURLtoBlob(img);
      onDribleSave(blobObject);
    }
  };

  useEffect(() => {
    if (drawingHistory.length > 0) handleSaveImage();
    else if (drawingHistory.length === 0) onDribleSave('');
  }, [drawingHistory]);

  // Function to undo the last drawing action
  const handleUndoDrawing = () => {
    const canvas = canvasRef.current;
    const context = canvas?.getContext('2d');
    if (drawingHistory.length > 1) {
      // Remove the last drawing from the history
      setDrawingHistory(prevHistory => prevHistory.slice(0, -1));
      // Redraw the canvas with the previous state
      if (canvas && context) {
        context.clearRect(0, 0, canvas.width, canvas.height);
        const image = new Image();
        image.crossOrigin = 'anonymous'; // Set the crossorigin attribute
        image.src = drawingHistory[drawingHistory.length - 2];
        image.onload = () => {
          context.drawImage(image, 0, 0, canvas.width, canvas.height);
        };
      }
    } else {
      setDrawingHistory([]);
      if (canvas && context && blueprint) {
        context.clearRect(0, 0, canvas.width, canvas.height);
        const image = new Image();
        image.crossOrigin = 'anonymous'; // Set the crossorigin attribute
        image.src = blueprint;
        image.onload = () => {
          context.drawImage(image, 0, 0, canvas.width, canvas.height);
        };
      }
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', marginTop: 10 }}>
      <canvas ref={canvasRef} style={{ width: '100%', objectFit: 'contain', maxWidth: 600 }} />
      <div style={{ display: 'flex', alignItems: 'center', marginTop: 10, gap: 15, marginBottom: 10 }}>
        <ActionButton onClick={handleUndoDrawing} background="whitesmoke" text="Undo" style={{ padding: 10, paddingLeft: 14, paddingRight: 14, color: COLOR_CODE.ESPECTRO_PRIMARY }} />
      </div>
    </div>
  );
};

export default Dribble;
