import { fromJS } from 'immutable';
import { DOWNLOADING_INCIDENTS_XLS } from '../actions/corporateDashboard.actions';

const INITIAL_STATE = fromJS({
  downloadIncidents: false,
});

// eslint-disable-next-line
const statusReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case DOWNLOADING_INCIDENTS_XLS:
      return state.set('downloadIncidents', action.status);
    default:
      return state;
  }
};

export default statusReducer;
