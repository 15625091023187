import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Header from 'components/Header';
import NavigationDrawerWithSideMenu
  from 'components/NavigationDrawer/NavigationDrawerWithVerticalMenu';
import defaultRoutes from 'utils/defaultRoutes';
import { selectUser, selectUserLocation, selectUserType } from 'store/selectors/user.selectors';
import './UserManual.scss';
import moment from 'moment';
import 'moment/locale/es';

const WorkOrders = () => {
  const userType = useSelector(selectUserType);
  const location = useSelector(selectUserLocation);
  const { t } = useTranslation();

  moment.locale('es');

  const userDetails = useSelector(selectUser);

  return (
    <div className="page-container">
      <Header />
      <NavigationDrawerWithSideMenu
        {...{
          openDrawer: true,
          routes: defaultRoutes(t, userType, location, userDetails),
        }}
      />
      <div className="um__container">
        <iframe
          title="tuts"
          src="https://scribehow.com/page-embed/Guias_del_usuario__Espectro__hNJtQlZOQdSQ3ulutPYmPg?skipIntro=true&removeLogo=true"
          width="100%"
          loading='eager'
          className="iframe_tutorial"
        />
      </div>
    </div>
  );
};

export default WorkOrders;
