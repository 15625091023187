import React from 'react';
import i18next from 'i18next';
import { format, startOfYear } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { Backdrop, Modal } from '@mui/material';
import { esES, enUS } from '@mui/x-date-pickers/locales';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import LoadingSpinner from 'components/LoadingSpinner';
import './WorkOrderValiditySelector.scss';
import { TRANSLATIONS } from 'types/enums';
import styles from './styles';
import { Box } from '@mui/system';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';

const DateLocale = i18next.language?.indexOf('en') === 0
  ? enUS.components.MuiLocalizationProvider.defaultProps.localeText
  : esES.components.MuiLocalizationProvider.defaultProps.localeText;

type Props = {
  endDate: Date;
  isOpen: boolean;
  startDate: Date;
  isEditting: boolean;
  isOpenToggle: () => void;
  isProcessingNextStepData: boolean;
  setEndDateCallback: (date: Date) => void;
  setStartDateCallback: (date: Date) => void;
};

const WorkOrderValiditySelector = ({
  isOpen,
  endDate,
  startDate,
  isEditting,
  isOpenToggle,
  setEndDateCallback,
  setStartDateCallback,
  isProcessingNextStepData,
}: Props) => {
  const { t } = useTranslation();

  const isEndDateLaterThanStartDate = endDate > startDate;

  const onCloseCallback = (_: {}, reason: 'backdropClick' | 'escapeKeyDown') => {
    if (!(reason === 'backdropClick' || reason === 'escapeKeyDown')) {
      isOpenToggle();
    }
  };

  const onChangeStartDate = (value: any) => {
    console.log({ value });
    setStartDateCallback(value!);
  };

  const onChangeEndDate = (value: any) => {
    setEndDateCallback(value!);
  };

  const areDatesSame = (() => {
    if (endDate && startDate) {
      return format(endDate!, 'dd/MM/yyyy HH:mm a') === format(startDate!, 'dd/MM/yyyy HH:mm a');
    }
    return false;
  })();

  const minDate = isEditting ? startOfYear(new Date()) : new Date();

  return (
    <Modal
      open={isOpen}
      onClose={onCloseCallback}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 1,
        onClick: () => null,
      }}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Box sx={styles.workOrderValidityModalContainer}>
        <div className="workOrder_validity_selector__overlay_head">
          <p className="workOrder_validity_selector__title">
            {t(TRANSLATIONS.PLEASE_SELECT_VALIDITY_FOR_WORK_ORDER)}
          </p>

          <div
            className="workOrder_validity_selector_date_picker_container"
          >
            <LocalizationProvider dateAdapter={AdapterDateFns} localeText={DateLocale}>
              <MobileDateTimePicker
                minutesStep={1}
                value={startDate}
                minDateTime={minDate}
                format="dd/MM/yyyy HH:mm a"
                onChange={onChangeStartDate}
                label={t(TRANSLATIONS.START_DATE)}
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns} localeText={DateLocale}>
              <MobileDateTimePicker
                value={endDate}
                onChange={onChangeEndDate}
                format="dd/MM/yyyy HH:mm a"
                label={t(TRANSLATIONS.END_DATE)}
                minutesStep={1}
                minDateTime={startDate || new Date()}
              />
            </LocalizationProvider>
          </div>

          {
            !isEndDateLaterThanStartDate && (
              <p className="workOrder_validity_selector-error-tag">
                {t(TRANSLATIONS.END_DATE_SHOULD_BE_A_LATER_DATE)}
              </p>
            )
          }

          {
            isProcessingNextStepData ? (
              <LoadingSpinner
                height={40}
                text={t(TRANSLATIONS.PROCESSING)}
                className="workOrder_validity_selector-circular-progress"
              />
            ) : (
              <button
                type="button"
                onClick={isOpenToggle}
                disabled={areDatesSame || !isEndDateLaterThanStartDate}
                className={`workOrder_validity_selector-save-button ${(areDatesSame || !isEndDateLaterThanStartDate)
                  && 'workOrder_validity_selector-disbale-btn'}`}
              >
                {t(TRANSLATIONS.SAVE)}
              </button>
            )
          }
        </div>
      </Box>
    </Modal>
  );
};

export default WorkOrderValiditySelector;
