import { COLOR_CODE } from 'types/enums';
import React, { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Overlay from 'components/Espectro/Overlay';
import EspectroSettings from 'components/Espectro/Settings';
import ActionButton from 'components/Espectro/Buttons/Action/Action';
import { ReactComponent as EspectroFullLogo } from '../../assets/icons/espectro/espectroLogo.svg';

import './Header.scss';

const Header: FC<{}> = () => {
  const [showLogoutOverlay, setShowLogoutOverlay] = useState(false);

  const navigationHook = useNavigate();

  const handleLogout = () => {
    setShowLogoutOverlay(false);
  };

  return (
    <div className="header espectro-header">
      <div className="espectro-header-flex-between" role="presentation">
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <div
            role="button"
            onClick={() => navigationHook(-1)}
            className="icon-wrapper"
          >
            <EspectroFullLogo />
          </div>
          <div className="line" />
        </div>
        <EspectroSettings />
        <Overlay
          open={showLogoutOverlay}
          onClose={() => setShowLogoutOverlay(false)}
        >
          <div
            style={{
              maxWidth: 500,
              display: 'flex',
              flexDirection: 'column',
              marginLeft: 'auto',
              marginRight: 'auto',
              backgroundColor: 'white',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: 200,
              padding: 10,
              paddingBottom: 15,
              paddingTop: 15,
            }}
          >
            <h2>Do you want to Logout?</h2>
            <div style={{ display: 'flex', gap: 20 }}>
              <ActionButton
                text="Cancel"
                onClick={() => setShowLogoutOverlay(false)}
                background="whitesmoke"
                style={{ color: COLOR_CODE.ESPECTRO_PRIMARY }}
              />
              <ActionButton
                text="Logout"
                background={COLOR_CODE.ESPECTRO_PRIMARY}
                onClick={handleLogout}
              />
            </div>
          </div>
        </Overlay>
      </div>
    </div>
  );
};

export default Header;
