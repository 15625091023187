/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { TRANSLATIONS } from 'types/enums';
import NavigationDrawerWithSideMenu from 'components/NavigationDrawer/NavigationDrawerWithVerticalMenu';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectUser, selectUserType } from 'store/selectors/user.selectors';
import './CompanyAnalytics.scss';
import { DATA_STUDIO_REPORT_ULRS } from '../../../constants';
import defaultRoutes from 'utils/defaultRoutes';
import Header from 'components/Header';


const CompanyAnalytics = () => {
  const { t } = useTranslation();
  const userData = useSelector(selectUser);

  const userCompanyInfo = userData?.get('company');

  const getDataStudioURL = () => {
    const iFrameStringUrl = DATA_STUDIO_REPORT_ULRS[userCompanyInfo.get('name')];
    return iFrameStringUrl;
  };

  const iframeUrl = getDataStudioURL();
  const userType = useSelector(selectUserType);
  const userDetails = useSelector(selectUser);

  return (
    <div className="company_analytic-container">
      <Header />
      <div className='company_analytics-sidebar-and-iframe-wrapper'>
        <NavigationDrawerWithSideMenu
          {...{
            openDrawer: true,
            routes: defaultRoutes(t, userType, location, userDetails),
          }}
        />
        <div className="company_analytics-container">
          <h2>{`${t(TRANSLATIONS.ROUTINE_COMPLIANCE_HEADING)}`}</h2>
          <div className="iframe-container">
            <iframe className="iframe" title="data studio" src={iframeUrl} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyAnalytics;
