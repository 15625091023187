/* eslint-disable no-use-before-define */
/* eslint-disable no-undef */
import React from 'react';
import { connect } from 'react-redux';
import { useNavigate, useLocation, Navigate } from 'react-router-dom';
import i18next from 'i18next';

const AuthWrapper = (props: any) => {
  const lang = i18next.language; // es or en
  const {
    user,
    isAuthenticating,
    userType,
  } = props;

  const location = useLocation();
  const navigate = useNavigate();

  const currentPath = location.pathname;

  // still authenticating
  if (isAuthenticating) {
    return <p style={{ textAlign: 'center', marginTop: '20px' }}>{lang === 'es' ? 'Cargando' : 'Loading'}...</p>;
  }
  if (userType === 'BackendEdit') {
    if (!currentPath.includes('backend-edit')) {
      navigate('/backend-edit', { replace: true });
      return null;
    }
  }

  // After redirecting to suitable AuthWrapper, TAKE THE USER TO THE ROUTE:
  if (user) return <props.component />;

  // in case of no user
  return <Navigate to="/login" replace />;
};

const mapStateToProps = (state: any) => {
  return {
    isAuthenticating: state.getIn(['user', 'isAuthenticating']),
    user: !!state.getIn(['user', 'email']),
    userType: state.getIn(['user', 'type']),
    userDetails: state.get('user'),
  };
};

export default connect(mapStateToProps)(AuthWrapper) as any;
