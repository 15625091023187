/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable prefer-destructuring */
import ActionButton from 'components/Espectro/Buttons/Action/Action';
import React, { Dispatch, useCallback, useEffect, useMemo, useState } from 'react';
import MultiValueInput from 'components/MultiValueInput';
import { useTranslation } from 'react-i18next';
import ImageLinks from 'utils/ImageLinks';
import { LocalLogicActionType, LocalLogicTriggerActionType, TRANSLATIONS } from 'types/enums';
import GroupDropDown from 'components/Dropdown/GroupDropDown';
import i18next from 'i18next';
import SelectDropdown from 'components/Dropdown/SelectDropdown';
import { Checkbox } from '@mui/material';
import { cloneDeep } from 'lodash';
import {
  Actions,
  LocalLogic,
  showIfOperators,
  LocalLogicAction,
  ChecklistSection,
  ChecklistBitTypes,
  LocalLogicTrigger,
  LocalLogicTriggerAction,
} from '../types';
import { getSectionAndItemIndex } from '../utils';
import { CALENDAR_DAYS, EMAIL_REGEX } from '../../../constants';
import { BIT_TYPE_SELECT_OPTIONS_V2, MAX_SOURCE_ITEM_TRIGGER, blackListedBitsFromLogic } from '../constants';

interface AddLogicProps {
  localLogics: { [key: number]: LocalLogic[] };
  sections: ChecklistSection[];
  globalEmailRecipients: string[];
  setMultiTriggerOptions: React.Dispatch<React.SetStateAction<showIfOperators[]>>;
  multiTriggerOptions: showIfOperators[];
  localLogicTriggers: LocalLogicTrigger[];
  setLocalLogics: Dispatch<LocalLogicAction>;
  setLocalLogicTriggers: Dispatch<LocalLogicTriggerAction>;
  setGlobalEmailRecipients: React.Dispatch<React.SetStateAction<string[]>>;
}

const AddLogic = ({
  sections,
  setLocalLogics,
  localLogics = {},
  localLogicTriggers,
  multiTriggerOptions,
  setLocalLogicTriggers,
  globalEmailRecipients,
  setMultiTriggerOptions,
  setGlobalEmailRecipients,
}: AddLogicProps) => {
  const { t } = useTranslation();

  const [selectedLogicKey, setSelectedLogicKey] = useState<number>(0);
  const [selectedLogic, setSelectedLogic] = useState<number>(
    localLogics[selectedLogicKey]?.length > 0 ? 0 : -1,
  );
  const selectedLogicObject = useCallback((index: number): LocalLogic => {
    return (localLogics?.[selectedLogicKey]?.[index] || {}) as LocalLogic;
  }, [localLogics, selectedLogicKey]);

  const [shouldSendEmail, setShouldSendEmail] = useState<boolean>(
    !!globalEmailRecipients.length,
  );

  const initializeLocalLogicsIfEmpty = useCallback(() => {
    if ((localLogics && localLogics[Number('0')]?.length === 0)
    || (localLogicTriggers && localLogicTriggers?.length === 0)) {
      setLocalLogics({ type: LocalLogicActionType.BASIC_INIT });
      setLocalLogicTriggers({ type: LocalLogicTriggerActionType.BASIC_INIT });
      setSelectedLogic(0);
      setSelectedLogicKey(0);
    }
  }, [localLogics, setLocalLogics, setLocalLogicTriggers, localLogicTriggers]);

  useEffect(() => {
    initializeLocalLogicsIfEmpty();
  }, [initializeLocalLogicsIfEmpty]);

  const TRIGGERS = [
    {
      label: t(TRANSLATIONS.SHOW_QUESTION),
      value: Actions.SHOW_ANSWER,
    },
    {
      label: t(TRANSLATIONS.SEND_EMAIL),
      value: Actions.SEND_EMAIL,
    },
    {
      label: t(TRANSLATIONS.TRIGGER_ACTION_LOG),
      value: Actions.TRIGGER_ACTION_LOG,
    },
  ];

  const onAddLocalLogic = () => {
    const logicKeys = Object.keys(localLogics);
    setLocalLogics({
      type: LocalLogicActionType.ADD_NEW_LOGIC,
      payload: {
        localLogicMapKey: Number(logicKeys[logicKeys.length - 1]) + 1,
      },
    });
    setLocalLogicTriggers({ type: LocalLogicTriggerActionType.ADD_NEW_LOGIC_TRIGGER });
  };

  const getChooseQuestionOptions = useCallback(({ shouldNotIncludeIfQuestion = false }) => {
    const options: {
      groupIndex: number,
      groupTitle: string;
      subOptions: {
        optionId: number;
        value: string;
        label: string;
      }[]
    }[] = [];

    sections.forEach((section, sectionIndex) => {
      if (options.filter(option => option.groupIndex !== sectionIndex)) {
        options.push({
          groupIndex: sectionIndex,
          groupTitle: `${t(TRANSLATIONS.SECTION)} ${sectionIndex + 1}: ${section.title}`,
          subOptions: [],
        });
      }
      section.items.forEach((item, itemIndex) => {
        /**
         * this check will skip the addition of question which is already selected
         * as the trigger source
         * In order to run this will require the shouldNotIncludeIfQuestion to be true
         */
        const questionsAdded = localLogics[selectedLogicKey];
        const indexes = questionsAdded.map(q => ({ si: q.sectionIndex, ii: q.itemIndex }));
        const hasSameQuestion = indexes
          .some(idx => idx.si === sectionIndex && idx.ii === itemIndex);
        const isAutoHideEnabled = item.bits[0].props?.hideQuestionUntilSectionAnswered;
        if ((shouldNotIncludeIfQuestion && hasSameQuestion)
          || (shouldNotIncludeIfQuestion && isAutoHideEnabled)) {
          return;
        }

        const subOptions = options.find(op => op.groupIndex === sectionIndex)?.subOptions;
        const bitType = item.bits[0].type;
        if (subOptions && bitType && !blackListedBitsFromLogic?.includes(bitType)) {
          subOptions.push({
            optionId: itemIndex,
            label: `${t(TRANSLATIONS.ANSWER)} ${itemIndex + 1}: ${item.title}`,
            value: `$checklist[${sectionIndex}].items[${itemIndex}].bits[0]`,
          });
        }
      });
    });

    console.log({ options });
    return options;
  }, [localLogics, sections, selectedLogicKey, t]);

  const selectedLogicTrigger = useMemo(() => localLogicTriggers?.[selectedLogicKey] ?? {},
    [selectedLogicKey, localLogicTriggers]);

  const onChangeLogicQuestion = ({
    targetSectionIndex,
    targetItemIndex,
    index,
  }: { targetSectionIndex: number; targetItemIndex: number; index: number; }) => {
    setLocalLogics({
      type: LocalLogicActionType.UPDATE_LOGIC_QUESTION,
      payload: {
        logicIndex: index,
        itemIndex: targetItemIndex,
        sectionIndex: targetSectionIndex,
        localLogicMapKey: selectedLogicKey,
      },
    });
    setSelectedLogic(index);
  };

  const onChangeLogicOperator = (operator: showIfOperators, index: number) => {
    setLocalLogics({
      type: LocalLogicActionType.UPDATE_LOGIC_OPERATOR,
      payload: {
        operator,
        logicIndex: index,
        localLogicMapKey: selectedLogicKey,
      },
    });
    setSelectedLogic(index);
  };

  const onChangeTrigger = useCallback((trigger: Actions, triggerIndex: number) => {
    console.log({
      trigger,
      triggerIndex,
      logicIndex: Number(selectedLogicKey),
      itemIndex: localLogics[selectedLogicKey][0].itemIndex,
      sectionIndex: localLogics[selectedLogicKey][0].sectionIndex,
    });
    setLocalLogicTriggers({
      type: LocalLogicTriggerActionType.UPDATE_TRIGGER,
      payload: {
        trigger,
        triggerIndex,
        logicIndex: Number(selectedLogicKey),
        itemIndex: localLogics[selectedLogicKey][0].itemIndex,
        sectionIndex: localLogics[selectedLogicKey][0].sectionIndex,
      },
    });
  }, [localLogics, selectedLogicKey, setLocalLogicTriggers]);

  const onChangeTriggerQuestion = ({
    targetItemIndex,
    targetSectionIndex,
  }: { targetSectionIndex:number;
    targetItemIndex: number;
    triggerIndex: number }) => {
    setLocalLogicTriggers({
      type: LocalLogicTriggerActionType.ADD_TRIGGER_QUESTION,
      payload: {
        logicIndex: selectedLogicKey,
        itemIndex: targetItemIndex,
        sectionIndex: targetSectionIndex,
      },
    });
  };

  const userLanguage = (i18next.language).split('-')[0] as 'en' | 'es';

  const renderShowIfPossibleAnswer = (index: number) => {
    let targetBit;
    if (index >= 0
      && selectedLogicObject(index).sectionIndex !== -1
      && selectedLogicObject(index).itemIndex !== -1) {
      targetBit = sections[
        selectedLogicObject(index).sectionIndex
      ].items[selectedLogicObject(index).itemIndex].bits[0];
    }

    const targetsList = targetBit?.props.options?.map((o, i) => {
      return {
        value: i,
        label: o.title,
      };
    }) ?? [];

    const onAddLogicSelect = (e: any) => {
      const value = targetsList.find(
        o => o.value === e.target.value,
      )?.value;
      setSelectedLogic(index);

      setLocalLogics({
        type: LocalLogicActionType.ADD_LOGIC_SELECT,
        payload: {
          value,
          logicIndex: index,
          localLogicMapKey: selectedLogicKey,
        },
      });
    };

    const onAddLogicSelectForLabelBits = (e: any) => {
      setSelectedLogic(index);
      const label = targetsList.find(
        o => o.value === e.target.value,
      )?.label;
      setLocalLogics({
        type: LocalLogicActionType.ADD_LOGIC_SELECT,
        payload: {
          value: label,
          logicIndex: index,
          localLogicMapKey: selectedLogicKey,
        },
      });
    };

    const onAddLogicValue = (e: any) => {
      setSelectedLogic(index);
      setLocalLogics({
        type: LocalLogicActionType.ADD_LOGIC_SELECT,
        payload: {
          value: e.target.value,
          logicIndex: index,
          localLogicMapKey: selectedLogicKey,
        },
      });
    };

    switch (targetBit?.type) {
      case ChecklistBitTypes.DROPDOWN:
        return (
          <SelectDropdown
            name="Operación"
            bgColor="transparent"
            dropdownWrapperClassName="checklist-builder_w-100 dropdown box-shadow-none"
            value={
              targetsList.find(
                o => o.label === selectedLogicObject(index)?.value,
              )?.value as number
            }
            onChange={onAddLogicSelectForLabelBits}
            placeholder="Select value"
            dropdownContent={targetsList}
          />
        );
      case ChecklistBitTypes.DATE: {
        const calendarDays = CALENDAR_DAYS[userLanguage];

        const onSelectDayForCondition = (e: any) => {
          setSelectedLogic(index);
          const value = calendarDays.find(
            o => o.value === e.target.value,
          )?.value;
          setLocalLogics({
            type: LocalLogicActionType.ADD_LOGIC_SELECT,
            payload: {
              value,
              logicIndex: index,
              localLogicMapKey: selectedLogicKey,
            },
          });
        };

        const selectedValueFromCalendarDaysDropdown = calendarDays.find(
          o => o.value === selectedLogicObject(index)?.value,
        )?.value as number;

        return (
          <SelectDropdown
            name="Operación"
            bgColor="transparent"
            dropdownWrapperClassName="checklist-builder_w-100 dropdown box-shadow-none"
            value={selectedValueFromCalendarDaysDropdown}
            onChange={onSelectDayForCondition}
            placeholder="Select value"
            dropdownContent={calendarDays}
          />
        );
      }
      case ChecklistBitTypes.VERTICAL_SINGLE_OPTION:
        return (
          <SelectDropdown
            name="Operación"
            bgColor="transparent"
            dropdownWrapperClassName="checklist-builder_w-100 dropdown box-shadow-none"
            value={
              targetsList.find(
                o => o.value === selectedLogicObject(index)?.value,
              )?.value as number
            }
            onChange={onAddLogicSelect}
            placeholder="Select value"
            dropdownContent={targetsList}
          />
        );
      case ChecklistBitTypes.HORIZONTAL_SINGLE_OPTION:
        return (
          <SelectDropdown
            name="Operación"
            bgColor="transparent"
            dropdownWrapperClassName="checklist-builder_w-100 dropdown box-shadow-none"
            value={
              targetsList.find(
                o => o.value === selectedLogicObject(index)?.value,
              )?.value as number
            }
            onChange={onAddLogicSelect}
            placeholder="Select value"
            dropdownContent={targetsList}
          />
        );
      default:
        return (
          <input
            type="text"
            className="add_answers-checklist-bit-input-option"
            placeholder="Escribe"
            value={selectedLogicObject(index)?.value}
            onChange={onAddLogicValue}
          />
        );
    }
  };

  const deleteLogic = (id: number) => {
    if (id >= 0) {
      setLocalLogics({
        payload: { localLogicMapKey: id },
        type: LocalLogicActionType.REMOVE_LOGIC,
      });
      setLocalLogicTriggers({
        payload: { logicIndexToDelete: id },
        type: LocalLogicTriggerActionType.REMOVE_TRIGGER,
      });
      setSelectedLogic(0);
      // setSelectedLogicKey(Number(Object.keys(localLogics)[0]));
    }
  };

  const handleEnterPress = useCallback((
    recipients: string[],
    handleEventForGlobalRecipients = false,
  ) => {
    if (handleEventForGlobalRecipients) {
      setGlobalEmailRecipients([...recipients]);
    } else {
      setLocalLogicTriggers({
        type: LocalLogicTriggerActionType.ADD_RECIPIENTS,
        payload: {
          recipient: [...recipients],
          logicIndex: selectedLogicKey,
        },
      });
    }
  }, [selectedLogicKey, setLocalLogicTriggers, setGlobalEmailRecipients]);

  const renderTriggerComponents = useCallback(() => {
    // setSelectedLogic(index);
    return (
      <MultiValueInput
        onAddItem={handleEnterPress}
        validationRegEx={EMAIL_REGEX}
        errorMessage="Invalid email!"
        onDeleteItem={handleEnterPress}
        items={(selectedLogicTrigger as any).recipient}
        placeholder={t(TRANSLATIONS.ENTER_OPTION_HIT_ENTER)}
      />
    );
  }, [selectedLogicTrigger, handleEnterPress, t]);

  const getOperators = (idx: number) => {
    console.log({ selectedLogicObject: selectedLogicObject(idx) });
    const s = selectedLogicObject(idx);
    const index = BIT_TYPE_SELECT_OPTIONS_V2.findIndex(o => o.value
      === sections[s.sectionIndex]
        ?.items[s.itemIndex]?.bits[0]?.type);
    return index !== -1 ? BIT_TYPE_SELECT_OPTIONS_V2[index]?.OPERATORS : [];
  };

  const isCurrentLocalLogicSourceItemTriggersOverLimit = localLogics?.[selectedLogicKey]?.length
    === MAX_SOURCE_ITEM_TRIGGER;

  const pushNewSourceItemTrigger = () => {
    if (!isCurrentLocalLogicSourceItemTriggersOverLimit) {
      setLocalLogics({
        payload: { localLogicMapKey: selectedLogicKey },
        type: LocalLogicActionType.ADD_MULTIPLE_SOURCE_ITEM_TRIGGER,
      });
    }
  };

  const deleteSourceItemTrigger = (index: number) => {
    if (localLogics[selectedLogicKey].length > 1) {
      setLocalLogics({
        payload: {
          localLogicMapKey: selectedLogicKey,
          localLogicIndex: index,
        },
        type: LocalLogicActionType.DELETE_SOURCE_ITEM_TRIGGER,
      });
    }
  };

  const getTriggerSourceItemSelectors = (index: number) => {
    return (
      <div className="add_logic-source-item-wrapper">
        <div style={{ width: '8%' }}>
          {
            index === 1 ? (
              <SelectDropdown
                name="Operación"
                bgColor="transparent"
                value={multiTriggerOptions[selectedLogicKey]}
                onChange={(e: any) => {
                  const optionsCopy = cloneDeep(multiTriggerOptions);
                  optionsCopy[selectedLogicKey] = e.target.value;
                  setMultiTriggerOptions([...optionsCopy]);
                }}
                placeholder="Select value"
                dropdownWrapperClassName="select-and-or"
                dropdownContent={[
                  {
                    value: showIfOperators.or,
                    label: 'or if'.toLocaleLowerCase(),
                  },
                  {
                    value: showIfOperators.and,
                    label: 'and if'.toLocaleLowerCase(),
                  },
                ]}
              />
            ) : (
              index > 0 ? null : <p>{t(TRANSLATIONS.SHOW_IF)}</p>
            )
          }
        </div>
        <div style={{ width: '30%', marginRight: '2%' }}>
          <GroupDropDown
            label={t(TRANSLATIONS.CHOOSE_QUESTION)}
            value={`$checklist[${selectedLogicObject(index).sectionIndex}].items[${selectedLogicObject(index).itemIndex}].bits[0]`}
            options={getChooseQuestionOptions({}) || []}
            onChange={selectedValue => {
              console.log({ selectedValue });
              if (selectedValue !== undefined) {
                onChangeLogicQuestion({
                  targetSectionIndex: getSectionAndItemIndex(
                    selectedValue,
                  ).sectionIndex,
                  targetItemIndex: getSectionAndItemIndex(
                    selectedValue,
                  ).itemIndex,
                  index,
                });
              }
            }}
          />
        </div>
        <div style={{ width: '28%', marginRight: '2%' }}>
          <SelectDropdown
            name="Operación"
            bgColor="transparent"
            placeholder="Select trigger type"
            dropdownWrapperClassName="add_answers-checklist-builder_select-dropdown bg-white"
            value={localLogics[selectedLogicKey][index].operation as string}
            onChange={e => {
              onChangeLogicOperator(e.target.value, index);
            }}
            dropdownContent={getOperators(index) as any}
          />
        </div>
        <div style={{ width: '30%' }}>
          {renderShowIfPossibleAnswer(index)}
        </div>
        <div className="checklist_add_logic-question-actions-wrapper">
          <img
            src={ImageLinks.espectro.plusWhite}
            alt="duplicate question"
            width={24}
            height={24}
            style={{
              cursor: isCurrentLocalLogicSourceItemTriggersOverLimit ? 'not-allowed' : 'pointer',
              opacity: isCurrentLocalLogicSourceItemTriggersOverLimit ? 0.3 : 1,
            }}
            onClick={pushNewSourceItemTrigger}
          />
          <img
            src={ImageLinks.espectro.delete}
            alt="Delete question"
            width={38}
            height={38}
            style={{
              marginLeft: 8,
              cursor: localLogics[selectedLogicKey].length === 1 ? 'not-allowed' : 'pointer',
              opacity: localLogics[selectedLogicKey].length === 1 ? 0.3 : 1,
            }}
            onClick={deleteSourceItemTrigger.bind(null, index)}
          />
        </div>
      </div>
    );
  };

  const getTriggerAffectedItemSelectors = () => {
    return (
      <div className="add_logic-affeted-item-wrapper mt-4">
        <div style={{ width: '6%' }}>
          <p>{t(TRANSLATIONS.THEN)}</p>
        </div>
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
          }}
        >
          <div style={{ width: '30%', marginRight: '2%' }}>
            <SelectDropdown
              name="Trigger"
              bgColor="transparent"
              placeholder="Select"
              dropdownWrapperClassName="add_answers-checklist-builder_select-dropdown"
              value={(selectedLogicTrigger as any).trigger as Actions}
              onChange={e => {
                onChangeTrigger(e.target.value, 0);
              }}
              dropdownContent={TRIGGERS}
            />
          </div>
          {(selectedLogicTrigger as any).trigger === Actions.SHOW_ANSWER && (
            <div style={{ width: '30%', marginRight: '2%' }}>
              <GroupDropDown
                label={t(TRANSLATIONS.CHOOSE_QUESTION)}
                value={`$checklist[${(selectedLogicTrigger as any).sectionIndex}].items[${(selectedLogicTrigger as any).itemIndex}].bits[0]`}
                options={getChooseQuestionOptions({ shouldNotIncludeIfQuestion: true }) || []}
                onChange={selectedValue => {
                  if (selectedValue !== undefined) {
                    onChangeTriggerQuestion({
                      targetSectionIndex: getSectionAndItemIndex(
                        selectedValue,
                      ).sectionIndex,
                      targetItemIndex: getSectionAndItemIndex(
                        selectedValue,
                      ).itemIndex,
                      triggerIndex: 0,
                    });
                  }
                }}
              />
            </div>
          )}
          {(selectedLogicTrigger as any).trigger === Actions.SEND_EMAIL && (
            <div style={{ width: '30%', marginTop: -2 }}>
              {renderTriggerComponents()}
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="checklist-builder__add-logic-container">
      {/* side bar */}
      <div className="checklist-builder__add-logic-bar-container">
        <div className="checklist-builder__add-logic-bar">
          <h1 style={{ fontWeight: 700, fontSize: '24px' }}>{t(TRANSLATIONS.LOGIC)}</h1>
          <ActionButton
            onClick={onAddLocalLogic}
            text={`+ ${t(TRANSLATIONS.ADD_LOGIC)}`}
            background="#FFFFFF"
            style={{ color: '#000000', border: '1px solid #D6D6D6', fontWeight: 400 }}
          />
        </div>
        {Object.keys(localLogics).map((logic: string, index: number) => (
          <div key={localLogics[Number(logic)][0].id} className={selectedLogicKey === Number(logic) ? 'checklist-builder__logic-title-active' : 'checklist-builder__logic-title'}>
            <div
              role="button"
              onClick={() => {
                setSelectedLogic(0);
                setSelectedLogicKey(Number(logic));
              }}
            >
              {index + 1}. {t(TRANSLATIONS.LOGIC)} {Number(logic) + 1}
            </div>
            {
              Object.keys(localLogics).length !== 1 && (
                <img
                  alt="delete logic"
                  onClick={deleteLogic.bind(null, Number(logic))}
                  height={24}
                  width={24}
                  src={ImageLinks.espectro.delete}
                  style={{ marginLeft: 8 }}
                />
              )
            }
          </div>
        ))}
      </div>
      {/* main container */}
      <div
        style={{
          width: '70%',
          height: '100%',
          paddingLeft: 24,
          paddingRight: 24,
          paddingTop: 12,
          paddingBottom: 12,
        }}
      >
        {
          selectedLogicKey < 0 || selectedLogicKey === null ? (
            <p>Please select a logic</p>
          ) : (
            <>
              <h1 style={{ fontWeight: 700, fontSize: '24px', marginBottom: 12 }}>{`${t(TRANSLATIONS.LOGIC)} ${Number(selectedLogicKey) + 1}`}</h1>
              <div className="add_logic-triggers-wrapper">
                <div className="add_logic-source-item-triggers-wrapper">
                  {localLogics[selectedLogicKey]?.map((l, i) => getTriggerSourceItemSelectors(i))}
                </div>
                {getTriggerAffectedItemSelectors()}
              </div>

              <div style={{ width: '90%' }}>
                <h1 style={{ fontWeight: 700, fontSize: '24px', marginTop: 80 }}>{t(TRANSLATIONS.OTHERS)}</h1>
                <div className="radio-row">
                  <Checkbox
                    onClick={() => {
                      setShouldSendEmail(prev => !prev);
                    }}
                    checked={shouldSendEmail}
                  />

                  <h2 style={{ fontWeight: 400, fontSize: '16px', margin: 0 }}>{t(TRANSLATIONS.SEND_EMAIL_WHEN_FINISHED)}</h2>
                </div>
                <MultiValueInput
                  items={globalEmailRecipients}
                  validationRegEx={EMAIL_REGEX}
                  errorMessage="Invalid email!"
                  placeholder={t(TRANSLATIONS.ENTER_OPTION_HIT_ENTER)}
                  onAddItem={(val: string[]) => handleEnterPress(val, true)}
                  onDeleteItem={(val: string[]) => handleEnterPress(val, true)}
                />
              </div>
            </>
          )
        }
      </div>
    </div>
  );
};

export default AddLogic;
