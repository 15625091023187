import React, { useEffect, useRef, useState } from 'react';
import { withStyles, createStyles, makeStyles } from '@mui/styles';
import './WorkOrdersTable.styles.scss';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ArrowUpwardIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDropDown';
import { WorkOrdersTabDataType } from 'features/Espectro/types';
import { DEFAULT_WORK_ORDERS_RECORDS_LIMIT_PER_PAGE } from 'features/Espectro/constants';
import { useTranslation } from 'react-i18next';
import { TRANSLATIONS } from 'types/enums';

type RowData = {
  rowData: any[];
  index: string;
};

type CustomTableProps = {
  header: string[];
  onClickRow: (id: string) => void;
  rows: RowData[];
  sortableColumns?: number[]; // Array of column indices that are sortable
  fetchQuery: (newPage: number) => void;
  data: WorkOrdersTabDataType;
};

const StyledTableCell = withStyles(() => createStyles({
  head: {
    backgroundColor: '#fff',
    color: '#404040',
    cursor: 'pointer',
    padding: '4px',
    fontFamily: 'Jost',
    border: 'none',
  },
  body: {
    fontSize: 14,
    padding: '4px',
    fontFamily: 'Jost',
  },
}))(TableCell);

const StyledTableCellHeader = withStyles(() => createStyles({
  head: {
    backgroundColor: '#fff',
    color: '#404040',
    padding: '4px',
    border: 'none',
  },
  body: {
    fontSize: 17,
    padding: '4px',
  },
}))(TableCell);

const StyledTableRow = withStyles(() => createStyles({
  root: {
    '&:hover': {
      backgroundColor: '#FAFAFA',
      cursor: 'pointer',
    },
    height: '40px', // Set a strict height for each row
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    overflowY: 'scroll',
    '-ms-overflow-style': 'none',
    'scrollbar-width': 'none',
  },
  tableContainer: {
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  arrowIcon: {
    fontSize: '2em',
    marginLeft: '0.2em',
  },
  cell: {
    flexDirection: 'row',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '200px',
  },
});

const CustomTable: React.FC<CustomTableProps> = (
  { header, rows, onClickRow, fetchQuery, data: workOrderRecords, sortableColumns = [] },
) => {
  const { t } = useTranslation();
  const observer = useRef<IntersectionObserver>();
  const lastElementRef = useRef<HTMLTableRowElement | null>(null);

  const classes = useStyles();
  const [sortConfig, setSortConfig] = useState<{ key: string, direction: 'ascending' | 'descending' | null }>({ key: '', direction: 'ascending' });

  const scrollToLoaderElement = () => {
    const element = document.getElementById('workOrders_table-spinner-container');
    element!.scrollTop = element!.scrollHeight;
  };

  const isTotalRecordsLowerThanPageLimit = workOrderRecords.data.length
    < DEFAULT_WORK_ORDERS_RECORDS_LIMIT_PER_PAGE;

  useEffect(() => {
    if (workOrderRecords.loading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        if (!workOrderRecords.message.length
          && !isTotalRecordsLowerThanPageLimit) {
          fetchQuery(workOrderRecords.page + 1);
          scrollToLoaderElement();
        }
      }
    });

    if (lastElementRef.current) {
      observer.current.observe(lastElementRef?.current as unknown as Element);
    }
  }, [fetchQuery, workOrderRecords, isTotalRecordsLowerThanPageLimit]);

  const sortedRows = [...rows].sort((a, b) => {
    if (!sortConfig.key || sortConfig.direction === null) {
      return 0;
    }
    const columnIdx = Number(sortConfig.key);
    if (sortConfig.direction === 'ascending') {
      if (a.rowData[columnIdx] < b.rowData[columnIdx]) return -1;
      if (a.rowData[columnIdx] > b.rowData[columnIdx]) return 1;
      return 0;
    }
    if (sortConfig.direction === 'descending') {
      if (a.rowData[columnIdx] > b.rowData[columnIdx]) return -1;
      if (a.rowData[columnIdx] < b.rowData[columnIdx]) return 1;
      return 0;
    }
    return 0;
  });

  const requestSort = (key: string) => {
    let direction: 'ascending' | 'descending' | null = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const renderHeaderCells = () => {
    return header.map((headerLabel, index) => {
      const isSortable = sortableColumns.includes(index);
      const isSortingColumn = sortConfig.key === String(index);
      const arrowIcon = () => {
        if (isSortable && isSortingColumn) {
          return sortConfig.direction === 'ascending' ? <ArrowUpwardIcon className={classes.arrowIcon} /> : <ArrowDownwardIcon className={classes.arrowIcon} />;
        }
        return null;
      };
      return (
        <StyledTableCellHeader
          key={headerLabel}
          onClick={() => {
            if (sortableColumns.includes(index)) {
              requestSort(String(index));
            }
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              paddingTop: '8px',
              paddingBottom: '8px',
              fontSize: 16,
              fontWeight: 700,
              fontFamily: 'Jost',
            }}
          >
            {headerLabel.toLocaleUpperCase()}
            {arrowIcon()}
          </div>
        </StyledTableCellHeader>
      );
    });
  };

  const footerMessage = isTotalRecordsLowerThanPageLimit
    ? t(TRANSLATIONS.NO_MORE_RECORDS) : workOrderRecords.message;

  return (
    <TableContainer
      id="workOrders_table-spinner-container"
      className={classes.tableContainer}
    >
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            {renderHeaderCells()}
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedRows.map((row, rowIndex) => (
            <StyledTableRow
              key={`${row.index}`}
              ref={rowIndex === sortedRows.length - 1 ? lastElementRef : null}
              onClick={() => onClickRow(row.index)}
            >
              {row.rowData.map((cellLabel, index) => (
                <StyledTableCell key={`${row.index}-${index}`} className={classes.cell}>
                  {cellLabel}
                </StyledTableCell>
              ))}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
      {
        workOrderRecords.loading && (
          <div
            className="workOrders_table-spinner-container"
          >
            <div className="workOrders_table-loading-spinner" />
            {t(TRANSLATIONS.LOADING)}...
          </div>
        )
      }
      {
        (workOrderRecords.message
          || isTotalRecordsLowerThanPageLimit) && (
          <p className="workOrders_table-data-message">{footerMessage}</p>
        )
      }
    </TableContainer>
  );
};

export default CustomTable;
