import React, { CSSProperties, ImgHTMLAttributes } from 'react';

import './ButtonWithIcon.scss';

export type ButtonWithIconProps = {
  text: string;
  onClick?(e?: any): void;
  className?: string;
  background?: string;
  iconClassName?: string;
  disabled?: boolean;
  style?: CSSProperties;
  iconSource: ImgHTMLAttributes<HTMLImageElement>['src'];
};

// NOTE: Default background color is #2574FB

const ButtonWithIcon = ({
  text,
  onClick,
  className = '',
  iconClassName = '',
  background,
  disabled,
  iconSource,
  style,
}: ButtonWithIconProps) => (
  <button
    type="button"
    className={`button-with-icon ${className}`}
    onClick={onClick}
    tabIndex={0}
    style={{
      backgroundColor: background,
      ...(disabled ? { opacity: 0.3 } : {}),
      outline: 'none',
      ...style,
    }}
    disabled={disabled}
  >
    <img
      src={iconSource}
      className={`button-with-img-icon ${iconClassName}`}
    />
    {text}
  </button>
);

export default ButtonWithIcon;
