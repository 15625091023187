import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import MonthlyTimeFilter from 'components/MonthlyTimeFilter';
import LoadingSpinner from 'components/LoadingSpinner';
import Card from 'components/Cards/LocationCard';
import Disclaimer from 'components/Disclaimer/Disclaimer';
import { fromJS } from 'immutable';
import { selectCurrentAccesLevel } from 'store/selectors/distributionCenters.selectors';
import { selectSelectedLocationsSlug } from 'store/selectors/globalFilters.selectors';
import { FILTER_ACCESS_LEVELS, TRANSLATIONS } from 'types/enums';
import { selectUserType } from 'store/selectors/user.selectors';

import { canUserDownload } from 'utils/canUserAccessFeature';
import downloadIconBlack from 'assets/icons/download-black.svg';
import { useActionLogsCountGroupByLocationQueryQuery } from 'generated/graphql';
import { selectTimeFilter } from 'store/selectors/timeFilter.selector';
import '../style.scss';
import { NameAndSlug } from 'types/interfaces';
import {
  setBusinessUnit,
  setCluster,
  setLocation,
  setSubzone,
  setZone,
} from 'store/actions/globalFilters.actions';
import { filterLocationsByCluster, groupLocationsByCluster } from 'utils/arrayHelpers';
import { downloadActionLogsReport } from '../../actionLogshelper';

type Props = {
  handleMonthChange: Function;
  currentMonth: string;
};

const ExcelDownloadBoard: React.FC<Props> = ({ currentMonth, handleMonthChange }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userType = useSelector(selectUserType);

  const [arrayOfLocationToShow, setArrayOfLocationToShow] = useState<any>(null);
  const selectedLocations = useSelector(selectSelectedLocationsSlug);

  const timeFilter = useSelector(selectTimeFilter);
  const accessLevel = useSelector(selectCurrentAccesLevel);

  const { loading, data } = useActionLogsCountGroupByLocationQueryQuery({
    variables: {
      startTime: timeFilter.get('startTime'),
      endTime: timeFilter.get('endTime'),
    },
  });

  useEffect(() => {
    let arrOfLocations = null;
    console.log({ dadas: data?.actionLogsCountGroupByLocation });
    if (data && data.actionLogsCountGroupByLocation) {
      const countLogs = fromJS(data.actionLogsCountGroupByLocation);
      const businessUnitIndex = countLogs.findIndex(
        (bu: any) => bu.get('slug') === selectedLocations.businessUnit,
      );
      const zoneIndex = businessUnitIndex !== -1
        && countLogs
          .getIn([businessUnitIndex, 'zones'])
          .findIndex((zone: any) => zone.get('slug') === selectedLocations.zone);
      const subzoneIndex = zoneIndex !== -1
        && zoneIndex !== false
        && countLogs
          .getIn([businessUnitIndex, 'zones', zoneIndex, 'subzones'])
          .findIndex((subzone: any) => subzone.get('slug') === selectedLocations.subzone);

      if (selectedLocations.cluster) {
        arrOfLocations = countLogs.getIn([
          businessUnitIndex,
          'zones',
          zoneIndex,
          'subzones',
          subzoneIndex,
          'locations',
        ]);
        const list = arrOfLocations
          ? filterLocationsByCluster(arrOfLocations.toJS(), selectedLocations.cluster) : [];
        setArrayOfLocationToShow(fromJS(list) || countLogs);
        return;
      }
      if (selectedLocations.subzone) {
        arrOfLocations = countLogs.getIn([
          businessUnitIndex,
          'zones',
          zoneIndex,
          'subzones',
          subzoneIndex,
          'locations',
        ]);
        const { clusterExists, clusters }: any = arrOfLocations
          ? groupLocationsByCluster(arrOfLocations.toJS()) : {};
        console.log({ clusterExists, clusters });
        const list = clusterExists ? clusters : arrOfLocations;
        setArrayOfLocationToShow(fromJS(list) || countLogs);
        return;
      }

      if (selectedLocations.zone) {
        arrOfLocations = countLogs.getIn([businessUnitIndex, 'zones', zoneIndex, 'subzones']);
        setArrayOfLocationToShow(arrOfLocations || countLogs);
        return;
      }

      if (selectedLocations.businessUnit) {
        arrOfLocations = countLogs.getIn([businessUnitIndex, 'zones']);
        setArrayOfLocationToShow(arrOfLocations || countLogs);
        return;
      }

      setArrayOfLocationToShow(arrOfLocations || countLogs);
    }
  }, [data, selectedLocations]);

  const handleDownloadOptionChange = () => {
    if (accessLevel === FILTER_ACCESS_LEVELS.DISTRIBUTION_CENTER) {
      return downloadActionLogsReport(
        FILTER_ACCESS_LEVELS.SUBZONE,
        timeFilter,
        selectedLocations.subzone,
      );
    }
    if (accessLevel === FILTER_ACCESS_LEVELS.SUBZONE) {
      return downloadActionLogsReport(
        FILTER_ACCESS_LEVELS.ZONE,
        timeFilter,
        selectedLocations.zone,
      );
    }
    if (accessLevel === FILTER_ACCESS_LEVELS.ZONE) {
      return downloadActionLogsReport(
        FILTER_ACCESS_LEVELS.BUSINESS_UNIT,
        timeFilter,
        selectedLocations.businessUnit,
      );
    }
    downloadActionLogsReport(accessLevel, timeFilter);
  };

  const handleCardClick = ({ name, slug }: NameAndSlug) => {
    console.log('Clicked', name, slug, accessLevel);
    switch (accessLevel) {
      case FILTER_ACCESS_LEVELS.CLUSTER:
        dispatch(setCluster({ name, slug }));
        break;
      case FILTER_ACCESS_LEVELS.DISTRIBUTION_CENTER:
        dispatch(setLocation({ name, slug }));
        break;
      case FILTER_ACCESS_LEVELS.SUBZONE:
        dispatch(setSubzone({ name, slug }));
        break;
      case FILTER_ACCESS_LEVELS.ZONE:
        dispatch(setZone({ name, slug }));
        break;
      case FILTER_ACCESS_LEVELS.BUSINESS_UNIT:
        dispatch(setBusinessUnit({ name, slug }));
        break;
      default:
        break;
    }
  };

  return (
    <>
      {arrayOfLocationToShow ? (
        <div className="distribution-centers">
          <div>
            <div className="distribution-centers__top-menu">
              <div className="distribution-centers__top-menu__buttons">
                {canUserDownload(userType) && (
                <button
                  type="button"
                  className="downloadAllBtn"
                  onClick={() => handleDownloadOptionChange()}
                >
                  <i className="iconDownloadBtn">
                    <img
                      src={downloadIconBlack}
                      className="downloadAllIcon"
                      alt="download icon"
                    />
                  </i>
                  {t(TRANSLATIONS.DOWNLOAD_ALL)}
                </button>
                )}
                <MonthlyTimeFilter
                  onChange={handleMonthChange}
                  currentValue={currentMonth}
                  t={t}
                  bgColor="transparent"
                />
                {loading && <LoadingSpinner text=" " height={25} />}
              </div>
            </div>
            <div className="distribution-centers__list">
              {arrayOfLocationToShow?.map((locationToShow: any) => (
                <Card
                  isClickable
                  name={locationToShow.get('name')}
                  slug={locationToShow.get('slug')}
                  counter={locationToShow.get('actionLogsCount')}
                  handleClick={handleCardClick}
                  key={locationToShow.get('slug')}
                  downloadComponent={(
                    canUserDownload(userType) && (
                    <div className="download-excel">
                      <img src={downloadIconBlack} alt="download icon" width={10} />
                      <p
                        style={{ marginLeft: 5 }}
                        onClick={() => downloadActionLogsReport(
                          accessLevel,
                          timeFilter,
                          locationToShow.get('slug'),
                        )}
                      >
                        {t(TRANSLATIONS.DOWNLOAD)}
                      </p>
                    </div>
                    )
                  )}
                />
              ))}
            </div>
          </div>
          {arrayOfLocationToShow?.size === 0 && (
            <p className="note">
              {t(TRANSLATIONS.YOU_DONT_HAVE_ANY_LOCATION_ASSIGNED_PLEASE_CONTACT_SUPPORT)}
            </p>
          )}
          <Disclaimer message={TRANSLATIONS.USER_ACCESS_DISCLAIMER} />
        </div>
      ) : (
        <div className="distribution-centers__loader">
          <LoadingSpinner />
        </div>
      )}
    </>
  );
};

export default ExcelDownloadBoard;
