import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Header from 'components/Header';
import NavigationDrawerWithSideMenu
  from 'components/NavigationDrawer/NavigationDrawerWithVerticalMenu';
import defaultRoutes from 'utils/defaultRoutes';
import './AddAsset.scss';
import { TRANSLATIONS, UserTypes } from 'types/enums';
import { toast, ToastOptions } from 'react-toastify';
import 'moment/locale/es';
import { Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectUserLocation, selectUserType } from 'store/selectors/user.selectors';
import ImageLinks from 'utils/ImageLinks';
import SelectGroupDropdown from 'components/Dropdown/SelectGroupDropdown';
import BasicButton from 'components/Button/BasicButton';
import { useNavigate } from 'react-router-dom';
import { esES, enUS } from '@mui/x-date-pickers/locales';
import {
  useCreateListItemMutation,
  useGetListItemForCompanyByIdLazyQuery,
  useGetListsForCompanyLazyQuery,
  useIsListItemUniqueNameAvailableLazyQuery,
  useUpdateListItemMutation,
} from 'generated/graphql';
import { ChecklistBitTypes, ChecklistItem, ChecklistSection } from 'features/Espectro/types';
import { cloneDeep } from 'lodash';
import Input from 'components/Input';
import LoadingSpinner from 'components/LoadingSpinner';
import { uploadToS3 } from 'utils/ImageHelper';
import withParams from 'utils/hoc/withParams';
import i18next from 'i18next';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

const toastConfig = {
  position: 'top-right',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
} as ToastOptions;

const DateLocale = i18next.language?.indexOf('en') === 0
  ? enUS.components.MuiLocalizationProvider.defaultProps.localeText
  : esES.components.MuiLocalizationProvider.defaultProps.localeText;

const AddAsset = (props: any) => {
  const userType = useSelector(selectUserType);
  const location = useSelector(selectUserLocation);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { assetId: passedAssetId = null } = props.params;

  const [selectedListType, setSelectedListType] = useState<any>(null);
  const [uniqueSlug, setUniqueSlug] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [assetAvatar, setAssetAvatar] = useState<Blob>();
  const [fetchedAssetUrl, setFetchedAssetUrl] = useState<string>('');
  const [isImageUploading, setIsImageUploading] = useState<boolean>(false);

  const [attributesInput, setAttributesInput] = useState<any>([]);

  const [
    getListItemForCompanyByIdQuery,
    {
      data: fetchedAssetData,
      loading: isGetAssetInfoQueryLoading,
    },
  ] = useGetListItemForCompanyByIdLazyQuery({
    onCompleted: d => {
      const fetchedAssetInfoFromQuery = d!.getListItemDetails;
      const fetchedAttributesForEditing: ChecklistItem[] = fetchedAssetInfoFromQuery!.attributes;
      console.log({ fetchedAttributesForEditing });
      setName(fetchedAssetInfoFromQuery!.name ?? '');
      if (fetchedAssetInfoFromQuery?.imageUrl !== null) {
        setFetchedAssetUrl(fetchedAssetInfoFromQuery!.imageUrl as string);
      }
      setUniqueSlug(fetchedAssetInfoFromQuery!.description ?? '');
      const attrClone = cloneDeep(attributesInput);
      fetchedAttributesForEditing!.forEach((attr, index) => {
        attrClone[index] = '';
        attrClone[index] = attr.bits[0].answer;
        setAttributesInput([...attrClone]);
      });
    },
  });

  const [
    fetchListForCompanyQuery, {
      data: assetTypeList,
      loading: isFetchListQueryLoading,
      refetch: refetchListForCompanyQuery,
    },
  ] = useGetListsForCompanyLazyQuery({
    // eslint-disable-next-line no-alert
    onError: e => alert(`Error occured ${e.message}`),
  });

  useEffect(() => {
    if (refetchListForCompanyQuery) {
      refetchListForCompanyQuery();
    }
  }, [refetchListForCompanyQuery]);

  useEffect(() => {
    if (passedAssetId && fetchedAssetData?.getListItemDetails
      && assetTypeList?.getListsForCompany) {
      const listType = assetTypeList?.getListsForCompany!.find(a => a?.id === fetchedAssetData
        ?.getListItemDetails?.list?.id);
      setSelectedListType(listType?.name ?? null);
    }
  }, [passedAssetId, fetchedAssetData, assetTypeList]);

  const [
    fetchIsListItemUniqueNameAvailable, {
      data: isListItemAvailableQueryData,
    },
  ] = useIsListItemUniqueNameAvailableLazyQuery();

  const isListNameUnique = isListItemAvailableQueryData?.isListItemUniqueNameAvailable
    ?.isAvailable ?? true;

  const [
    createListItemMutation, {
      loading: isCreateListItemLoading,
    },
  ] = useCreateListItemMutation({
    onCompleted: () => {
      setIsImageUploading(false);
      navigate(-1);
    },
    onError: e => toast.success(e.message ?? 'Internal Error', toastConfig),
  });

  const assetListRaw = assetTypeList?.getListsForCompany;

  const addAssetTypeString = `+ ${t(TRANSLATIONS.ADD_ASSET_TYPE)}`;

  const assetTypeListForDropdown = useMemo(() => {
    let types = assetListRaw?.map(a => ({
      value: a?.name,
      label: a?.name,
      searchKey: a?.name,
      id: a?.id,
    }));
    if (!Array.isArray(types)) {
      types = [];
    }
    return types;
  }, [assetListRaw]);

  const goBack = () => {
    navigate(-1);
  };

  const [
    updateAssetMutation, {
      loading: isUpdateAssetMutationLoading,
    },
  ] = useUpdateListItemMutation({
    onCompleted: () => {
      setIsImageUploading(false);
      goBack();
    },
    onError: e => toast.success(e.message ?? 'Internal Error', toastConfig),
  });

  const fetchListItemsForCompanyById = useCallback(() => {
    getListItemForCompanyByIdQuery({
      variables: {
        listItemId: passedAssetId,
      },
    });
  }, [getListItemForCompanyByIdQuery, passedAssetId]);

  useEffect(() => {
    fetchListForCompanyQuery();
    if (passedAssetId) {
      fetchListItemsForCompanyById();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (refetchListForCompanyQuery) {
      refetchListForCompanyQuery();
    }
  }, [refetchListForCompanyQuery]);

  const selectedAttributes: ChecklistItem[] = useMemo(() => {
    if (selectedListType !== null) {
      const targetAssetType = assetListRaw!.find(a => a?.name === selectedListType);
      if (targetAssetType) {
        const attributes: ChecklistItem[] = [];
        const fetchedAttributes: ChecklistSection[] = targetAssetType.attributes;
        fetchedAttributes[0].items.forEach(i => {
          if (!['Nombre único',
            'Nombre para inspecciones',
            'Name to inspect',
            'Name of inspection',
            'Unique name'].includes(i.title)) {
            attributes.push(i);
          }
        });
        return attributes;
      }
    }
    return [];
  }, [selectedListType, assetListRaw]);

  const isDisabled = () => {
    if (uniqueSlug === '') return true;
    if (name === '') return true;
    if (selectedListType === null) return true;
    let hasEmptyAnswer = false;
    selectedAttributes.forEach((a, i) => {
      if (!attributesInput[i]) {
        hasEmptyAnswer = true;
      }
    });
    if (hasEmptyAnswer) return true;
    return false;
  };

  const fetchIfNameIsUnique = useCallback(() => {
    fetchIsListItemUniqueNameAvailable({
      variables: {
        uniqueName: uniqueSlug,
      },
    });
  }, [uniqueSlug, fetchIsListItemUniqueNameAvailable]);

  const onPressCreateListItem = async () => {
    fetchIfNameIsUnique();
    if (uniqueSlug === '') return;
    if (name === '') return;
    if (selectedListType === null) return;
    let hasEmptyAnswer = false;
    selectedAttributes.forEach((a, i) => {
      if (!attributesInput[i]) {
        hasEmptyAnswer = true;
      }
    });
    if (hasEmptyAnswer) return;

    if (isListNameUnique) {
      const targetAssetType = assetListRaw!.find(a => a?.name === selectedListType);
      const attributesItemsClone = cloneDeep(selectedAttributes);
      attributesItemsClone.forEach((a, i) => {
        const answer = attributesInput[i];
        a.bits[0] = {
          ...a.bits[0],
          answer,
        };
      });

      if (assetAvatar) {
        setIsImageUploading(true);
      }

      const assetImageUrl = assetAvatar ? await uploadToS3(assetAvatar) : null;
      if (passedAssetId) {
        updateAssetMutation({
          variables: {
            listId: targetAssetType!.id ?? null,
            uniqueSlug,
            additionalAttributes: attributesItemsClone,
            imageUrl: assetImageUrl,
            name,
            listItemId: passedAssetId,
          },
        });
      } else {
        createListItemMutation({
          variables: {
            listId: targetAssetType!.id ?? null,
            uniqueSlug,
            additionalAttributes: attributesItemsClone,
            imageUrl: assetImageUrl,
            name,
          },
        });
      }
    }
  };

  return (
    <div className="add_asset-page-container">
      <Header />
      <NavigationDrawerWithSideMenu
        {...{
          routes: defaultRoutes(t, userType, location),
          openDrawer: true,
        }}
      />
      {
        (isFetchListQueryLoading || isCreateListItemLoading
          || isGetAssetInfoQueryLoading || isUpdateAssetMutationLoading
          || isImageUploading)
          ? (<LoadingSpinner />)
          : (
            <>
              <div className="asset-builder__container asset-builder_required-inputs-wrapper">
                <div className="asset-builder__flex-row">
                  <div role="button" className="backButtonContainer" onClick={goBack}>
                    <img alt="backArrow" src={ImageLinks.leftArrowIcon} />
                  </div>
                  {
                    userType === UserTypes.EspectroViewOnly ? (
                      <Tooltip title="Read Only" arrow placement="right">
                        <h1>{t(TRANSLATIONS.ADD_ASSETS)}</h1>
                      </Tooltip>
                    ) : (
                      <span className="asset-title">{t(TRANSLATIONS.ADD_ASSETS)}</span>
                    )
                  }
                </div>
                {
                  assetAvatar || (passedAssetId && fetchedAssetUrl) ? (
                    <div>
                      <img
                        src={(passedAssetId && fetchedAssetUrl)
                          ? fetchedAssetUrl : URL.createObjectURL(assetAvatar as Blob)}
                        alt="asset"
                        width={250}
                        height="auto"
                        style={{ borderRadius: 8, objectFit: 'contain' }}
                      />
                      <input
                        type="file"
                        id="avatar-input"
                        style={{ display: 'none' }}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          if (passedAssetId && fetchedAssetUrl) {
                            setFetchedAssetUrl('');
                          }
                          setAssetAvatar(e!.target!.files![0] as Blob);
                        }}
                        accept="image/png, image/jpeg"
                      />
                      <div className="add_asset-imageTextContainer">
                        <button
                          type="button"
                          onClick={() => document.getElementById('avatar-input')?.click()}
                          className="reUploadImage"
                        >
                          <img src={ImageLinks.espectro.upload} alt="delete section" width={24} height={24} color="white" style={{ marginRight: 10 }} />
                          {t(TRANSLATIONS.REPLACE_FILE)}
                        </button>
                        <button
                          type="button"
                          onClick={() => {
                            if (passedAssetId && fetchedAssetUrl) {
                              setFetchedAssetUrl('');
                            } else {
                              setAssetAvatar(undefined);
                            }
                          }}
                          style={{ backgroundColor: 'red', borderColor: 'red', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', fontSize: 15, marginTop: 12 }}
                          className="delete-image"
                        >
                          <img src={ImageLinks.espectro.whiteDelete} alt="delete section" width={24} height={24} color="white" style={{ marginRight: 10 }} />
                          {t(TRANSLATIONS.DELETE)}
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className="searchFolder">
                      <img alt="gallary" src={ImageLinks.espectro.imagePickerIconRaw} />
                      <span>Subir fotografía o
                        {' '}
                        <span
                          role="button"
                          className="buscar-style"
                          onClick={() => {
                            document.getElementById('avatar-input')?.click();
                          }}
                        >
                          buscar
                          <input
                            type="file"
                            id="avatar-input"
                            style={{ display: 'none' }}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              setAssetAvatar(e!.target!.files![0] as Blob);
                            }}
                            accept="image/png, image/jpeg"
                          />
                        </span>
                        {' '}
                        en carrete
                      </span>
                    </div>
                  )
                }
              </div>
              <div className="asset-builder__container">
                <div className="asset-builder_required-inputs-wrapper">
                  <div>
                    <span>{t(TRANSLATIONS.INSPECT_NAME)}*</span>
                    <div className="add_asset-pop-up-input-container">
                      <input
                        type="text"
                        className="add_asset-quesion_popup-input-style"
                        placeholder={t(TRANSLATIONS.WRITE)}
                        value={name}
                        onChange={e => setName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div>
                    <span>{t(TRANSLATIONS.UNIQUE_NAME)}*
                      <br /><span className="unique-subtitle">{t(TRANSLATIONS.UNIQUE_SUBTITLE)}</span>
                    </span>
                    <div className="add_asset-pop-up-input-container">
                      <input
                        type="text"
                        className="add_asset-quesion_popup-input-style"
                        placeholder={t(TRANSLATIONS.WRITE)}
                        value={uniqueSlug}
                        onChange={e => {
                          setUniqueSlug(e.target.value);
                          // fetchIfNameIsUnique();
                        }}
                      />
                    </div>
                  </div>
                  <div>
                    <span>{t(TRANSLATIONS.ASSET_TYPE)}*</span>
                    <div className="add_asset-dropdownInputStyle">
                      <SelectGroupDropdown
                        placeholder="Select a default option"
                        name="Bit Type"
                        bgColor="white"
                        footerButtonText={addAssetTypeString}
                        value={selectedListType}
                        onChange={e => {
                          console.log({ e });
                          if (e.target.value === addAssetTypeString) {
                            navigate('/espectro/assets/add-asset-type');
                          } else {
                            setAttributesInput([]);
                            setSelectedListType(e.target.value ?? null);
                          }
                        }}
                        dropdownContent={assetTypeListForDropdown as []}
                      />
                    </div>
                  </div>
                </div>

                {/* List type attributes input */}
                {
                  selectedAttributes.length > 0 && (
                    <div className="asset-builder_required-inputs-wrapper-adjacent">
                      {selectedAttributes.map((attribute: ChecklistItem, index: number) => {
                        const bit = attribute.bits[0];
                        switch (bit.type) {
                          case ChecklistBitTypes.TEXT: return (
                            <>
                              <div
                                style={{ margin: 0, padding: 0, marginTop: index === 0 ? 8 : 16 }}
                              >
                                <span style={{ fontSize: 15 }}>{attribute.title}</span>
                              </div>
                              <Input
                                value={attributesInput![index] ?? ''}
                                placeholder="Escribe"
                                style={{ width: '180%' }}
                                onChange={e => {
                                  const { value } = e.target;
                                  const attrClone = cloneDeep(attributesInput);
                                  attrClone[index] = '';
                                  attrClone[index] = value;
                                  setAttributesInput([...attrClone]);
                                }}
                              />
                            </>
                          );
                          case ChecklistBitTypes.INPUT: return (
                            <>
                              <div
                                style={{ margin: 0, padding: 0, marginTop: index === 0 ? 8 : 16 }}
                              >
                                <span style={{ fontSize: 15 }}>{attribute.title}</span>
                              </div>
                              <Input
                                value={attributesInput![index] ?? ''}
                                placeholder="Escribe"
                                style={{ width: '180%' }}
                                onChange={e => {
                                  let formattedText = e.target.value;
                                  const attrClone = cloneDeep(attributesInput);
                                  const { numericType } = attribute.bits[0].props;
                                  if (numericType === 'decimal') {
                                    formattedText = e.target.value.replace(/[^\d.-]+/g, '');
                                  } else {
                                    formattedText = e.target.value.replace(/[^0-9]/g, '');
                                  }
                                  attrClone[index] = '';
                                  attrClone[index] = formattedText;
                                  setAttributesInput([...attrClone]);
                                }}
                              />
                            </>
                          );
                          case ChecklistBitTypes.DATE: return (
                            <>
                              <div
                                style={{ margin: 0, padding: 0, marginTop: index === 0 ? 8 : 16 }}
                              >
                                <span style={{ fontSize: 15 }}>{attribute.title}</span>
                              </div>
                              <div className="add_asset-date-wrapper">
                                <LocalizationProvider dateAdapter={AdapterDateFns} localeText={DateLocale}>
                                  <MobileDatePicker
                                    format="dd/MM/yyyy"
                                    sx={{
                                      width: '180%',
                                      border: 'none',
                                    }}
                                    value={attributesInput![index] ?? new Date()}
                                    onChange={data => {
                                      if (data && !isNaN(data.getTime())) {
                                        const attrClone = cloneDeep(attributesInput);
                                        attrClone[index] = '';
                                        attrClone[index] = data;
                                        setAttributesInput([...attrClone]);
                                      }
                                    }}
                                  />
                                </LocalizationProvider>
                              </div>
                            </>
                          );
                          default: return (
                            <p>
                              Error! Unable to render attributes with asset category name
                              {selectedListType}
                            </p>
                          );
                        }
                      })}
                    </div>
                  )
                }
              </div>
              <div className="button-class">
                <BasicButton
                  onClick={onPressCreateListItem}
                  className="checklist-builder__upload_button"
                  text={passedAssetId !== null ? 'Update Asset' : `+ ${t(TRANSLATIONS.CREATE_NEW_TYPE)}`}
                  background={isDisabled() ? '#6C63FF70' : '#6C63FF'}
                  disabled={isDisabled()}
                />
              </div>
            </>
          )
      }
    </div>
  );
};

export default withParams(AddAsset);
