export default {
  workOrderValidityModalContainer: {
    position: 'absolute',
    top: '30%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '55%',
    bgcolor: 'white',
    display: 'flex',
    alignItems: 'stretch',
    borderRadius: 4,
    pt: 2,
    px: 4,
    pb: 2,
  },
};
