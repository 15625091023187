import { isEspectroEnabled } from 'features/Espectro/constants';
import ImageLinks from 'utils/ImageLinks';

import React from 'react';

type Props = {
  label?: string;
  selected?: boolean;
  selectedIcon?: string;
  deSelectedIcon?: any;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  disabled?: boolean;
  required?: boolean;
  iconStyle?: {};
  noIcon?:boolean
};

const CheckBoxWithIcon = (props: Props) => {
  const {
    selected,
    selectedIcon,
    deSelectedIcon,
    label,
    onClick,
    disabled,
    required,
    iconStyle,
    noIcon,
  } = props;
  return (
    <div
      onClick={onClick}
      style={{
        display: 'flex',
        maxWidth: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        cursor: disabled ? 'not-allowed' : 'pointer',
        marginBottom: '5px',
        justifyContent: 'space-between',
      }}
      role="presentation"
    >
      <span
        style={{
          display: 'flex',
          alignItems: 'center',
          marginLeft: '8px',
          color: (isEspectroEnabled && selected) ? '#404040' : '#B5B5B5',
        }}
      >
        {required && <p style={{ color: '#E92B36', display: 'inline' }}>*</p>}
        {label}
      </span>
      {!noIcon && <img style={{ ...iconStyle }} width="24px" src={selected ? selectedIcon : deSelectedIcon} alt="Checkbox" />}
      {(noIcon && selected && isEspectroEnabled) ? <img src={ImageLinks.espectro.checkSmall} style={{ ...iconStyle }} alt="Checkbox" /> : ''}
    </div>
  );
};

export default CheckBoxWithIcon;
