import React, { PureComponent } from 'react';
import ImageLinks from 'utils/ImageLinks';
import InputWithIcon from 'components/Input/InputWithIcon';
import HighlightButton from 'components/Button/HighlightButton';
import request from 'utils/request';
import LoadingSpinner from 'components/LoadingSpinner';
import 'features/internal-users/user/Login/Login.scss';
import { withTranslation } from 'react-i18next';
import { TRANSLATIONS } from 'types/enums';
import { connect } from 'react-redux';
import { isUserAuthenticated } from 'store/actions/user.actions';
import { Link } from 'react-router-dom';
import { userOnlyHasAccessToReportSIOs, userOnlyHasAccessToSearchUserScreen } from 'utils/canUserAccessFeature';
import { isEspectro } from '../../../../constants';
import withNavigation from 'utils/hoc/withNavigation';

const LockIcon = ImageLinks.lockIcon;
const UserIcon = ImageLinks.userIcon;
const UserWarningIcon = ImageLinks.userWarningIcon;
const LockIWarningIcon = ImageLinks.lockWarningIcon;

type State = {
  email: string;
  password: string;
  errors: any;
  loggingIn: boolean;
};

class Login extends PureComponent<any, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      email: '',
      password: '',
      errors: {},
      loggingIn: false,
    };
    this.submitLogin = this.submitLogin.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.enterSubmit = this.enterSubmit.bind(this);
  }

  componentDidUpdate(prevProps : any) {
    if (!prevProps.user?.email && this.props.user?.email) {
      if (userOnlyHasAccessToSearchUserScreen(this.props.user.type)) {
        this.props.navigate('/all-users');
        return;
      }
      if (userOnlyHasAccessToReportSIOs(this.props.user.roleDetails,
        this.props.user.features,
        this.props.user.type)) {
        this.props.navigate('/ac');
        return;
      }
      this.props.navigate('/espectro/checklist-builder');
    }
  }

  enterSubmit(e: React.KeyboardEvent<HTMLFormElement>) {
    if (e.keyCode === 13 && !e.shiftKey) {
      e.preventDefault();
      e.stopPropagation();
      this.submitLogin();
    }
  }

  async submitLogin(e?: any) {
    if (e && e.preventDefault) {
      e.preventDefault();
      e.stopPropagation();
    }
    this.setState({ loggingIn: true, errors: {} });
    try {
      await request.post('/api/v1/users/login', this.state);
      await this.props.dispatch(isUserAuthenticated());
      this.props.navigate('/espectro/checklist-builder');
    } catch (error) {
      if ((typeof (error as any).response?.data) !== 'object') {
        this.setState({ errors: { message: 'Error de red' }, loggingIn: false });
      } else {
        this.setState({ errors: (error as any).response.data, loggingIn: false });
      }
      console.log(error);
    }
  }

  handleInputChange(e: any) {
    // @ts-ignore
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    const { errors } = this.state;
    const { t } = this.props;
    // 500 error responses are not formatted within input field keys
    // but rather as a message key
    let nonValidationError = '';
    if (!errors.email && !errors.password && errors.message) {
      switch (errors.message) {
        case 'Permisos insuficientes para acceder':
          nonValidationError = t(TRANSLATIONS.NOT_ENOUGH_PERMISSIONS_TO_ACCESS);
          break;
        default:
          nonValidationError = t(TRANSLATIONS.INVALID_CREDENTIALS_TRY_AGAIN);
          break;
      }
    }
    return (
      <div className="login">
        <div className="container">
          <div className="row">
            <div className="col-md-6 offset-md-3 card main-card-container">
              {/* <img style={{ width: 250 }} src={Logo} alt="logo" /> */}
              <p className="welcome-text" style={{ marginBottom: 10 }}>
                {
                isEspectro
                  ? t(TRANSLATIONS.LOGIN_TOP_ESPECTRO_DASHBOARD)
                  : t(TRANSLATIONS.LOGIN_TO_ACCESS_SAFETY_DASHBOARD)
}
              </p>
              <form onSubmit={this.submitLogin} onKeyDown={this.enterSubmit}>
                <InputWithIcon
                  icon={UserIcon}
                  errorIcon={UserWarningIcon}
                  type="text"
                  placeholder={t(TRANSLATIONS.EMAIL)}
                  onChange={this.handleInputChange}
                  name="email"
                  // error={errors.type}
                  errorHeight
                  style={{ borderWidth: '1px' }}
                  errorTxt={false}
                  autoComplete={false}
                />
                <InputWithIcon
                  icon={LockIcon}
                  errorIcon={LockIWarningIcon}
                  type="password"
                  placeholder={t(TRANSLATIONS.PASSWORD)}
                  onChange={this.handleInputChange}
                  name="password"
                  // error={errors.type}
                  errorHeight
                  style={{ borderWidth: '1px' }}
                  clickable
                  errorTxt={false}
                  autoComplete={false}
                />
                <p className="recover-password-text">
                  {t(TRANSLATIONS.FORGOT_YOUR_PASSWORD)}
                  <Link
                    to="/reset-password"
                    style={{ textDecoration: 'underline' }}
                  >
                    {t(TRANSLATIONS.RECOVER_IT_HERE)}
                  </Link>
                </p>
                <HighlightButton
                  text={t(TRANSLATIONS.LOGIN).toUpperCase()}
                  className="main-action"
                  onClick={this.submitLogin}
                />
                {/* <p className="register-text">
                  {t(TRANSLATIONS.DONT_HAVE_AN_ACCOUNT)}?
                  <Link
                    to="/register-account"
                    style={{ textDecoration: 'underline' }}
                  >
                    {t(TRANSLATIONS.CREATE_AN_ACCOUNT)}
                  </Link>
                </p> */}
                {nonValidationError ? <p className="error">{nonValidationError}</p> : null}
                {this.state.loggingIn
                  ? <LoadingSpinner text={t(TRANSLATIONS.LOGGING_IN)} className="login-spinner" />
                  : null}
              </form>
            </div>
          </div>
          <div className="row">
            <div className="footer">
              <p className="footer-text"> powered by ARVOLUTION </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  return {
    user: state.getIn(['user']).toJS(),
  };
}

export default connect(mapStateToProps)(withNavigation(withTranslation()(Login))) as any;
