import React, { useCallback, useEffect, useState } from 'react';
import QRCode from 'qrcode';
import cameraIcon from 'assets/icons/camera.svg';
import copyIcon from 'assets/copy-new.svg';
import downloadIcon from 'assets/download-new.svg';
import cross from 'assets/cross-square.svg';

import Header from 'components/Header';
import NavigationDrawerWithSideMenu
  from 'components/NavigationDrawer/NavigationDrawerWithVerticalMenu';
import './AssetInfo.styles.scss';
import { useDeleteListItemMutation, useGetListItemForCompanyByIdLazyQuery, useUpdateListItemAvatarMutation } from 'generated/graphql';
import defaultRoutes from 'utils/defaultRoutes';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectUserLocation, selectUserType } from 'store/selectors/user.selectors';
import ImageLinks from 'utils/ImageLinks';
import Overlay from 'components/Espectro/Overlay';
import ConfirmationModal from 'components/Modal/ConfirmationModal';
import { Button } from '@mui/material';
import { uploadToS3 } from 'utils/ImageHelper';
import { ChecklistBitTypes } from 'features/Espectro/types';
import { format } from 'date-fns';
import { useNavigate, useParams } from 'react-router-dom';

export default function AssetInfo(props: any) {
  const params = useParams();
  const navigateHook = useNavigate();
  const { assetsId } = params;
  const { t } = useTranslation();
  const userType = useSelector(selectUserType);
  const location = useSelector(selectUserLocation);
  const [isQRmodalOpen, setIsQRmodalOpen] = useState<boolean>(false);
  const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState<boolean>(false);
  const [qr, setQr] = useState<string>('');
  const [assetAvatar, setAssetAvatar] = useState<Blob>();

  const [
    callUpdateListItemAvatar,
  ] = useUpdateListItemAvatarMutation();

  const uploadImage = useCallback(async () => {
    if (assetAvatar) {
      console.log({ assetAvatar });

      const assetImageUrl = assetAvatar ? await uploadToS3(assetAvatar) : null;

      if (assetImageUrl) {
        callUpdateListItemAvatar({ variables: {
          imageUrl: assetImageUrl,
          listItemId: assetsId,
        } });
      }
    }
  }, [assetAvatar, assetsId, callUpdateListItemAvatar]);

  useEffect(() => {
    uploadImage();
  }, [assetAvatar, uploadImage]);
  const [
    getListItemForCompanyByIdQuery,
    {
      data,
      loading: isFetchListItemsQueryLoading,
    },
  ] = useGetListItemForCompanyByIdLazyQuery({
    variables: {
      listItemId: assetsId,
    },
  });
  const fetchListItemsForCompanyById = useCallback(() => {
    getListItemForCompanyByIdQuery();
  }, [getListItemForCompanyByIdQuery]);
  const [deleteListItem] = useDeleteListItemMutation();
  useEffect(() => {
    if (assetsId) {
      fetchListItemsForCompanyById();
    }
  }, [fetchListItemsForCompanyById, assetsId]);

  const goBack = () => {
    navigateHook(-1);
  };
  const onDeleteConfirm = () => {
    deleteListItem({
      variables: {
        listItemId: assetsId,
      },
    }).then(() => {
      goBack();
    }).finally(() => {
      setIsDeleteAlertOpen(false);
    });
  };
  const listData = data?.getListItemDetails;
  const avatarUrl = data?.getListItemDetails?.imageUrl;
  const getGridData = () => {
    if (listData?.attributes) {
      const dataList: any = (listData?.attributes).slice(1).map(
        (item: { title: any; bits: { answer: any; }[]; }) => (
          {
            title: item.title,
            answer: (item.bits[0] as any).type === ChecklistBitTypes.DATE ? format(new Date(item.bits[0].answer ?? null), 'dd/MM/yyyy') : item.bits[0].answer,
          }),
      );
      // Split titles into chunks of 3 for each row
      const chunkSize = 3;
      const rows = [[{
        title: 'Nombre para inspecciones',
        answer: listData?.name as string,
      }, {
        title: 'Tipo de activo',
        answer: listData?.list?.name as string,
      }, {
        title: listData?.attributes?.[0].title ?? '',
        answer: listData?.attributes?.[0].bits?.[0].answer ?? '',
      }]];
      for (let i = 0; i < dataList.length; i += chunkSize) {
        rows.push(dataList.slice(i, i + chunkSize));
      }
      return rows;
    }
    return [];
  };

  const generateQrCode = () => {
    QRCode.toDataURL(listData?.name as string, {
      width: 220,
      color: {
        dark: '#000000',
        light: '#ffffff',
      },
      margin: 2,
    }, (err, url) => {
      if (err) {
        return;
      }
      setQr(url);
    });
  };
  return (
    <div
      className="page-container"
    >
      <Header />
      <NavigationDrawerWithSideMenu
        {...{
          routes: defaultRoutes(t, userType, location),
          openDrawer: true,
        }}
      />
      {
        !isFetchListItemsQueryLoading && !!listData?.attributes[0] && (
          <div className="assetsInfo__container">
            <div role="button" className="assetsInfo__backButtonContainer" onClick={goBack}>
              <img alt="backArrow" src={ImageLinks.espectro.backArrow} />
              <p>Mis activos</p>
            </div>
            <div style={{ width: '80%' }}>
              <div className="assetsInfo__topbar" style={{ height: 'calc(100% - 50px)' }}>
                <div className="assetsInfo__topbar_tools">
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div className="assetsInfo__avatar">
                      {assetAvatar || avatarUrl ? (
                        <img
                          src={(avatarUrl) || URL.createObjectURL(assetAvatar as Blob)}
                          alt="asset"
                          width={80}
                          height="auto"
                          style={{ borderRadius: 8, objectFit: 'contain' }}
                        />
                      ) : listData?.name?.charAt(0)}
                      <div
                        role="button"
                        onClick={() => {
                          document.getElementById('avatar-input')?.click();
                        }}
                      >
                        <img src={cameraIcon} alt={cameraIcon} />
                        <input
                          type="file"
                          id="avatar-input"
                          style={{ display: 'none' }}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setAssetAvatar(e!.target!.files![0] as Blob);
                          }}
                          accept="image/png, image/jpeg"
                        />
                      </div>
                    </div>
                    <p className="assetsInfo__name">{listData?.description}</p>
                  </div>
                  <div style={{ display: 'flex', gap: '12px' }}>
                    <div onClick={() => { setIsQRmodalOpen(true); generateQrCode(); }} className="assetsInfo__borderButton" role="button">
                      <img style={{ height: 24, width: 24 }} alt="qrCode" src={ImageLinks.espectro.qrCode} />
                      <p>Ver QR</p>
                    </div>
                    <div onClick={() => props.history.push(`/espectro/assets/add-asset/${listData!.id}`)} className="assetsInfo__borderButton" role="button">
                      <img style={{ height: 24, width: 24 }} alt="qrCode" src={ImageLinks.edit} />
                    </div>
                    <div onClick={() => { setIsDeleteAlertOpen(true); }} className="assetsInfo__borderButton" role="button">
                      <img style={{ height: 24, width: 24 }} alt="qrCode" src={ImageLinks.espectro.deleteRedEmpty} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="assetsInfo__info_container">
                {getGridData().map((row, rowIndex) => (
                  <div key={rowIndex} style={{ display: 'flex', flexDirection: 'column' }}>
                    {row.map((el: { title: string, answer: string }) => (
                      <div className="assetsInfo__info_item">
                        <p className="assetsInfo__label">{el.title}</p>
                        <p className="assetsInfo__info">{el.answer}</p>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          </div>
        )
      }
      <Overlay open={isQRmodalOpen} onClose={() => setIsQRmodalOpen(false)}>
        <div style={{ maxWidth: 400, display: 'flex', flexDirection: 'column', marginLeft: 'auto', marginRight: 'auto', backgroundColor: 'white', justifyContent: 'center', alignItems: 'center', marginTop: 200, padding: 10, paddingBottom: 15, paddingTop: 15, borderRadius: '12px' }}>
          <div className="assetsInfo__overlay_head">
            <p className="assetsInfo__overlay_p">
              Descarga este código QR para compartirlo con tu equipo.
              Escaneándolo los usuarios podrán ver el perfil del activo y muchas cosas más.
            </p>
            <img onClick={() => setIsQRmodalOpen(false)} style={{ width: 28, height: 28, cursor: 'pointer' }} alt="grayCross" src={cross} />
          </div>
          <div className="assetsInfo_qr_container">
            <p style={{ margin: 0 }} className="assetsInfo__name">{listData?.name}</p>
            <div>
              {qr && <img src={qr} alt="qr" />}
            </div>
            <p style={{ margin: 0 }} className="assetsInfo__name">{listData?.description}</p>
          </div>
          <div className="assetsInfo__download">
            <Button variant="outlined" onClick={() => { navigator.clipboard.writeText(listData?.name as string); }}>
              <img style={{ height: 18, width: 18 }} alt="copyIcon" src={copyIcon} />
              <p style={{ textTransform: 'none' }}>Copiar link</p>
            </Button>
            <Button style={{ background: '#6C63FF' }} variant="outlined" href={qr} download={`${listData?.name}.png`}>
              <img style={{ height: 16, width: 16, marginRight: 4 }} alt="download" src={downloadIcon} />
              <p style={{ textTransform: 'none', color: '#fff' }}>Descargar QR</p>
            </Button>
          </div>
        </div>
      </Overlay>
      <ConfirmationModal
        title="Archivar activo"
        description="Si archivas el activo no podrás seleccionarlo en ninguna inspección ni acción."
        isOpen={isDeleteAlertOpen}
        onOk={onDeleteConfirm}
        okButtonText="Archivar"
        cancelButtonText="Cancelar"
        onCancel={() => setIsDeleteAlertOpen(false)}
        okButtonStyle={{ backgroundColor: '#E92B36', color: '#fff' }}
      />
    </div>
  );
}
