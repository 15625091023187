import { fromJS } from 'immutable';
import { format } from 'date-fns';
import { semestralPeriodOptions } from 'utils/dateHelpers';
import {
  SET_TIME_FILTER,
  SET_TIME_FILTER_RAW,
} from '../actions/timeFilter.actions';

const INITIAL_STATE = fromJS({
  startTime: semestralPeriodOptions[0].startTime,
  endTime: semestralPeriodOptions[0].endTime,
  slug: semestralPeriodOptions[0].slug,
  dropdownValue: format(new Date(), 'yyyy'),
  timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
});

// eslint-disable-next-line
const timeFiltersReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case SET_TIME_FILTER:
      return state
        .set('startTime', action.startTime)
        .set('endTime', action.endTime)
        .set('slug', action.slug)
        .set('dropdownValue', action.dropdownValue);
    case SET_TIME_FILTER_RAW:
      return state
        .set('startTime', action.time.startTime)
        .set('slug', action.time.slug)
        .set('endTime', action.time.endTime);
    default:
      return state;
  }
};

export default timeFiltersReducer;
