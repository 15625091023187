import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Header from 'components/Header';
import NavigationDrawerWithSideMenu
  from 'components/NavigationDrawer/NavigationDrawerWithVerticalMenu';
import defaultRoutes from 'utils/defaultRoutes';
import { selectUser, selectUserLocation, selectUserType } from 'store/selectors/user.selectors';
import './CancelWorkOrder.scss';
import { TRANSLATIONS } from 'types/enums';
import BasicButton from 'components/Button/BasicButton';
import { toast } from 'react-toastify';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  useCancelWorkOrderMutation,
  useGetRoutinesAvailableForWorkOrdersLazyQuery,
} from 'generated/graphql';
import InputWithLabel from 'components/Input/InputWithLabel/InputWithLabel';
import Modal from 'components/Modal';
import moment from 'moment';
import 'moment/locale/es';
import ImageLinks from 'utils/ImageLinks';
import LoadingSpinner from 'components/LoadingSpinner';
import { DragDropContext, Droppable, DropResult, Draggable } from 'react-beautiful-dnd';
import { capitalizeFirst } from 'utils/stringHelpers';
import checklistSearch from '../../../assets/icons/input-search.svg';
import backArrow from '../../../assets/icons/back-arrow.svg';

enum DndCards {
  CARD_1 = 'CARD_1',
  CARD_2 = 'CARD_2',
}

export interface StateType { checklistData: any, isEdit: boolean }

const NewWorkOrder = () => {
  const userType = useSelector(selectUserType);
  const location = useSelector(selectUserLocation);
  const { state: routerState } = useLocation();
  console.log({ routerState });
  const isWoStarted = routerState?.checklistData?.totalQuestionsAnswered > 0
    || routerState?.checklistData?.isCancelled;
  const isWorkOrderCancelled = routerState?.checklistData?.isCancelled;

  const { t } = useTranslation();
  const navigate = useNavigate();

  moment.locale('es');
  const [editTitle, setEditTitle] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const [title, setTitle] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [addedCancellationReason, setAddedCancellationReason] = useState<string>('');
  const [dndChecklistList, setDndChecklistList] = useState<any>({
    [DndCards.CARD_1]: [],
    [DndCards.CARD_2]: [],
  });
  const [selectUsers, setSelectUsers] = useState<boolean>(false);
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
  const [cancellationReason, setCancellationReason] = useState<string>('');

  const inputEl = useRef<HTMLInputElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const goBack = () => navigate(-1);

  const [callGetRoutinesAvailableLazyQuery,
    { loading, data }] = useGetRoutinesAvailableForWorkOrdersLazyQuery({
    fetchPolicy: 'no-cache',
    onCompleted: res => {
      if (res?.getRoutinesAvailable) {
        let unlinkedRoutines: any = res?.getRoutinesAvailable;
        const linkedRoutines: any = [];
        if (routerState?.checklistData?.routines?.length) {
          routerState?.checklistData?.routines?.forEach((i: any) => {
            res?.getRoutinesAvailable?.forEach(j => {
              if (i?.routineInfo?.id === j?.id) {
                linkedRoutines?.push(i);
              }
            });
          });
          const routines = (routerState?.checklistData?.routines || [])
            .map((r: any) => r.routineInfo.id);
          unlinkedRoutines = res?.getRoutinesAvailable?.filter(
            item1 => !routines?.some(
              (item2: any) => item1?.id === item2),
          );
        }
        const list = {
          [DndCards.CARD_1]: unlinkedRoutines,
          [DndCards.CARD_2]: linkedRoutines,
        };
        setDndChecklistList(list);
      }
    },
  });

  useEffect(() => {
    callGetRoutinesAvailableLazyQuery({
      variables: {
        excludeNestedChecklist: false,
        includeDeletedRoutines: true,
      },
    });
    setTitle(
      routerState?.checklistData ? routerState?.checklistData?.name : t(TRANSLATIONS.JOB_NAME),
    );
    setDescription(
      routerState?.checklistData ? routerState?.checklistData?.description : '',
    );
    setAddedCancellationReason(
      routerState?.checklistData ? routerState?.checklistData?.cancellationReason : '',
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (editTitle && inputEl?.current) {
      inputEl.current.focus();
    }
  }, [editTitle]);

  const onClickSaveChecklist = () => {};

  const routinesAvailable = data?.getRoutinesAvailable;

  const searchChecklists = useCallback(() => {
    if (
      !dndChecklistList[DndCards.CARD_1] || dndChecklistList[DndCards.CARD_1]?.length === 0
    ) return;

    const tempRoutines = dndChecklistList[DndCards.CARD_1]?.filter((workOrder: any) => workOrder
      ?.name?.toLowerCase().includes(search?.toLocaleLowerCase()));
    if (tempRoutines.length > 0) {
      const list = JSON.parse(JSON.stringify(dndChecklistList));
      list[DndCards.CARD_1] = tempRoutines;
      setDndChecklistList(list);
    } else {
      const list = {
        [DndCards.CARD_1]: [],
        [DndCards.CARD_2]: [...dndChecklistList[DndCards.CARD_2]],
      };
      setDndChecklistList(list);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    if (search) {
      searchChecklists();
    } else {
      const arr = routinesAvailable?.filter(
        item1 => !dndChecklistList[DndCards.CARD_2]?.some((item2: any) => item1?.id === item2?.id),
      );

      const list = {
        [DndCards.CARD_1]: arr,
        [DndCards.CARD_2]: [...dndChecklistList[DndCards.CARD_2]],
      };
      setDndChecklistList(list);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, searchChecklists]);

  const [
    cancelWorkOrderMutation, {
      loading: isCancelWorkOrderMutationLoading,
    },
  ] = useCancelWorkOrderMutation({
    onError: e => {
      toast.error(e.message);
      setShowCancelModal(false);
    },
    onCompleted: () => {
      navigate(-1);
      setShowCancelModal(false);
    },
  });

  const handleSearch = async (event: any) => {
    setSearch(event.target.value);
  };

  const onChangeTitle = (e: any) => {
    setTitle(e.target.value);
  };

  const onChangeDescription = (e: any) => {
    setDescription(e.target.value);
  };

  const toggleEditTitle = () => setEditTitle(prev => !prev);

  async function onDragEnd(result: DropResult) {
    const { source, destination } = result;
    if (!destination) return;
    const sourceColumn = source?.droppableId as DndCards;
    const destinationColumn = destination?.droppableId as DndCards;

    if (sourceColumn === destinationColumn) {
      const items = Array.from(dndChecklistList?.[sourceColumn]);
      const [removed] = items?.splice(source?.index, 1);
      items.splice(destination?.index, 0, removed);

      setDndChecklistList((prevState: any) => ({
        ...prevState,
        [sourceColumn]: items,
      }));
    } else {
      const movingCard = dndChecklistList?.[sourceColumn]?.find(
        (_: any, index: number) => index === source?.index);
      setDndChecklistList((preState: any) => ({
        ...preState,
        [sourceColumn]: [...preState?.[sourceColumn]?.filter?.(
          (_: any, index: number) => index !== source?.index,
        )],
        [destinationColumn]: [
          ...preState?.[destinationColumn]?.slice?.(0, destination?.index),
          { ...movingCard },
          ...preState?.[destinationColumn]?.slice?.(destination?.index),
        ],
      }));
    }
  }

  const onClickSelectUsers = () => {
    setSelectUsers(true);
  };

  const userDetails = useSelector(selectUser);

  const onCancelWo = () => {
    cancelWorkOrderMutation({
      variables: {
        cancellationReason,
        workOrderId: String(routerState?.checklistData?.id),
      },
    });
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className="page-container">
        <Header />
        <NavigationDrawerWithSideMenu
          {...{
            routes: defaultRoutes(t, userType, location, userDetails),
            openDrawer: true,
          }}
        />
        {showCancelModal && (
          <Modal
            modalContainerStyles={{
              width: '632px',
              height: 'auto',
              display: 'flex',
              flexDirection: 'column',
              position: 'absolute',
              left: '50%',
              top: '50%',
              transform: 'translate(-50%, -50%)',
              overflow: 'hidden',
              padding: '24px',
            }}
          >
            {isCancelWorkOrderMutationLoading ? (
              <div className="cancel_wo__delete-loader">
                <LoadingSpinner />
              </div>
            ) : (
              <div>
                <p className="wo__deleteMoal-title">{`${t(TRANSLATIONS.CANCEL_WORK_ORDER)}`}</p>
                <p className="wo__deleteMoal-description">{`${routerState?.checklistData?.id} - ${routerState?.checklistData?.name}`}</p>
                <textarea
                  value={cancellationReason}
                  inputMode="text"
                  placeholder={`${t(TRANSLATIONS.REASON_FOR_CANCELLATION)}...`}
                  onChange={
                    (event: React.ChangeEvent<HTMLTextAreaElement>) => setCancellationReason(
                      event.target.value,
                    )
                  }
                  className="wo__cancellation-reason-input"
                  style={{
                    width: '100%',
                  }}
                />
                <div className="wo__deleteMoal-button_container">
                  <BasicButton text={t(TRANSLATIONS.CANCEL)} className="wo__deleteMoal-cancel_button" onClick={() => setShowCancelModal(false)} />
                  <BasicButton text={t(TRANSLATIONS.FINISH_AND_SEND)} className="wo__cancelModal-send_button" onClick={onCancelWo} />
                </div>
              </div>
            )}
          </Modal>
        )}
        <div className="new-workorder__container">
          {(!loading) ? (
            <>
              <div className="cancel-workorder__flex-row">
                <div className={`new-workorder__header-main ${isWoStarted ? 'new-workorder__cancel-header-main' : ''}`}>
                  <div role="button" onClick={goBack} className="cancel-workorder__backArrow">
                    <img src={backArrow} alt="backArrow" />
                  </div>
                  <div className="new-workorder__headerContainer">
                    <div className="checklistHeader__tab-edit">
                      {editTitle ? (
                        <div className="checklistHeader__edit-container">
                          <input
                            ref={inputEl}
                            className="checklistHeader_input"
                            type="text"
                            placeholder={t(TRANSLATIONS.JOB_NAME)}
                            value={title}
                            onChange={onChangeTitle}
                            onBlur={toggleEditTitle}
                            maxLength={100}
                          />
                        </div>
                      ) : (
                        <div className={`new-workorder__header-edit ${isWoStarted ? 'cursor-default' : ''}`}>
                          <p
                            className="new-workorder__header-title"
                            onClick={() => {
                              if (!isWoStarted) {
                                toggleEditTitle();
                              }
                            }}
                          >
                            {title}
                          </p>
                          {!isWoStarted ? (
                            <img onClick={toggleEditTitle} height={16} width={16} src={ImageLinks.edit} alt="edit" />
                          ) : null}
                        </div>
                      )}
                      {routerState?.isEdit ? (
                        <text className="checklistHeader_id">{`ID ${routerState?.checklistData ? routerState?.checklistData?.id : ''}`}</text>
                      ) : null}
                      {!isWoStarted ? (
                        <input
                          type="text"
                          placeholder={t(TRANSLATIONS.ENTER_DESCRIPTION)}
                          name="description"
                          value={description}
                          maxLength={1500}
                          onChange={onChangeDescription}
                          className="header-description"
                        />
                      ) : null}
                    </div>
                  </div>
                </div>
                {isWoStarted ? (
                  <div className="new-workorder__cancelWo">
                    <div className="new-workorder__cancelWo-info">
                      <p
                        className={`new-workorder__cancelWo-title ${isWorkOrderCancelled && 'cancel_wo-color-red'}`}
                      >
                        {`${Math.round((parseInt(routerState?.checklistData?.totalQuestionsAnswered, 10) / parseInt(routerState?.checklistData?.totalQuestions, 10)) * 100)}% ${t(
                          isWorkOrderCancelled
                            ? TRANSLATIONS.CANCELLED
                            : TRANSLATIONS.COMPLETED,
                        )?.toLowerCase()}`}
                      </p>
                      <p className="new-workorder__cancelWo-description">{`${routerState?.checklistData?.totalQuestionsAnswered}/${routerState?.checklistData?.totalQuestions} ${t(TRANSLATIONS.STEPS)?.toLowerCase()}`}</p>
                    </div>
                    {
                      !isWorkOrderCancelled && (
                        <button
                          type="button"
                          className="cancel-workorder__cancel_button"
                          onClick={() => setShowCancelModal(true)}
                          style={{ outline: 'none' }}
                        >
                          <img src={ImageLinks.espectro.file} alt="cancel" />
                          <p className="new-workorder__cancel_button-title">{t(TRANSLATIONS.CANCEL_JOB)}</p>
                        </button>
                      )
                    }
                  </div>
                ) : (
                  selectUsers ? (
                    <BasicButton
                      onClick={onClickSaveChecklist}
                      className="new-workorder__upload_button"
                      text={TRANSLATIONS.SAVE}
                      background="#6C63FF"
                      disabled={!title || (dndChecklistList[DndCards.CARD_2]?.length === 0)}
                    />
                  ) : (
                    <BasicButton
                      onClick={onClickSelectUsers}
                      className="new-workorder__upload_button"
                      text={TRANSLATIONS.NEXT}
                      background="#6C63FF"
                      disabled={!title || (dndChecklistList[DndCards.CARD_2]?.length === 0)}
                    />
                  )
                )}
              </div>
              {isWoStarted && (
                <div className="new-workorder__cancel-description-container">
                  <p className="new-workorder__cancel-description-title">Descripción</p>
                  <p className="new-workorder__cancel-description">{description}</p>
                </div>
              )}
              {isWorkOrderCancelled && (
                <div className="new-workorder__cancel-description-container">
                  <p className="new-workorder__cancel-description-title">{t(TRANSLATIONS.ADDED_REASON_FOR_CANCELLATION)}</p>
                  <p className="new-workorder__cancel-description">{addedCancellationReason}</p>
                </div>
              )}

              <p className="new-workorder__checklistSelection-title">
                {t(TRANSLATIONS.SELECTED_CHECKLISTS)}
              </p>

              {isWoStarted ? (
                <div className="row">
                  <div className="col-12 card new-workorder__cancel_checklist_cards">
                    <div className="new-workorder__cancel_checklist_card_container">
                      {dndChecklistList?.[DndCards.CARD_2]?.map(
                        (cardData: any) => (
                          <div
                            className="new-workorder__checklist_card"
                          >
                            <text className="new-workorder__checklist_card_title">{capitalizeFirst(cardData?.routineInfo?.name)}</text>
                            <text className="new-workorder__checklist_card_version_title">Version {cardData?.versionAdded?.versionNumber || 1}</text>
                          </div>
                        ),
                      )}
                    </div>
                    <p className="new-workorder__total-questions">{`${t(TRANSLATIONS.TOTAL_QUESSTIONS)} ${routerState?.checklistData?.totalQuestions}`}</p>
                  </div>
                </div>
              ) : (
                selectUsers ? (
                  null
                ) : (
                  <div className="row">
                    {Object.keys(dndChecklistList)?.map((column: any, index: number) => {
                      return (
                        <div className="col-6 card cancelled-workorder__checklist_cards">
                          {column === DndCards.CARD_1 ? (
                            <InputWithLabel
                              inputRef={inputRef}
                              inputImg={checklistSearch}
                              onChange={handleSearch}
                              placeholder={t(TRANSLATIONS.SEARCH)}
                              value={search}
                              labelClassName="search-header"
                              type="search"
                              maxLength={1000}
                              inputClassName="search-input"
                              containerClassName="new-workorder__search"
                            />
                          ) : null}
                          <Droppable droppableId={column}>
                            {provided => (
                              <div ref={provided.innerRef}>
                                {(dndChecklistList?.[column]?.length) ? (
                                  <div className="new-workorder__checklist_card_container">
                                    {index === 1 ? <text className="new-workorder__checklist_card_add_title">Arrastra inspecciones para agregarlas a este trabajo</text> : null}
                                    {dndChecklistList?.[column]?.map(
                                      (cardData: any, index1: number) => (
                                        <Draggable key={cardData?.id} draggableId={`${cardData?.id}`} index={index1}>
                                          {providedV1 => (
                                            <div
                                              className="new-workorder__checklist_card"
                                              role="presentation"
                                              ref={providedV1.innerRef}
                                              {...providedV1.draggableProps}
                                              {...providedV1.dragHandleProps}
                                            >
                                              <text className="new-workorder__checklist_card_title">{cardData?.name}</text>
                                              <text className="new-workorder__checklist_card_version_title">Version 1</text>
                                            </div>
                                          )}
                                        </Draggable>
                                      ))}
                                  </div>
                                ) : (
                                  <div className="new-workorder__checklist_card_add_title_wrap">
                                    <text className="new-workorder__checklist_card_add_title">Arrastra inspecciones para agregarlas a este trabajo</text>
                                  </div>
                                )}
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </div>
                      );
                    })}
                  </div>
                )
              )}
            </>
          ) : (
            <div className="cancel_wo-loader-container">
              <LoadingSpinner text={`${t(TRANSLATIONS.LOADING)}...`} />
            </div>
          )}
        </div>
      </div>
    </DragDropContext>
  );
};

export default NewWorkOrder;
