import React, { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import { COLOR_CODE_NEW, TRANSLATIONS } from 'types/enums';
import './LabelledInputWithImage.scss';
import Input from '../Input';

type Props = {
  label?: string;
  placeholder?: string;
  value?: any;
  onChange: (e: any)=>void;
  containerStyle? :CSSProperties;
  inputStyle?: CSSProperties;
  icon?: string;
  type?:string;
  dropdownOptions?: { value: string, text: string }[],
  disabled?:boolean;
};

const LabelledInputWithImage = (props: Props) => {
  const { t } = useTranslation();
  const { icon, label, value, onChange, placeholder,
    disabled, containerStyle, inputStyle, type, dropdownOptions } = props;
  return (
    <div className="inputContainer" style={{ ...(containerStyle || {}) }}>
      {(label || icon) && (
      <div className="labelContainer">
        {icon && <img className="icon" src={icon} alt="input" />}
        {label && <span>{label || ''}</span>}
      </div>
      )}
      {type !== 'dropdown'
      && (
      <Input
        disabled={disabled}
        value={value}
        placeholder={placeholder || ''}
        activeBoxShadow="none"
        activeBorderColor={COLOR_CODE_NEW.PRIMARY}
        inactiveBorderColor={COLOR_CODE_NEW.BORDERS}
        onChange={onChange}
        style={{
          border: `2px solid ${COLOR_CODE_NEW.BORDERS}`,
          maxWidth: 'unset',
          paddingTop: '5px',
          paddingBottom: '5px',
          ...(inputStyle || {}),
        }}
      />
      )}
      {type === 'dropdown' && dropdownOptions && (
      <select
        style={{
          border: `2px solid ${COLOR_CODE_NEW.BORDERS}`,
          maxWidth: 'unset',
          paddingTop: '5px',
          paddingBottom: '5px',
          ...(inputStyle || {}),
        }}
        value={value}
        onChange={onChange}
      >
        <option value="" selected disabled hidden>{t(TRANSLATIONS.CHOOSE_HERE)}</option>
        {dropdownOptions.map(option => (
          <option
            key={option.value}
            disabled={option.value.length === 0}
            value={option.value}
          >{option.text}
          </option>
        ))}
      </select>
      ) }
    </div>
  );
};

export default LabelledInputWithImage;
