import {
  Action_Log_Origin,
  Action_Log_Status,
  DetailedCommentsForActionLogDocument,
} from 'generated/graphql';
import { TFunction } from 'i18next';
import { FILTER_ACCESS_LEVELS, TRANSLATIONS } from 'types/enums';
import { generateQueryDates } from 'utils/dateHelpers';
import ImageLinks from 'utils/ImageLinks';
import { Responsible } from './createEditActionLog/createEditActionLogModal.state';

export const downloadActionLogsReport = (
  accessLevel: FILTER_ACCESS_LEVELS,
  timeFilter: any,
  locationSlug?: string,
) => {
  let params: any;
  if (locationSlug) {
    params = {
      [accessLevel]: locationSlug,
      ...generateQueryDates(timeFilter.get('startTime'), timeFilter.get('endTime')),
    };
  } else {
    params = {
      ...generateQueryDates(timeFilter.get('startTime'), timeFilter.get('endTime')),
    };
  }
  const queryString = Object.keys(params)
  // @ts-ignore
    .map(key => `${key}=${params[key]}`)
    .join('&');
  window.open(`/api/v1/actionlogs-report?${queryString}`, '_blank');
};

export function trelloBoardColumns(t: any) {
  const trelloBoardColumnsData = [
    { name: t(TRANSLATIONS.OPEN),
      icon: ImageLinks.docsOpened,
      status: Action_Log_Status.Open },
    {
      name: t(TRANSLATIONS.IN_PROGRESS),
      icon: ImageLinks.settingsColored,
      status: Action_Log_Status.InProgress,
    },
    { name: t(TRANSLATIONS.IN_REVIEW),
      icon: ImageLinks.watching,
      status: Action_Log_Status.ForRevision },
    { name: t(TRANSLATIONS.COMPLETED),
      icon: ImageLinks.finished,
      status: Action_Log_Status.Completed },
  ];
  return trelloBoardColumnsData;
}

export function getParticipantIncludingOwner(
  responsibles: Responsible[],
  owner: any,
  ParticipantNumber = 4,
) {
  let ownerInfo = responsibles.find(responsible => responsible.slug === owner);

  if (!ownerInfo) {
    ownerInfo = owner;
  }

  const responsiblesWithoutOwner = responsibles
    .filter((responsible: Responsible) => responsible.slug !== owner)
    .slice(0, ParticipantNumber - 1);
  return [ownerInfo, ...responsiblesWithoutOwner] as Responsible[];
}

export function actionLogOriginText(
  origin: Action_Log_Origin,
  originText: string,
  t: TFunction,
) {
  if (origin === Action_Log_Origin.ActionLog) {
    return t(originText as string);
  }
  return originText;
}

export const participantNumber = 4;

export const actionLogCommentRefetchQueries = (actionLogId: string) => {
  return [
    {
      query: DetailedCommentsForActionLogDocument,
      variables: {
        commentsActionLogId: actionLogId,
      },
    },
  ];
};
