import { UserTypes, USER_FEATURES, USER_ROLE } from 'types/enums';

export function userOnlyHasAccessToSearchUserScreen(userType: UserTypes): boolean {
  return [
    UserTypes.LocationSecurityDoctor,
    UserTypes.LocationManager,
    UserTypes.LocationSecurity,
  ].includes(userType);
}

export function canUserBlockUnblock(userType: UserTypes): boolean {
  return [
    UserTypes.CorporateSupply,
    UserTypes.CorporateDPO,
    UserTypes.SubzoneGeneralManager,
    UserTypes.LocationManager,
    UserTypes.LocationSecurity,
    UserTypes.LocationSecurityDoctor,
    UserTypes.DistributionCenterManagerDPO,
    UserTypes.DistributionCenterManagerSupply,
    UserTypes.SubzoneGeneralManagerDPO,
    UserTypes.SubzoneGeneralManagerSupply,
  ].includes(userType);
}

export function canUserDownload(userType: UserTypes): boolean {
  return [
    UserTypes.CorporateDPO,
    UserTypes.DistributionCenterManagerDPO,
    UserTypes.SubzoneGeneralManagerDPO,
  ].includes(userType);
}

export function userHasWebAccess(
  userRole: USER_ROLE,
  userFeatures: any,
  userType?: UserTypes,
): boolean {
  return [
    USER_ROLE.SAFETY_MANAGER,
    USER_ROLE.LINE_MANAGER,
    USER_ROLE.ASSIGNER,
    USER_ROLE.SAFETY_MANAGER_SAFETY,
    USER_ROLE.LINE_MANAGER_SAFETY,
    USER_ROLE.ASSIGNER_SAFETY,
    USER_ROLE.ENVIRONMENT_MANAGER,
    USER_ROLE.LINE_MANAGER_ENVIRONMENT,
    USER_ROLE.ASSIGNER_ENVIRONMENT,
  ].includes(userRole)
  || userFeatures?.map((userFeature: any) => [
    USER_FEATURES.SAFETY_WEB_ACCESS,
  ].includes(userFeature?.size ? userFeature?.get('slug') : userFeature?.slug)).includes(true)
  || (userType && [
    UserTypes.BreweryViewer,
    UserTypes.BreweryPlanner,
    UserTypes.BreweryViewerManager,
  ].includes(userType));
}

export function userOnlyHasAccessToReportSIOs(userRole: USER_ROLE,
  userFeatures: any,
  userType?: UserTypes): boolean {
  return [
    USER_ROLE.EJECUTOR,
    USER_ROLE.EJECUTOR_SAFETY,
    USER_ROLE.EJECUTOR_ENVIRONMENT,
    USER_ROLE.REPORTER_ONLY,
    USER_ROLE.KIOSK,
  ].includes(userRole)
  || userFeatures?.map((userFeature: any) => [
    USER_FEATURES.ONLY_REPORTS_WEB_SIOS,
  ].includes(userFeature?.size ? userFeature?.get('slug') : userFeature?.slug)).includes(true)
  || (userType && (!userRole && [
    UserTypes.ReporterOnlySupply,
    UserTypes.CertificatesOnly,
  ].includes(userType)));
}

export function userCanReportSIOs(userRole: USER_ROLE, userFeatures: any): boolean {
  return [
    USER_ROLE.KIOSK,
    USER_ROLE.SAFETY_MANAGER,
    USER_ROLE.LINE_MANAGER,
    USER_ROLE.ASSIGNER,
    USER_ROLE.EJECUTOR,
    USER_ROLE.LINE_MANAGER_SAFETY,
    USER_ROLE.ASSIGNER_SAFETY,
    USER_ROLE.EJECUTOR_SAFETY,
    USER_ROLE.LINE_MANAGER_ENVIRONMENT,
    USER_ROLE.ASSIGNER_ENVIRONMENT,
    USER_ROLE.EJECUTOR_ENVIRONMENT,
    USER_ROLE.REPORTER_ONLY,
  ].includes(userRole)
  || userFeatures?.map((userFeature: any) => [
    USER_FEATURES.WEB_REPORT_SIOS,
  ].includes(userFeature?.size ? userFeature?.get('slug') : userFeature?.slug)).includes(true);
}

export function userCanDownloadExcelSupply(userRole: USER_ROLE, userFeatures: any): boolean {
  return [
    USER_ROLE.SAFETY_MANAGER,
    USER_ROLE.LINE_MANAGER,
    USER_ROLE.ASSIGNER,
    USER_ROLE.ENVIRONMENT_MANAGER,
    USER_ROLE.LINE_MANAGER_ENVIRONMENT,
    USER_ROLE.ASSIGNER_ENVIRONMENT,
    USER_ROLE.LINE_MANAGER_SAFETY,
    USER_ROLE.ASSIGNER_SAFETY,
    USER_ROLE.WEB_ONLY,
  ].includes(userRole)
  || userFeatures?.map((userFeature: any) => [
    USER_FEATURES.SAFETY_WEB_ACCESS,
  ].includes(userFeature?.size ? userFeature?.get('slug') : userFeature?.slug)).includes(true);
}

export function userHasRoleID(userRoleDetails: any) {
  if (userRoleDetails?.get('id')?.size || userRoleDetails?.get('id')) {
    return true;
  }
  return false;
}

export function isUserBreweryPlantManager(
  userRole: USER_ROLE,
  userType: UserTypes,
): boolean {
  return [
    USER_ROLE.PLANT_MANAGER,
  ].includes(userRole)
  || [
    UserTypes.BreweryViewer,
    UserTypes.BreweryPlanner,
  ].includes(userType);
}
