import React, { useRef, useState } from 'react';
import './SectionHeader.scss';
import ImageLinks from 'utils/ImageLinks';
import { SECTION_HEADER_MAX_LENGTH } from 'features/Espectro/constants';
import { TRANSLATIONS } from 'types/enums';
import { useTranslation } from 'react-i18next';

type Props = {
  onClickDropdownIcon: any;
  iconClass: string;
  onChangeSectionTitle: any;
  sectionTitle: string;
};

const SectionHeader = ({
  onClickDropdownIcon,
  iconClass,
  sectionTitle,
  onChangeSectionTitle,
}: Props) => {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);
  const [editingEnabled, setEditingEnabled] = useState(false);

  const onChange = (e: any) => {
    onChangeSectionTitle(e);
  };
  const onHandleToggle = () => {
    inputRef.current?.focus();
    setEditingEnabled(true);
  };

  return (
    <div
      className="checklist-header-container"
      style={editingEnabled ? { width: '100%' } : {}}
    >
      <img
        width={32}
        height={32}
        alt="delete section"
        style={{ marginRight: 4 }}
        src={ImageLinks.espectro.dropdownIcon}
        onClick={onClickDropdownIcon}
        className={iconClass}
      />

      {
        !editingEnabled ? (
          <div
            role="button"
            onClick={onHandleToggle}
            className="section_title-container"
          >
            <p className="section_title">
              {sectionTitle || `${t(TRANSLATIONS.ADD)} ${t(TRANSLATIONS.SECTION).toLowerCase()} ${t(TRANSLATIONS.TITLE).toLowerCase()}`}
            </p>
            <img
              src={ImageLinks.edit}
              alt="delete section"
              width={16}
              height={16}
              style={{ marginLeft: 10 }}
            />
          </div>
        ) : (
          <input
            className="section_title"
            onChange={onChange}
            type="text"
            ref={inputRef}
            style={{ width: '100%' }}
            id="autoWidthInput"
            maxLength={SECTION_HEADER_MAX_LENGTH}
            placeholder={`${t(TRANSLATIONS.ADD)} ${t(TRANSLATIONS.SECTION).toLowerCase()} ${t(TRANSLATIONS.TITLE).toLowerCase()}`}
            value={sectionTitle}
            autoFocus
            onBlur={setEditingEnabled.bind(null, false)}
            onFocus={setEditingEnabled.bind(null, true)}
          />
        )
      }
    </div>
  );
};

export default SectionHeader;
