import React from 'react';
import { useTranslation } from 'react-i18next';
import { Backdrop, Checkbox, CircularProgress, Modal } from '@mui/material';

import './RoutinesDropdownForExcelDownload.scss';
import { TRANSLATIONS } from 'types/enums';
import styles from './styles';
import { Box } from '@mui/system';
import { RoutineAvailable } from 'generated/graphql';
import { cloneDeep } from 'lodash';


type Props = {
  isOpen: boolean;
  isOpenToggle: () => void;
  onPressDownloadReports: () => void;
  routinesAvailable: RoutineAvailable[];
  isDownloadReportsRequestLoading: boolean;
  selectedRoutinesForReportsDownload: number[];
  setSelectedRoutinesForReportDownload: React.Dispatch<React.SetStateAction<number[]>>;
};

const RoutinesDropdownForExcelDownload = ({
  isOpen,
  isOpenToggle,
  routinesAvailable,
  onPressDownloadReports,
  isDownloadReportsRequestLoading,
  selectedRoutinesForReportsDownload,
  setSelectedRoutinesForReportDownload,
}: Props) => {
  const { t } = useTranslation();

  const onCloseCallback = () => {
    if (isDownloadReportsRequestLoading) return;
    if (!isDownloadReportsRequestLoading) {
      isOpenToggle();
      setSelectedRoutinesForReportDownload([]);
    }
  };

  const onPressRoutineItem = (routineId: number) => {
    if (isDownloadReportsRequestLoading) return;
    const selectedRoutines = cloneDeep(selectedRoutinesForReportsDownload);

    const indexIfExists = selectedRoutines.indexOf(Number(routineId));
    if (indexIfExists > -1) {
      selectedRoutines.splice(indexIfExists, 1);
    } else {
      selectedRoutines.push(Number(routineId));
    }
    setSelectedRoutinesForReportDownload([...selectedRoutines]);
  };

  const onToggleSelectAll = () => {
    if (isDownloadReportsRequestLoading) return;
    if (selectedRoutinesForReportsDownload.length === routinesAvailable.length) {
      setSelectedRoutinesForReportDownload([]);
    } else {
      setSelectedRoutinesForReportDownload(routinesAvailable.map(r => Number(r.id)));
    }
  };

  return (
    <Modal
      open={isOpen}
      closeAfterTransition
      onClose={onCloseCallback}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 1,
        onClick: () => {
          if (isDownloadReportsRequestLoading) return;
          setSelectedRoutinesForReportDownload([]);
          isOpenToggle();
        },
      }}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Box sx={styles.routinesDropdownForExcelDownloadModalContainer}>
        <div className="routines_dropdown_for_excel_download__overlay_head">
          <p className="routines_dropdown_for_excel_download_title">
            Download Reports Excel
          </p>
          <p className="routines_dropdown_for_excel_download_sub-title">
            Reports for all associated versions will be downloaded of selected routines
          </p>

          <div className='routines_dropdown_for_excel-routines-list-container'>
            <div
              role='button'
              onClick={onToggleSelectAll}
              className='routines_dropdown_for_excel-routine-list-item'
            >
              <Checkbox
                onChange={onToggleSelectAll}
                inputProps={{ 'aria-label': 'All' }}
                disabled={isDownloadReportsRequestLoading}
                checked={selectedRoutinesForReportsDownload.length === routinesAvailable.length}
              />
              <p>
                Select All
              </p>
            </div>
            {
              (routinesAvailable || []).map(routine => (
                <div
                  role='button'
                  className='routines_dropdown_for_excel-routine-list-item'
                  onClick={onPressRoutineItem.bind(this, Number(routine.id))}
                >
                  <Checkbox
                    disabled={isDownloadReportsRequestLoading}
                    inputProps={{ 'aria-label': routine.name }}
                    onChange={onPressRoutineItem.bind(this, Number(routine.id))}
                    checked={selectedRoutinesForReportsDownload.includes(Number(routine.id))}
                  />
                  <p>
                    {routine.name}
                  </p>
                </div>
              ))
            }
          </div>

          <button
            type="button"
            onClick={onPressDownloadReports}
            disabled={!selectedRoutinesForReportsDownload.length!}
            className={`
              routines_dropdown_for_excel_download_save-button ${
                !selectedRoutinesForReportsDownload.length && 'routines_dropdown_for_excel_download_save-button-disabled'
              }`}
          >
            {
              isDownloadReportsRequestLoading ? (
                <CircularProgress size={18} color='secondary' />
              ) : t(TRANSLATIONS.DOWNLOAD)
            }
          </button>
        </div>
      </Box>
    </Modal>
  );
};

export default RoutinesDropdownForExcelDownload;
