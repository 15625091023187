import { fromJS } from 'immutable';
import {
  SET_HAS_SEARCHED,
  SET_SEARCH_LOADING,
  SET_SEARCH_RESULTS,
  SET_SEARCH_VALUE,
} from '../actions/safetyAcademy.actions';

const INITIAL_STATE = fromJS({
  searchResults: [],
  loading: false,
  searchValue: '',
  hasSearched: false,
});

// eslint-disable-next-line
const safetyAcademmyReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case SET_SEARCH_RESULTS:
      return state.set('searchResults', fromJS(action.results));
    case SET_SEARCH_LOADING:
      return state.set('loading', fromJS(action.loading));
    case SET_SEARCH_VALUE:
      return state.set('searchValue', fromJS(action.value));
    case SET_HAS_SEARCHED:
      return state.set('hasSearched', fromJS(action.value));
    default:
      return state;
  }
};

export default safetyAcademmyReducer;
